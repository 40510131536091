#header {

	.header-btn-collapse-nav { 
		margin: 12px 10px 8px 15px;
	}
	.header-body {
		border: none;
		padding: 0;
	}
	.header-column {
		position: relative;
		display: block;
	}
	
	@media all and (max-width: 767px) {
		.container {
			height: 50px;
		}

		> .header-body > .container {
			z-index: 200;
		}
	}

	.header-logo{
		position: absolute;

		@media all and (max-width: 1023px) {
			width: 80px !important;
			height: 80px !important;
			top: -50px;
			left: 10px;
			img {
				width: 100%;
				height: auto !important;
			}
		}

		@media all and (min-width: 1024px) {
			top: -80px;
			width: 130px !important;
			height: 130px !important;
		}
	}

	.right {
		text-align: right;
		padding-top: 20px;
	}

	.dark {
		background-color: #000;

		/*@media all and (max-width: 1023px) {
			.container {
				width: 100%;
			}
		}*/
	}
	.row {
		min-height: 50px;

		@media all and (min-width: 1024px) {
			min-height: 80px;
		}
	}

	nav.meta {
		span {
			display: inline-block;
			
			a {
				font-size: 14px;
				color: $brand-primary;
				
				&.btn.btn-small {
					background: none;
					padding: 0;
				}
			}
			&.nolink {
				padding: 0 10px;
			}
			
			@media all and (min-width: 991px) {
				margin-left: 30px;

				&.nolink {
					padding: 0;
				}

				a {
					font-size: 18px;
					color: $brand-primary;

					&.btn.btn-small {
						font-size: 18px;
						color: #fff;
						background: $brand-primary;
						padding: 8px 16px;
					}
				}
			}
		}
	}


	.header-nav-main {

		@media all and (max-width: 991px) {
			background: #000;
		}

		> nav {
			> ul {
				display: block;

				>li {		
					> a {
						color: #fff;
						display: block;
						font-size: 18px;
						font-weight: 400;
						letter-spacing: 1px;
					}

					&.active,
					&:hover {
						a,
						a:hover {
							background: none;
							color: $brand-primary;	
						}
					}

					&.active + .active {
						> a {
							color: #fff;
						}
					}

					a.dropdown-toggle,
					&.activea.dropdown-toggle,
					&:hover a.dropdown-toggle {
						&:after {
							border-color: #fff transparent transparent;

							@media all and (max-width: 991px) {
								border: none;
							}
						}
					}

					&.dropdown {
						.dropdown-menu {
							border-top-color: $brand-primary ;

							li a {
								font-size: 18px;
								padding: 10px 20px 10px 10px;
								white-space: nowrap;
								display: block;
							}
						}
					}

					&.tapped {
						ul.dropdown-menu {
							top: 45px !important;
							opacity: 1 !important;
						}
					}
				}
			}
		}	 

		@media all and (min-width: 1024px) {
			margin-top: 21px;
			> nav {
				float: right;
				> ul {
				display: flex;
					>li {
						display: block;
						margin-left: 55px;
						> a {
							padding: 10px 0;
							text-transform: none;
						}
					}
				}
			}
		}
	}

	.khv-notice {
		color: $brand-primary;
		font-size: 20px;
		padding-top: 23px;

		@media all and (max-width:767px) {
			text-align: center;
			position: absolute;
			top: 100px;
			left: 0;
			padding: 3px 0;
			background: #fff;
		}
	}
}


.sticky-header-active {
	#header {
		.header-body {
			min-height: 120px;
		}
		.row {
			min-height: 40px;

			@media all and (min-width: 1024px) {
				min-height: 60px;
			}
		}

		.right {
			padding-top: 10px;
		}

		.header-logo{

			@media all and (min-width: 1024px) {
				top: -68px;
			}
		}

		.header-nav-main {
			@media all and (min-width: 1024px) {
				margin-top: 11px;
			}
		}
	}
}

.right.no-padding{
	padding: 0 !important;

	@media all and (max-width: 767px) {
		position: absolute;
		top: 50px;
		right: 100px;
	}
}

.language-switch {
	padding: 0;
	margin: 0;
	padding-right: 5px;

	@media all and (min-width: 768px) {
		padding-right: 0;
	}
	li {
		padding: 1px;
		border: 1px solid #cacaca;
		margin-left: 5px;
		display: inline-block;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover; 
		width: 40px;
		height: 28px;


		@media all and (min-width: 768px) {
			width: 40px;
			height: 28px;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			font: 0/0 arial;
			color: transparent;
		}

		&.en { 
			background-image: url(../images/gb.svg);
		}
		&.nl {
			background-image: url(../images/nl.svg);
		} 
		&.de {
			background-image: url(../images/de.svg);
		}
	}
}



@media all and (max-width:768px) {
	.row {
		margin: 0;
	}
	.container {
		width: auto;
	}
}