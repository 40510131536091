.comment-list {
  @extend .list-unstyled;
}
.comment-list ol {
  list-style: none;
}
.comment-form p {
  @extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}
.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-secondary;
}

body.is_auction {
  .menu-item.menu-veilingen a {
    color: #df4c2c !important;
  }
  .menu-item.menu-koivoer a {
    color: #fff !important;
  }
}