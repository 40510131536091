html .btn-secondary:hover,
html .btn-primary,
html .btn-primary-scale-2 {
  background-color: $brand-primary;
  color: #fff;
  border: none;
  border-radius: 100px;
  font-size: 26px;
}
html .btn-primary:hover,
html .btn-secondary {
  background-color: $brand-secondary;
  color: #fff;
  border: none;
  border-radius: 100px;
  font-size: 26px;
}

html .btn-primary.btn-borders {
  border: 3px solid $brand-primary;
  color: $brand-primary; 
  font-size: 26px;
}