// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  input,
  input:not([type=submit]):not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  textarea {
    @extend .form-control;
    padding: 10px 5px !important;
    font-size: 16px;
  }

  .ginput_container_fileupload {
    input.medium {
      width: 100% !important;
    }
  }

  input[type="submit"]{
    @extend .btn-primary;
    height: auto;
  }

  input[type=number] {
    line-height: 40px;
    font-size: 16px;
  }

  input:not([type=submit]), 
  textarea, 
  select {
    background-color: #fff !important;
  }

  li.gfield {
    margin-bottom: 20px;
  }

}

.container.contact-form .gform_wrapper {


  li {
    padding: 0 !important;
    
    &:nth-child(1),
    &:nth-child(2) {
      padding: 0;
      float: left;
      width: 48%;
      clear: none !important;
    }
    &:nth-child(2) {
      float: right;
    }
  }

  label.gfield_label{
    display: none !important;
  }
}

