html .woocommerce {
  .u-columns {
    .col-1, 
    .col-2 {
      display: block;
      flex: none;
      max-width: none;
    }
    &.col2-set .col-1, 
    &.col2-set .col-2 {
      width: 40%;

      @media all and (max-width: 1023px) {
        width: 100%;
      }
    }
  }

  h1.product_title {
    @media all and (min-width: 1024px) {
      font-size: 48px;
    }
  }

  ul.products a.button:hover {
    font-size: 24px;
  }

  label {
    display: block;
    margin-top: 10px;
  }

  .woocommerce-checkout-payment {
    label {
      display: inline-block;
    }
  }

  input {
    width: 100%;
    padding: .5rem .75rem;
    font-size: 16px;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  input[name=apply_coupon],
  input[name=update_cart] {
    width: auto !important;
    font-size: 18px !important;
  }
  input[name=coupon_code] {
    min-width: 200px !important;
    margin: 10px 20px 10px 0 !important;

  }

  input[type=checkbox],
  input[type=radio] {
    width: auto;
  }

  input[type=button] {
    width: auto;
    display: inline-block;
  }

  input[type=number] {
    padding: 3px 10px;
  }

  input.button,
  input.button:hover {
    @extend .btn-secondary;
    font-size: 18px;
    margin: 10px 0;

    @media all and (min-width: 1024px) {
      font-size: 24px;
    }
  }

  .register {
    input.button,
    input.button:hover {
      @extend .btn-primary;
      font-size: 24px;
    }
  }

  .woocommerce-form-row {
    > span {
      display: inline-block;

      &:first-child {
        width: 78%;
        float: left;
      }
      &:last-child {
        width: 20%;
        float: right;
      }
    }
  }



  ul.products {
    li.product {
      h2.woocommerce-loop-product__title {
        font-size: 24px;
      }
      span.price {
        font-size: 18px;
      }
    }
    a.button {
      @extend .btn;
      @extend .btn-primary;
      font-size: 24px;
    }
  }

  #respond input#submit.alt, 
  a.button.alt, 
  button.button.alt, 
  input.button.alt {
    @extend .btn;
    @extend .btn-primary;
    margin-left: 20px;

  }

  .quantity.buttons_added {
    height: 48px;
    padding-top: 4px;
    vertical-align: middle;
    .minus, 
    .plus {
      background: $brand-secondary;
      color: #fff;
      border: none;
      padding: 3px 10px 6px;
      line-height: 1;
      font-size: 30px;
    }
    .qty {
      font-size: 24px;
      line-height: 1;
    }
  }

  .auction-price.current-bid {
    font-size: 1.5em;
  }

  .woocommerce-error {
    .button {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}


//Account page
.logged-in.woocommerce-account.my-account {

  .woocommerce {
    padding: 50px 0;
  }
}

//Cart in Header:
.cart-contents:before {
    font-family:WooCommerce;
    content: "\e01d";
    font-size:28px;
    margin-top:10px;
    font-style:normal;
    font-weight:400;
    padding-right:5px;
    vertical-align: bottom;
    color: $brand-secondary;
} 
.cart-contents:hover {
    text-decoration: none;
}
.cart-contents-count {
    color: #fff;
    background-color: $brand-primary;
    font-weight: bold;
    border-radius: 10px;
    padding: 1px 6px;
    line-height: 1;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: top;
}


///Responsive
@media all and (max-width: 500px) {
  .tabs.wc-tabs {
    li {
      a {
        font-size: 12px;
      }
    }
  }
}

//up to tablet ls
  html .woocommerce {

    .col2-set {
      .col-1, 
      .col-2 {
        flex: none;
        max-width: none;
      }   
    }
    input[type=submit].button.alt{
      margin-left: 0;
    }
  }

.auction-bid {
  display: none;
}

dl {
  @include clearfix;
  dt {
    font-weight: bold;
    display: block;
    float: left;
    clear: left;
    width: 40%;
    border-bottom: 1px solid #cacaca;
  }
  dd {
    display: block;
    float: left;
    width: 60%;
    border-bottom: 1px solid #cacaca;
  } 
}  

.auction-history-table {
  thead, tbody {
    th:first-child,
    td:first-child,
    .date {
      display: none;
    }
  }

  td {
    padding: 3px 10px;
  }
}

.bid-help {
  position: relative;
  display: inline-block;
  margin-left: 20px;

  .help-icon {
    color: #666;
    display: inline-block;
    height: 48px;
    line-height: 48px;
    position: relative;
    vertical-align: middle;
    width: 48px;
    font: 0/0 arial;
    color: transparent;

    &:after {
      font-family: Dashicons;
      speak: none;
      color: black;
      font-size: 28px;
      line-height: 38px;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      text-indent: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      content: "?";
      cursor: help;
    }

    &:hover {
      + .help-text {
        display: block !important;
      }
    }
  }
  .help-text {
    position: absolute;
    left: 45px;
    top: 0;
    width: 200px;
    height: auto;
    font-size: 12px;
    background: black;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    display: none;

    &:after {
      content: '';
      position: absolute;
      left: -9px;
      top: 10px;
      width: 0; 
      height: 0; 
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; 
      border-right:10px solid black; 
    }
  }

  a {
    display: block;
    text-align: right;
  }
}

.woocommerce div.product div.images.woocommerce-product-gallery {
  max-width: 350px !important;
}

@media all and (min-width:1024px) {
  //specific overrides
  .woocommerce #content div.product div.images, 
  .woocommerce div.product div.images, 
  .woocommerce-page #content div.product div.images, 
  .woocommerce-page div.product div.images {
    width: 38%;
  }
  .woocommerce #content div.product div.summary, 
  .woocommerce div.product div.summary, 
  .woocommerce-page #content div.product div.summary, 
  .woocommerce-page div.product div.summary {
    width: 60%;
  } 
}

//video max width 100%

.wc-terms-and-conditions .checkbox input[type=checkbox] {
  position: static;
}

@media all and (max-width: 768px) {
  .woocommerce ul.products li.product, 
  .woocommerce-page ul.products li.product, 
  .woocommerce-page[class*=columns-] ul.products li.product, 
  .woocommerce[class*=columns-] ul.products li.product {
    width: 90%;
    float: none !important;
    margin: 10px auto; 
  }
  
  .woocommerce div.product {
    padding: 0 15px;
  } 
  .container > .row {
    margin: 0; 
  }
}

iframe {
  max-width: 100% !important;
}

li.product > a > h2 {
  height: 87px !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: .5em 0 !important;
} 

.my-auctions {
  .simple-auctions.active-auctions {
    &:first-child {
      display: none;
    }
  }
}