@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,600');
@import "common/variables";
@import "common/mixins";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

@import "porto/bootstrap"; 
@import "porto/compiled";

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/home";
@import "components/shop";
@import "components/cta"; 


