.home.page {

  .call-to-action.cta-center {
    background: url(../images/header-koi.jpg) center top no-repeat;
    background-size: cover;

    @media all and (min-width: 1024px) {
      height: 500px;

      .row, .container {
        height: 100%;
      } 
    }

    @media all and (min-width: 1024px) {
      .col-md-12 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .call-to-action-content {
      width: 100%;
      margin-left: 0;
      @include clearfix;

      h3 {
        font-size: 30px;
        color: #fff;
        text-shadow: 0 3px 10px rgba(0,0,0,1);
        line-height: 1;
        margin-bottom: 20px;

        @extend .col-sm-6;
        @extend .col-sm-offset-3; 
        //@extend .offset-sm-3;

        @media all and (min-width: 768px) {
          font-size: 60px;
        }
      }
    }
    .call-to-action-btn {
      position: static;
      width: 100%;
      margin: 0;
    }
  }

  .padding-block {
    padding: 40px 0;
    text-align: center;

    @media all and (min-width: 1024px) {
      padding: 90px 0;
    }

    h2 {
      margin-bottom: 115px;
    }
  }

  .auctions{
    background: url(../images/bg-kavels.jpg) center bottom no-repeat;
    background-size: cover;
  }

  #contact {
    background: url(../images/footer-koi.jpg) center bottom no-repeat;
    background-size: cover;
    padding-top: 80px;

    h2.underline {
      margin-bottom: 50px;
    }

    .button {
      @extend .btn-secondary;
      padding: 14px 20px !important;
    }
  }
}