#footer,
footer.content-info {
  background-color: $brand-secondary;
  border: none;

  p {
    color: #fff;
  }

  .row {
    text-align: center;
  }

  nav {
    text-align: center;
    width: 100%;
    ul, li {
      flex: none;
      display: inline-block;
    }
    &.primary-nav {
      li {
        margin-left: 30px;
        @media all and (min-width: 1024px) {
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          font-size: 16px;
        }

        ul.sub-menu {
          display: none;
        }
      }

      @media all and (max-width: 767px) {
        li {
          width: 50%;
          margin: 10px 0;
        }
      }
    }

    &.footer-nav {
      li {
        border-left: 1px solid white;

        &:first-child {
          border: none;
          padding: 0;
          margin: 0;
        }

        a {
          font-size: 14px;
          padding: 0 10px;
        }
      }
       @media all and (max-width: 767px) {
        li {
          width: 50%;
          border: none;
          margin: 10px 0;
        }
      }
    }
  }

  .social-icons {
    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      width: 25px;
      height: 25px;
      opacity: 1;
      transition: opacity 0.3s linear;

      &:hover {
        opacity: 0.5;
        transition: opacity 0.3s linear;
      }

      a {
        background: none;
        font: 0/0 arial;
        color: transparent;
        display: block;
        width: 100%;
        height: 100%;
      }

      &.social-icons-facebook {
        background: url(../images/icon-facebook.svg) center left no-repeat;
      }
      &.social-icons-linkedin {
        background: url(../images/icon-linkedin.svg) center left no-repeat;
      }
      &.social-icons-twitter {
        background: url(../images/icon-twitter.svg) center left no-repeat;
      }
    }
  }
}

.mb-40 {
  margin-bottom: 30px;
}