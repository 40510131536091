.call-to-action.with-background {
  background: url(../images/header-koi.jpg) center top no-repeat;
  background-size: cover;

  @media all and (min-width: 1024px) {
    height: 500px;

    .row, .container {
      height: 100%;
    } 
  }

  @media all and (min-width: 1024px) {
    .col-md-12 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .call-to-action-content {
    width: 100%;
    margin-left: 0;
    @include clearfix;

    h3 {
      font-size: 30px;
      color: #fff;
      text-shadow: 0 3px 10px rgba(0,0,0,1);
      line-height: 1;
      margin-bottom: 20px;

      @extend .col-sm-6;
      @extend .col-sm-offset-3; 
      //@extend .offset-sm-3;

      @media all and (min-width: 768px) {
        font-size: 60px;
      }
    }
  }
  .call-to-action-btn {
    position: static;
    width: 100%;
    margin: 0;
  }
}