@charset "UTF-8";
/*
Name: 			theme-elements.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	5.7.2
*/
/* Headings */
h1, h2, h3, h4, h5, h6 {
	color: #1d2127;
	font-weight: 200;
	letter-spacing: -1px;
	margin: 0;
}

h1 {
	font-size: 2.6em;
	line-height: 44px;
	margin: 0 0 32px 0;
}

h2 {
	font-size: 2.2em;
	font-weight: 300;
	line-height: 42px;
	margin: 0 0 32px 0;
}

h3 {
	font-size: 1.8em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 24px;
	margin-bottom: 33px;
	text-transform: uppercase;
}

h4 {
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 27px;
	margin: 0 0 14px 0;
}

h5 {
	font-size: 1em;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
	text-transform: uppercase;
}

h6 {
	color: #333;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
}

h1.big {
	font-size: 4.2em;
	line-height: 54px;
}

h1.small {
	font-size: 2.2em;
	line-height: 42px;
	font-weight: 600;
}

h1.short {
	margin-bottom: 15px;
}

h2.short {
	margin-bottom: 15px;
}

h3.short,
h4.short,
h5.short,
h6.short {
	margin-bottom: 9px;
}

h1.shorter,
h2.shorter,
h3.shorter,
h4.shorter,
h5.shorter,
h6.shorter {
	margin-bottom: 0;
}

h1.tall,
h2.tall,
h3.tall,
h4.tall,
h5.tall,
h6.tall {
	margin-bottom: 33px;
}

h1.taller,
h2.taller,
h3.taller,
h4.taller,
h5.taller,
h6.taller {
	margin-bottom: 44px;
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
	margin-top: 22px;
}

h1.more-spaced,
h2.more-spaced,
h3.more-spaced,
h4.more-spaced,
h5.more-spaced,
h6.more-spaced {
	margin-top: 44px;
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
	color: #1d2127;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
	color: #FFF;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Heading */
.heading.heading-border {
	margin-bottom: 25px;
}

.heading.heading-border h1, .heading.heading-border h2, .heading.heading-border h3, .heading.heading-border h4, .heading.heading-border h5, .heading.heading-border h6 {
	margin: 0;
	padding: 0;
}

.heading.heading-bottom-border h1 {
	border-bottom: 5px solid #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-border h2, .heading.heading-bottom-border h3 {
	border-bottom: 2px solid #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-border h4, .heading.heading-bottom-border h5, .heading.heading-bottom-border h6 {
	border-bottom: 1px solid #DBDBDB;
	padding-bottom: 5px;
}

.heading.heading-bottom-double-border h1, .heading.heading-bottom-double-border h2, .heading.heading-bottom-double-border h3 {
	border-bottom: 3px double #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-double-border h4, .heading.heading-bottom-double-border h5, .heading.heading-bottom-double-border h6 {
	border-bottom: 3px double #DBDBDB;
	padding-bottom: 5px;
}

.heading.heading-middle-border {
	position: relative;
}

.heading.heading-middle-border:before {
	border-top: 1px solid #DBDBDB;
	content: "";
	height: 0;
	left: auto;
	position: absolute;
	right: 0;
	top: 50%;
	width: 100%;
}

.heading.heading-middle-border h1, .heading.heading-middle-border h2, .heading.heading-middle-border h3, .heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
	position: relative;
	background: #FFF;
	display: inline-block;
}

.heading.heading-middle-border h1 {
	padding-right: 15px;
}

.heading.heading-middle-border h2, .heading.heading-middle-border h3 {
	padding-right: 10px;
}

.heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
	padding-right: 5px;
}

.heading.heading-middle-border-reverse {
	text-align: right;
}

.heading.heading-middle-border-reverse h1, .heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3, .heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
	padding-right: 0;
}

.heading.heading-middle-border-reverse h1 {
	padding-left: 15px;
}

.heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3 {
	padding-left: 10px;
}

.heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
	padding-left: 5px;
}

.heading.heading-middle-border-center {
	text-align: center;
}

.heading.heading-middle-border-center h1 {
	padding-left: 15px;
	padding-right: 15px;
}

.heading.heading-middle-border-center h2, .heading.heading-middle-border-center h3 {
	padding-left: 10px;
	padding-right: 10px;
}

.heading.heading-middle-border-center h4, .heading.heading-middle-border-center h5, .heading.heading-middle-border-center h6 {
	padding-left: 5px;
	padding-right: 5px;
}

.heading.heading-border-xs:before {
	border-top-width: 1px;
}

.heading.heading-border-sm:before {
	border-top-width: 2px;
}

.heading.heading-border-lg:before {
	border-top-width: 3px;
}

.heading.heading-border-xl:before {
	border-top-width: 5px;
}

/* Modal Titles */
.modal-header h3 {
	color: #333;
	text-transform: none;
}

/* Responsive */
@media (max-width: 479px) {
	h2 {
		line-height: 40px;
	}
}

@media (max-width: 767px) {
	h1.big {
		font-size: 3.2em;
		line-height: 42px;
	}
}
/* Blockquote */
blockquote {
	font-size: 1em;
}

blockquote.with-borders {
	border-radius: 6px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	padding: 15px 20px;
}

/* Paragraphs */
p {
	color: #777;
	line-height: 24px;
	margin: 0 0 20px;
}

p.featured {
	font-size: 1.6em;
	line-height: 1.5em;
}

p.short {
	margin-bottom: 0;
}

p.tall {
	margin-bottom: 20px;
}

p.taller {
	margin-bottom: 40px;
}

p .alternative-font {
	display: inline-block;
	margin-top: -15px;
	position: relative;
	top: 3px;
	margin-bottom: -6px;
}

/* Links */
a, a:hover, a:focus {
	color: #CCC;
}

/* Drop Caps */
p.drop-caps:first-letter {
	float: left;
	font-size: 75px;
	line-height: 60px;
	padding: 4px;
	margin-right: 5px;
	margin-top: 5px;
	font-family: Georgia;
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #CCC;
	color: #FFF;
	padding: 6px;
	margin-right: 5px;
	border-radius: 4px;
}

/* Default Font Style */
.default-font {
	font-family: "Open Sans", Arial, sans-serif;
}

/* Alternative Font Style */
.alternative-font {
	color: #CCC;
	font-family: "Shadows Into Light", cursive;
	font-size: 1.6em;
}

/* Animations */
.appear-animation {
	opacity: 0;
}

.appear-animation-visible {
	opacity: 1;
}

/* Nav */
.nav > li > a:hover, .nav > li > a:focus {
	background: transparent;
}

/* Nav List */
ul.nav-list li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list li a {
	-webkit-transition: background 0.1s;
	-moz-transition: background 0.1s;
	transition: background 0.1s;
	border-bottom: 1px solid #EDEDDE;
	padding: 8px 0 8px 18px;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
}

ul.nav-list li a:before {
	content: "";
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-left: 4px solid #333;
	margin: 7px 0 0 -9px;
	position: absolute;
}

ul.nav-list li a:hover {
	background-color: #eee;
	text-decoration: none;
}

ul.nav-list li.active > a {
	font-weight: bold;
}

ul.nav-list li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.nav-list.hide-not-active li ul {
	display: none;
}

ul.nav-list.hide-not-active li.active ul {
	display: block;
}

ul.nav-list.show-bg-active .active > a {
	background-color: #f5f5f5;
}

ul.nav-list.show-bg-active .active > a:hover {
	background-color: #eee;
}

ul.narrow li a {
	padding: 4px 0;
}

ul.nav-pills > li.active > a {
	background-color: #CCC;
}

ul.nav-pills > li.active > a:hover, ul.nav-pills > li.active > a:focus {
	background-color: #CCC;
}

/* Nav Pills */
.nav-pills-center {
	text-align: center;
}

.nav-pills-center > li {
	display: inline-block;
	float: none;
}

.nav-pills-sm > li > a {
	padding: 7px 12px;
}

.nav-pills-xs > li > a {
	padding: 5px 10px;
	font-size: 0.9em;
}

/* Nav Secondary */
.nav-secondary {
	background: #fafafa;
	margin: 0;
	padding: 20px 0;
	text-align: center;
	z-index: 99;
	width: 100% !important;
}

.nav-secondary .nav-pills > li {
	float: none;
	display: inline-block;
}

.nav-secondary .nav-pills > li a {
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	padding: 10px 13px;
	text-transform: uppercase;
}

.nav-secondary.sticky-active {
	border-bottom-color: #e9e9e9;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Boxed */
html.boxed .nav-secondary.sticky-active {
	max-width: 1200px;
	left: auto !important;
}

/* Buttons */
.btn {
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.btn-default {
	color: #666;
}

.btn-icon i {
	margin-right: 10px;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 10px;
}

.btn-primary {
	border-color: #CCC;
	background-color: #CCC;
	border-color: #CCC #CCC #b3b3b3;
	color: #FFF;
}

.btn-primary:hover {
	border-color: #d9d9d9 #d9d9d9 #bfbfbf;
	background-color: #d9d9d9;
	color: #FFF;
}

.btn-primary:active, .btn-primary:focus {
	border-color: #bfbfbf;
	background-color: #bfbfbf;
	color: #FFF;
}

.btn-primary.dropdown-toggle {
	border-left-color: #bfbfbf;
}

.btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:active, .btn-primary[disabled]:focus {
	border-color: white;
	background-color: white;
	color: #CCC;
}

.btn-success {
	border-color: #47a447;
	background-color: #47a447;
	border-color: #47a447 #47a447 #388038;
	color: #FFF;
}

.btn-success:hover {
	border-color: #51b451 #51b451 #3f923f;
	background-color: #51b451;
	color: #FFF;
}

.btn-success:active, .btn-success:focus {
	border-color: #3f923f;
	background-color: #3f923f;
	color: #FFF;
}

.btn-success.dropdown-toggle {
	border-left-color: #3f923f;
}

.btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:active, .btn-success[disabled]:focus {
	border-color: #86cb86;
	background-color: #86cb86;
	color: #CCC;
}

.btn-warning {
	border-color: #ed9c28;
	background-color: #ed9c28;
	border-color: #ed9c28 #ed9c28 #d18211;
	color: #FFF;
}

.btn-warning:hover {
	border-color: #efa740 #efa740 #e89113;
	background-color: #efa740;
	color: #FFF;
}

.btn-warning:active, .btn-warning:focus {
	border-color: #e89113;
	background-color: #e89113;
	color: #FFF;
}

.btn-warning.dropdown-toggle {
	border-left-color: #e89113;
}

.btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:active, .btn-warning[disabled]:focus {
	border-color: #f5c786;
	background-color: #f5c786;
	color: #CCC;
}

.btn-danger {
	border-color: #d2322d;
	background-color: #d2322d;
	border-color: #d2322d #d2322d #a82824;
	color: #FFF;
}

.btn-danger:hover {
	border-color: #d74742 #d74742 #bd2d29;
	background-color: #d74742;
	color: #FFF;
}

.btn-danger:active, .btn-danger:focus {
	border-color: #bd2d29;
	background-color: #bd2d29;
	color: #FFF;
}

.btn-danger.dropdown-toggle {
	border-left-color: #bd2d29;
}

.btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:active, .btn-danger[disabled]:focus {
	border-color: #e48481;
	background-color: #e48481;
	color: #CCC;
}

.btn-info {
	border-color: #5bc0de;
	background-color: #5bc0de;
	border-color: #5bc0de #5bc0de #31b0d5;
	color: #FFF;
}

.btn-info:hover {
	border-color: #70c8e2 #70c8e2 #46b8da;
	background-color: #70c8e2;
	color: #FFF;
}

.btn-info:active, .btn-info:focus {
	border-color: #46b8da;
	background-color: #46b8da;
	color: #FFF;
}

.btn-info.dropdown-toggle {
	border-left-color: #46b8da;
}

.btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:active, .btn-info[disabled]:focus {
	border-color: #b0e1ef;
	background-color: #b0e1ef;
	color: #CCC;
}

.btn-dark {
	border-color: #1d2127;
	background-color: #1d2127;
	border-color: #1d2127 #1d2127 #07080a;
	color: #FFF;
}

.btn-dark:hover {
	border-color: #282d36 #282d36 #121518;
	background-color: #282d36;
	color: #FFF;
}

.btn-dark:active, .btn-dark:focus {
	border-color: #121518;
	background-color: #121518;
	color: #FFF;
}

.btn-dark.dropdown-toggle {
	border-left-color: #121518;
}

.btn-dark[disabled], .btn-dark[disabled]:hover, .btn-dark[disabled]:active, .btn-dark[disabled]:focus {
	border-color: #495362;
	background-color: #495362;
	color: #CCC;
}

/* Border Buttons */
.btn-borders {
	border-width: 3px;
}

.btn-borders.btn-primary {
	background: transparent;
	border-color: #CCC;
	color: #CCC;
	text-shadow: none;
}

.btn-borders.btn-primary:hover, .btn-borders.btn-primary:focus {
	background-color: #d9d9d9;
	border-color: #CCC !important;
	color: #FFF;
}

.btn-borders.btn-success {
	background: transparent;
	border-color: #47a447;
	color: #47a447;
	text-shadow: none;
}

.btn-borders.btn-success:hover, .btn-borders.btn-success:focus {
	background-color: #51b451;
	border-color: #47a447 !important;
	color: #FFF;
}

.btn-borders.btn-warning {
	background: transparent;
	border-color: #ed9c28;
	color: #ed9c28;
	text-shadow: none;
}

.btn-borders.btn-warning:hover, .btn-borders.btn-warning:focus {
	background-color: #efa740;
	border-color: #ed9c28 !important;
	color: #FFF;
}

.btn-borders.btn-danger {
	background: transparent;
	border-color: #d2322d;
	color: #d2322d;
	text-shadow: none;
}

.btn-borders.btn-danger:hover, .btn-borders.btn-danger:focus {
	background-color: #d74742;
	border-color: #d2322d !important;
	color: #FFF;
}

.btn-borders.btn-info {
	background: transparent;
	border-color: #5bc0de;
	color: #5bc0de;
	text-shadow: none;
}

.btn-borders.btn-info:hover, .btn-borders.btn-info:focus {
	background-color: #70c8e2;
	border-color: #5bc0de !important;
	color: #FFF;
}

.btn-borders.btn-dark {
	background: transparent;
	border-color: #1d2127;
	color: #1d2127;
	text-shadow: none;
}

.btn-borders.btn-dark:hover, .btn-borders.btn-dark:focus {
	background-color: #282d36;
	border-color: #1d2127 !important;
	color: #FFF;
}

/* Border Buttons - Sizes */
.btn-borders {
	padding: 4px 12px;
}

.btn-borders.btn-lg, .btn-group-lg > .btn-borders.btn {
	padding: 8px 16px;
}

.btn-borders.btn-sm, .btn-group-sm > .btn-borders.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-borders.btn-xs, .btn-group-xs > .btn-borders.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* 3D Buttons */
.btn-3d {
	border-bottom-width: 3px;
	padding: 5px 12px;
	border-radius: 6px;
}

.btn-3d.btn-lg, .btn-group-lg > .btn-3d.btn {
	padding: 9px 16px;
}

.btn-3d.btn-sm, .btn-group-sm > .btn-3d.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-3d.btn-xs, .btn-group-xs > .btn-3d.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* Sizes */
.btn-xlg {
	border-radius: 6px;
	font-size: 16px;
	padding: 12px 33px;
}

/* Labels */
.label-primary {
	background-color: #CCC;
	color: #FFF;
}

.label-success {
	background-color: #47a447;
	color: #FFF;
}

.label-warning {
	background-color: #ed9c28;
	color: #FFF;
}

.label-danger {
	background-color: #d2322d;
	color: #FFF;
}

.label-info {
	background-color: #5bc0de;
	color: #FFF;
}

.label-dark {
	background-color: #1d2127;
	color: #FFF;
}

.label.label-sm {
	font-size: 0.7em;
}

.label.label-lg {
	font-size: 1em;
}

/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	border-color: #CCC;
}

.form-control.error {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

/* Forms Validations */
label.valid {
	display: inline-block;
	text-indent: -9999px;
}

label.error {
	color: #c10000;
	font-size: 0.9em;
	line-height: 18px;
	padding: 5px 0 0;
}

label.error#captcha-error {
	margin-top: 15px;
}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
}

.captcha input {
	border: 0;
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: left;
	position: relative;
	top: -5px;
	left: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
}

.captcha-refresh a, .captcha-refresh a:hover, .captcha-refresh a:focus, .captcha-refresh a:active {
	color: #333;
}

/* Tables */
.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
	background-color: #CCC;
	color: #FFF;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
	background-color: #47a447;
	color: #FFF;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
	background-color: #ed9c28;
	color: #FFF;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
	background-color: #d2322d;
	color: #FFF;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
	background-color: #5bc0de;
	color: #FFF;
}

.table > thead > tr > td.dark,
.table > tbody > tr > td.dark,
.table > tfoot > tr > td.dark,
.table > thead > tr > th.dark,
.table > tbody > tr > th.dark,
.table > tfoot > tr > th.dark,
.table > thead > tr.dark > td,
.table > tbody > tr.dark > td,
.table > tfoot > tr.dark > td,
.table > thead > tr.dark > th,
.table > tbody > tr.dark > th,
.table > tfoot > tr.dark > th {
	background-color: #1d2127;
	color: #FFF;
}

/* Icons */
.featured-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.featured-icon i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Font Awesome List */
.sample-icon-list {
	margin-top: 22px;
}

.sample-icon-list .sample-icon a {
	display: block;
	color: #222222;
	line-height: 32px;
	height: 32px;
	padding-left: 10px;
	border-radius: 4px;
	font-size: 11px;
}

.sample-icon-list .sample-icon a .fa, .sample-icon-list .sample-icon a .icons {
	width: 32px;
	font-size: 14px;
	display: inline-block;
	text-align: right;
	margin-right: 10px;
}

.sample-icon-list .sample-icon a:hover {
	color: #ffffff;
	text-decoration: none;
}

.sample-icon-list .sample-icon a:hover .fa, .sample-icon-list .sample-icon a:hover .icons {
	font-size: 28px;
	vertical-align: -6px;
}

/* Thumbnails */
.img-rounded {
	border-radius: 4px;
}

.img-thumbnail {
	border-radius: 4px;
	position: relative;
}

.img-thumbnail img {
	border-radius: 4px;
}

.img-thumbnail .zoom {
	background: #CCC;
	border-radius: 100%;
	bottom: 8px;
	color: #FFF;
	display: block;
	height: 30px;
	padding: 6px;
	position: absolute;
	right: 8px;
	text-align: center;
	width: 30px;
}

.img-thumbnail .zoom i {
	font-size: 14px;
	left: -1px;
	position: relative;
	top: -3px;
}

.img-thumbnail.img-thumbnail-hover-icon {
	position: relative;
}

.img-thumbnail.img-thumbnail-hover-icon img {
	position: relative;
	z-index: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:before {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	background: rgba(0, 0, 0, 0.65);
	border-radius: 4px;
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	z-index: 2;
}

.img-thumbnail.img-thumbnail-hover-icon:after {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	font-family: FontAwesome;
	color: #FFF;
	content: "\f00e";
	font-size: 30px;
	height: 30px;
	position: absolute;
	top: 30%;
	margin-top: -15px;
	display: block;
	text-align: center;
	width: 100%;
	opacity: 0;
	z-index: 3;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:before {
	opacity: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:after {
	top: 50%;
	opacity: 1;
}

/* Image Gallery */
ul.image-gallery {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.image-gallery .image-gallery-item {
	margin-bottom: 35px;
}

/* Thumbnail Gallery */
ul.thumbnail-gallery {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}

ul.thumbnail-gallery li {
	display: inline-block;
	margin: 10px 10px 0 0;
	padding: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.thumbnail {
		border: 0;
		text-align: center;
	}
}
/* Masonry */
.masonry .masonry-item {
	width: 25%;
}

.masonry .masonry-item.w2 {
	width: 50%;
}

.masonry .masonry-item img {
	border-radius: 0 !important;
}

/* Masonry Loading */
.masonry-loader {
	height: auto;
	overflow: visible;
	position: relative;
}

.masonry-loader:after {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	content: ' ';
	background: #FFF;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}

.masonry-loader .bounce-loader {
	display: none;
}

.masonry-loader.masonry-loader-showing {
	height: 200px;
	overflow: hidden;
}

.masonry-loader.masonry-loader-showing:after {
	opacity: 1;
}

.masonry-loader.masonry-loader-showing .bounce-loader {
	display: block;
}

.masonry-loader.masonry-loader-loaded:after {
	display: none;
}

/* Mockup */
@media (min-width: 992px) {
	.mockup-landing-page {
		margin-top: -120px;
	}
}
/* List */
.list li {
	margin-bottom: 13px;
}

.list.pull-left li {
	text-align: left;
}

.list.list-icons {
	list-style: none;
	padding-left: 0;
	padding-right: 0;
}

.list.list-icons li {
	position: relative;
	padding-left: 25px;
}

.list.list-icons li > .fa:first-child,
.list.list-icons li > .icons:first-child, .list.list-icons li a:first-child > .fa:first-child,
.list.list-icons li a:first-child > .icons:first-child {
	position: absolute;
	left: 0;
	top: 5px;
}

.list.list-icons.list-icons-style-2 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .icons:first-child {
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 4px;
}

.list.list-icons.list-icons-style-3 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	color: #FFF;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 5px;
}

.list.list-icons.list-icons-sm li {
	padding-left: 13px;
	margin-bottom: 5px;
}

.list.list-icons.list-icons-sm li > .fa:first-child,
.list.list-icons.list-icons-sm li > .icons:first-child, .list.list-icons.list-icons-sm li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm li a:first-child > .icons:first-child {
	font-size: 0.8em;
	top: 7px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-lg li {
	padding-top: 5px;
	padding-left: 32px;
}

.list.list-icons.list-icons-lg li > .fa:first-child,
.list.list-icons.list-icons-lg li > .icons:first-child, .list.list-icons.list-icons-lg li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg li a:first-child > .icons:first-child {
	font-size: 1.5em;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-reverse li {
	text-align: right;
	padding-right: 25px;
	padding-left: 0;
}

.list.list-icons.list-icons-reverse li > .fa:first-child,
.list.list-icons.list-icons-reverse li > .icons:first-child, .list.list-icons.list-icons-reverse li a:first-child > .fa:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .icons:first-child {
	position: absolute;
	left: auto;
	right: 0;
	top: 5px;
}

.list.list-icons.list-icons-reverse.list-icons-style-2 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-style-3 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-sm li {
	padding-left: 0;
	padding-right: 13px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg li {
	padding-left: 0;
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
	padding-right: 42px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
	padding-right: 42px;
}

.list.list-borders li {
	border-bottom: 1px solid #DBDBDB;
	margin-top: 9px;
	margin-bottom: 0;
	padding-bottom: 9px;
}

.list.list-borders li:last-child {
	border-bottom: 0;
}

.list.list-side-borders {
	border-left: 1px solid #DBDBDB;
	padding-left: 10px;
}

.list.list-ordened {
	counter-reset: custom-counter;
	list-style: none;
}

.list.list-ordened li {
	position: relative;
	padding-left: 22px;
}

.list.list-ordened li:before {
	position: absolute;
	top: 0;
	left: 0;
	content: counter(custom-counter);
	counter-increment: custom-counter;
	font-weight: bold;
}

.list.list-ordened.list-ordened-style-2 li, .list.list-ordened.list-ordened-style-3 li {
	padding-left: 32px;
}

.list.list-ordened.list-ordened-style-2 li:before, .list.list-ordened.list-ordened-style-3 li:before {
	border-radius: 100%;
	border-style: solid;
	border-width: 1px;
	min-width: 26px;
	padding: 0;
	text-align: center;
}

.list.list-ordened.list-ordened-style-3 li:before {
	color: #FFF;
	border: 0;
}

.list.list-unstyled {
	padding-left: 0;
	padding-right: 0;
}

dl dd {
	margin-bottom: 15px;
}

/* Sitemap */
ul.sitemap > li > a {
	font-weight: bold;
}

ul.sitemap.list li {
	margin: 0 0 3px 0;
}

ul.sitemap.list ul {
	margin-bottom: 5px;
	margin-top: 5px;
}

/* Accordion */
.panel-group .panel-heading {
	padding: 0;
	border-radius: 3px;
}

.panel-group .panel-heading a {
	display: block;
	padding: 10px 15px;
}

.panel-group .panel-heading a:hover, .panel-group .panel-heading a:focus {
	text-decoration: none;
}

.panel-group .panel-heading a [class^="icon-"] {
	margin-right: 4px;
	position: relative;
	top: 1px;
}

.panel-group .panel-heading a .fa, .panel-group .panel-heading a .icons {
	display: inline-block;
	margin-right: 5px;
	position: relative;
	top: -1px;
}

.panel-group.panel-group-sm .panel-heading a {
	padding: 5px 15px;
	font-size: 0.9em;
}

.panel-group.panel-group-sm .panel-body {
	font-size: 0.9em;
}

.panel-group.panel-group-lg .panel-heading a {
	padding: 15px 15px;
	font-size: 1em;
	font-weight: 600;
}

.panel-group.without-bg .panel-default {
	background: transparent !important;
}

.panel-group.without-bg .panel-default > .panel-heading {
	background: transparent !important;
}

.panel-group.without-bg .panel-body {
	padding-top: 0;
	padding-bottom: 0;
}

.panel-group.without-borders .panel {
	border: 0;
	box-shadow: none;
	border-bottom: 1px solid #DDD;
	border-radius: 0;
}

.panel-group.without-borders .panel-heading a {
	padding-left: 0;
	padding-right: 0;
}

.panel-group.without-borders .panel-body {
	padding: 0;
}

/* Tabs */
.tabs {
	border-radius: 4px;
	margin-bottom: 20px;
}

.nav-tabs {
	margin: 0;
	padding: 0;
	font-size: 0;
	border-bottom-color: #EEE;
}

.nav-tabs li {
	display: inline-block;
	float: none;
}

.nav-tabs li:last-child a {
	margin-right: 0;
}

.nav-tabs li a {
	border-radius: 5px 5px 0 0;
	font-size: 14px;
	margin-right: 1px;
}

.nav-tabs li a, .nav-tabs li a:hover {
	background: #F4F4F4;
	border-bottom: none;
	border-left: 1px solid #EEE;
	border-right: 1px solid #EEE;
	border-top: 3px solid #EEE;
	color: #CCC;
}

.nav-tabs li a:hover {
	border-bottom-color: transparent;
	border-top: 3px solid #CCC;
	box-shadow: none;
}

.nav-tabs li a:active, .nav-tabs li a:focus {
	border-bottom: 0;
}

.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top: 3px solid #CCC;
	color: #CCC;
}

.tab-content {
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	background-color: #FFF;
	border: 1px solid #EEE;
	border-top: 0;
	padding: 15px;
}

/* Right Aligned */
.nav-tabs.nav-right {
	text-align: right;
}

/* Bottom Tabs */
.tabs.tabs-bottom {
	margin: 0 0 20px 0;
	padding: 0;
}

.tabs.tabs-bottom .tab-content {
	border-radius: 4px 4px 0 0;
	border-bottom: 0;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs {
	border-bottom: none;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li {
	margin-bottom: 0;
	margin-top: -1px;
}

.tabs.tabs-bottom .nav-tabs li:last-child a {
	margin-right: 0;
}

.tabs.tabs-bottom .nav-tabs li a {
	border-radius: 0 0 5px 5px;
	font-size: 14px;
	margin-right: 1px;
}

.tabs.tabs-bottom .nav-tabs li a, .tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #EEE;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #CCC;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li.active a,
.tabs.tabs-bottom .nav-tabs li.active a:hover,
.tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom: 3px solid #CCC;
	border-top-color: transparent;
}

/* Vertical */
.tabs-vertical {
	display: table;
	width: 100%;
	padding: 0;
	border-top: 1px solid #EEE;
}

.tabs-vertical .tab-content {
	display: table-cell;
	vertical-align: top;
}

.tabs-vertical .nav-tabs {
	border-bottom: none;
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	vertical-align: top;
}

.tabs-vertical .nav-tabs > li {
	display: block;
}

.tabs-vertical .nav-tabs > li a {
	border-radius: 0;
	display: block;
	padding-top: 10px;
}

.tabs-vertical .nav-tabs > li a, .tabs-vertical .nav-tabs > li a:hover, .tabs-vertical .nav-tabs > li a:focus {
	border-bottom: none;
	border-top: none;
}

.tabs-vertical .nav-tabs > li.active a,
.tabs-vertical .nav-tabs > li.active a:hover, .tabs-vertical .nav-tabs > li.active:focus {
	border-top: none;
}

/* Vertical - Left Side */
.tabs-left {
	padding: 0;
}

.tabs-left .tab-content {
	border-radius: 0 5px 5px 5px;
	border-left: none;
}

.tabs-left .nav-tabs > li {
	margin-right: -1px;
}

.tabs-left .nav-tabs > li:first-child a {
	border-radius: 5px 0 0 0;
}

.tabs-left .nav-tabs > li:last-child a {
	border-radius: 0 0 0 5px;
	border-bottom: 1px solid #eee;
}

.tabs-left .nav-tabs > li a {
	border-right: 1px solid #EEE;
	border-left: 3px solid #EEE;
	margin-right: 1px;
	margin-left: -3px;
}

.tabs-left .nav-tabs > li a:hover {
	border-left-color: #CCC;
}

.tabs-left .nav-tabs > li.active a,
.tabs-left .nav-tabs > li.active a:hover,
.tabs-left .nav-tabs > li.active a:focus {
	border-left: 3px solid #CCC;
	border-right-color: #FFF;
}

/* Vertical - Right Side */
.tabs-right {
	padding: 0;
}

.tabs-right .tab-content {
	border-radius: 5px 0 5px 5px;
	border-right: none;
}

.tabs-right .nav-tabs > li {
	margin-left: -1px;
}

.tabs-right .nav-tabs > li:first-child a {
	border-radius: 0 5px 0 0;
}

.tabs-right .nav-tabs > li:last-child a {
	border-radius: 0 0 5px 0;
	border-bottom: 1px solid #eee;
}

.tabs-right .nav-tabs > li a {
	border-right: 3px solid #EEE;
	border-left: 1px solid #EEE;
	margin-right: 1px;
	margin-left: 1px;
}

.tabs-right .nav-tabs > li a:hover {
	border-right-color: #CCC;
}

.tabs-right .nav-tabs > li.active a,
.tabs-right .nav-tabs > li.active a:hover,
.tabs-right .nav-tabs > li.active a:focus {
	border-right: 3px solid #CCC;
	border-left: 1px solid #FFF;
}

/* Justified */
.nav-tabs.nav-justified {
	padding: 0;
	margin-bottom: -1px;
}

.nav-tabs.nav-justified li {
	margin-bottom: 0;
}

.nav-tabs.nav-justified li:first-child a,
.nav-tabs.nav-justified li:first-child a:hover {
	border-radius: 5px 0 0 0;
}

.nav-tabs.nav-justified li:last-child a,
.nav-tabs.nav-justified li:last-child a:hover {
	border-radius: 0 5px 0 0;
}

.nav-tabs.nav-justified li a {
	border-bottom: 1px solid #DDD;
	border-radius: 0;
	margin-right: 0;
}

.nav-tabs.nav-justified li a:hover, .nav-tabs.nav-justified li a:focus {
	border-bottom: 1px solid #DDD;
}

.nav-tabs.nav-justified li.active a,
.nav-tabs.nav-justified li.active a:hover,
.nav-tabs.nav-justified li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a {
	border-bottom: 1px solid #FFF;
}

.nav-tabs.nav-justified li.active a, .nav-tabs.nav-justified li.active a:hover, .nav-tabs.nav-justified li.active a:focus {
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a:hover {
	border-bottom: 1px solid #FFF;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom {
	padding: 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
	border-top: none;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
	margin-right: 0;
	border-top-color: transparent;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a {
	border-radius: 0 0 0 5px;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a {
	margin-right: 0;
	border-radius: 0 0 5px 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:focus {
	border-top-color: transparent;
}

/* Center */
.tabs-center .nav-tabs {
	margin: 0 auto;
	text-align: center;
}

/* Navigation */
.tabs-navigation {
	border-radius: 5px;
}

.tabs-navigation .nav-tabs > li {
	margin-bottom: 1px;
}

.tabs-navigation .nav-tabs > li a {
	color: #777;
	padding: 18px;
	line-height: 100%;
}

.tabs-navigation .nav-tabs > li a:before {
	font-family: FontAwesome;
	content: "\f054";
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -7px;
}

.tabs-navigation .nav-tabs > li a .fa, .tabs-navigation .nav-tabs > li a .icons {
	margin-right: 4px;
}

.tabs-navigation .nav-tabs > li:first-child a {
	border-radius: 5px 5px 0 0;
}

.tabs-navigation .nav-tabs > li.active a, .tabs-navigation .nav-tabs > li.active a:hover, .tabs-navigation .nav-tabs > li.active a:focus {
	border-right-color: #eee;
}

.tabs-navigation .nav-tabs > li:last-child a {
	border-radius: 0 0 5px 5px;
}

.tab-pane-navigation {
	display: none;
}

.tab-pane-navigation.active {
	display: block;
}

/* Simple */
.tabs.tabs-simple .nav-tabs {
	border: 0;
	margin-bottom: 10px;
}

.tabs.tabs-simple .nav-tabs > li {
	margin-left: 0;
	margin-bottom: 20px;
}

.tabs.tabs-simple .nav-tabs > li a, .tabs.tabs-simple .nav-tabs > li a:hover, .tabs.tabs-simple .nav-tabs > li a:focus {
	padding: 15px 30px;
	background: none;
	border: 0;
	border-bottom: 3px solid #eee;
	border-radius: 0;
	color: #777;
	font-size: 16px;
}

.tabs.tabs-simple .tab-content {
	background: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	box-shadow: none;
}

/* Responsive */
@media (max-width: 479px) {
	.tabs .nav.nav-tabs.nav-justified li {
		display: block;
		margin-bottom: -5px;
	}

	.tabs .nav.nav-tabs.nav-justified li a {
		border-top-width: 3px !important;
		border-bottom-width: 0 !important;
	}

	.tabs .nav.nav-tabs.nav-justified li:first-child a, .tabs .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 5px 5px 0 0;
	}

	.tabs .nav.nav-tabs.nav-justified li:last-child a, .tabs .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
		margin-bottom: 0;
		margin-top: -5px;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
		border-bottom-width: 3px !important;
		border-top-width: 0 !important;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0 0 5px 5px;
	}
}
/* Toggles */
.toggle {
	margin: 10px 0 20px;
	position: relative;
	clear: both;
}

.toggle .toggle {
	margin: 10px 0 0;
}

.toggle > input {
	cursor: pointer;
	filter: alpha(opacity=0);
	height: 45px;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 2;
}

.toggle > label {
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
	background: #F4F4F4;
	border-left: 3px solid #CCC;
	border-radius: 5px;
	color: #CCC;
	display: block;
	font-size: 1.1em;
	min-height: 20px;
	padding: 12px 20px 12px 10px;
	position: relative;
	cursor: pointer;
	font-weight: 400;
}

.toggle > label:-moz-selection {
	background: none;
}

.toggle > label i.fa-minus {
	display: none;
}

.toggle > label i.fa-plus {
	display: inline;
}

.toggle > label:selection {
	background: none;
}

.toggle > label:before {
	border: 6px solid transparent;
	border-left-color: inherit;
	content: '';
	margin-top: -6px;
	position: absolute;
	right: 4px;
	top: 50%;
}

.toggle > label:hover {
	background: #f5f5f5;
}

.toggle > label + p {
	color: #999;
	display: block;
	overflow: hidden;
	padding-left: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 25px;
}

.toggle > label i {
	font-size: 0.7em;
	margin-right: 8px;
	position: relative;
	top: -1px;
}

.toggle > .toggle-content {
	display: none;
}

.toggle > .toggle-content > p {
	margin-bottom: 0;
	padding: 10px 0;
}

.toggle.active i.fa-minus {
	display: inline;
}

.toggle.active i.fa-plus {
	display: none;
}

.toggle.active > label {
	background: #F4F4F4;
	border-color: #CCC;
}

.toggle.active > label:before {
	border: 6px solid transparent;
	border-top-color: #FFF;
	margin-top: -3px;
	right: 10px;
}

.toggle.active > p {
	white-space: normal;
}

.toggle > p.preview-active {
	height: auto;
	white-space: normal;
}

.toggle.toggle-sm .toggle > label {
	font-size: 0.9em;
	padding: 6px 20px 6px 10px;
}

.toggle.toggle-sm .toggle > .toggle-content > p {
	font-size: 0.9em;
}

.toggle.toggle-lg .toggle > label {
	font-size: 1.1em;
	padding: 16px 20px 16px 20px;
}

.toggle.toggle-lg .toggle > .toggle-content > p {
	font-size: 1.1em;
}

.toggle.toggle-simple .toggle > label {
	border-radius: 0;
	border: 0;
	background: transparent;
	color: #777;
	padding-left: 32px;
	margin-bottom: -10px;
	font-size: 1em;
	font-weight: 600;
	color: #1d2127;
}

.toggle.toggle-simple .toggle > label:after {
	content: '';
	position: absolute;
	left: 0;
	display: block;
	width: 24px;
	height: 24px;
	background: #CCC;
	top: 23px;
	margin-top: -12px;
	border-radius: 3px;
	z-index: 1;
}

.toggle.toggle-simple .toggle > label:before {
	border: 0;
	left: 7px;
	right: auto;
	margin-top: 0;
	top: 12px;
	font-family: FontAwesome;
	content: "\f067";
	z-index: 2;
	color: #FFF;
	font-size: 13px;
}

.toggle.toggle-simple .toggle.active > label {
	background: transparent;
	color: #1d2127;
}

.toggle.toggle-simple .toggle.active > label:before {
	content: "\f068";
}

/* Owl Carousel */
.owl-carousel {
	margin-bottom: 20px;
}

.owl-carousel .owl-item img {
	transform-style: unset;
}

.owl-carousel .thumbnail {
	margin-right: 1px;
}

.owl-carousel .item-video {
	height: 300px;
}

.owl-carousel .owl-nav {
	top: 50%;
	position: absolute;
	margin-top: -20px;
	width: 100%;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
	display: inline-block;
	position: absolute;
	width: 30px;
	height: 30px;
}

.owl-carousel .owl-nav .owl-prev {
	left: -5px;
}

.owl-carousel .owl-nav .owl-prev:before {
	font-family: FontAwesome;
	content: "\f053";
	position: relative;
	left: -1px;
	top: 1px;
}

.owl-carousel .owl-nav .owl-next {
	right: -5px;
}

.owl-carousel .owl-nav .owl-next:before {
	font-family: FontAwesome;
	content: "\f054";
	position: relative;
	left: 2px;
	top: 1px;
}

.owl-carousel.stage-margin .owl-stage-outer {
	margin-left: 40px !important;
	margin-right: 40px !important;
}

.owl-carousel.stage-margin .owl-stage-outer .owl-stage {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.owl-carousel.show-nav-hover .owl-nav {
	opacity: 0;
	-webkit-transition: opacity 0.2s ease-in-out;
	-moz-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}

.owl-carousel.show-nav-hover .owl-nav .owl-prev {
	left: -20px;
}

.owl-carousel.show-nav-hover .owl-nav .owl-next {
	right: -20px;
}

.owl-carousel.show-nav-hover:hover .owl-nav {
	opacity: 1;
}

.owl-carousel.show-nav-title .owl-nav {
	top: 0;
	right: 0;
	margin-top: -45px;
	width: auto;
}

.owl-carousel.show-nav-title .owl-nav [class*="owl-"], .owl-carousel.show-nav-title .owl-nav [class*="owl-"]:hover, .owl-carousel.show-nav-title .owl-nav [class*="owl-"]:active {
	font-size: 18px;
	background: transparent !important;
	width: 18px;
	font-size: 18px;
}

.owl-carousel.show-nav-title .owl-nav .owl-prev {
	left: -45px;
}

.owl-carousel.rounded-nav .owl-nav [class*="owl-"], .owl-carousel.rounded-nav .owl-nav [class*="owl-"]:hover {
	padding: 3px 7px;
	border-radius: 50%;
	background: transparent;
	border: 1px solid #999 !important;
	color: #999;
}

.owl-carousel.nav-bottom .owl-nav {
	position: static;
	margin: 0;
	padding: 0;
	width: auto;
}

.owl-carousel.nav-bottom .owl-nav .owl-prev, .owl-carousel.nav-bottom .owl-nav .owl-next {
	position: static;
}

.owl-carousel.nav-inside .owl-nav, .owl-carousel.nav-inside .owl-dots {
	bottom: 2px;
	margin-top: 10px;
	position: absolute;
	right: 6px;
}

.owl-carousel.full-width .owl-nav, .owl-carousel.big-nav .owl-nav {
	margin-top: -30px;
}

.owl-carousel.full-width .owl-nav [class*="owl-"], .owl-carousel.full-width .owl-nav [class*="owl-"]:hover, .owl-carousel.big-nav .owl-nav [class*="owl-"], .owl-carousel.big-nav .owl-nav [class*="owl-"]:hover {
	height: auto;
	padding: 20px 0;
}

.owl-carousel.full-width .owl-nav .owl-prev, .owl-carousel.big-nav .owl-nav .owl-prev {
	border-radius: 0 4px 4px 0;
}

.owl-carousel.full-width .owl-nav .owl-next, .owl-carousel.big-nav .owl-nav .owl-next {
	border-radius: 4px 0 0 4px;
}

.owl-carousel.top-border {
	border-top: 1px solid #DBDBDB;
	padding-top: 18px;
}

/* Spaced */
.owl-carousel-spaced {
	margin-left: -5px;
}

.owl-carousel-spaced .owl-item > div {
	margin: 5px;
}

/* Testimonials */
.owl-carousel.testimonials img {
	display: inline-block;
	height: 70px;
	width: 70px;
}

/* Responsive */
@media (max-width: 479px) {
	.owl-carousel-spaced {
		margin-left: 0;
	}
}
/* Carousel Areas */
.carousel-areas {
	background: transparent url(../img/patterns/stripes.png) repeat 0 0;
	margin-bottom: -10px;
	padding: 10px 10px 0 10px;
	border-radius: 6px 6px 0 0;
}

.carousel-areas .owl-carousel {
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.carousel-areas .owl-carousel .owl-nav .owl-prev {
	left: -55px;
}

.carousel-areas .owl-carousel .owl-nav .owl-next {
	right: -55px;
}

.carousel-areas .owl-carousel:first-child img {
	border-radius: 6px 6px 0 0;
}

/* Responsive */
@media (max-width: 991px) {
	.carousel-areas .owl-carousel .owl-nav {
		display: none;
	}
}
/* Video */
section.video {
	-webkit-transform: translate3d(0, 0, 0);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
}

section.video .container {
	position: relative;
	z-index: 3;
}

section.video .video-overlay {
	background: transparent url(../img/video-overlay.png) repeat 0 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

section.video.video-overlay-dark .video-overlay {
	background-image: url(../img/video-overlay-dark.png);
}

html.boxed section.video > div:first-child {
	z-index: 0 !important;
}

html.boxed section.video > div:first-child video {
	z-index: 0 !important;
}

/* Popup */
.popup-inline-content,
.mfp-content .ajax-container {
	background: none repeat scroll 0 0 #FFF;
	margin: 40px auto;
	max-width: 1170px;
	padding: 20px 30px;
	position: relative;
	text-align: left;
	display: none;
}

.mfp-content .ajax-container {
	display: block;
}

.mfp-wrap .popup-inline-content {
	display: block;
}

/* Close */
.mfp-close,
.mfp-close-btn-in .mfp-close {
	font-family: "Open Sans", Arial, sans-serif;
	font-weight: 600;
	font-size: 22px;
	color: #838383;
}

/* No Margins */
.mfp-no-margins img.mfp-img {
	padding: 0;
}

.mfp-no-margins .mfp-figure:after {
	top: 0;
	bottom: 0;
}

.mfp-no-margins .mfp-container {
	padding: 0;
}

/* Zoom */
.mfp-with-zoom .mfp-container, .mfp-with-zoom.mfp-bg {
	opacity: 0.001;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, .mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* Animnate */
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}

.my-mfp-zoom-in.mfp-bg {
	opacity: 0.001;
	/* Chrome opacity transition bug */
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}

.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	-webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective(600px) rotateX(0);
	-moz-transform: translateY(0) perspective(600px) rotateX(0);
	-ms-transform: translateY(0) perspective(600px) rotateX(0);
	-o-transform: translateY(0) perspective(600px) rotateX(0);
	transform: translateY(0) perspective(600px) rotateX(0);
}

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;
	-webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-bg {
	opacity: 0.01;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* Fade */
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

/* Dialog */
.dialog {
	background: white;
	padding: 20px 30px;
	text-align: left;
	margin: 40px auto;
	position: relative;
	max-width: 600px;
}

.dialog.dialog-xs {
	max-width: 200px;
}

.dialog.dialog-sm {
	max-width: 400px;
}

.dialog.dialog-md {
	max-width: 600px;
}

.dialog.dialog-lg {
	max-width: 900px;
}

/* White Popup Block */
.white-popup-block {
	background: #FFF;
	padding: 20px 30px;
	text-align: left;
	max-width: 600px;
	margin: 40px auto;
	position: relative;
}

.white-popup-block.white-popup-block-xs {
	max-width: 200px;
}

.white-popup-block.white-popup-block-sm {
	max-width: 400px;
}

.white-popup-block.white-popup-block-md {
	max-width: 600px;
}

.white-popup-block.white-popup-block-lg {
	max-width: 900px;
}

/* Lightbox Opened */
html.lightbox-opened.sticky-header-active #header .header-body {
	padding-right: 17px;
}

/* Modal Opened */
.modal-open {
	padding-right: 0 !important;
}

/* Grid */
.col-half-section {
	width: 100%;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	max-width: 570px;
	float: left;
	margin-left: 30px;
	margin-right: 0;
}

.col-half-section.col-half-section-right {
	float: right;
	margin-left: 0;
	margin-right: 30px;
}

@media (max-width: 1199px) {
	.col-half-section {
		max-width: 468px;
	}
}

@media (max-width: 991px) {
	.col-half-section {
		max-width: 740px;
		float: none !important;
		margin: 0 auto !important;
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}
/* Miscellaneous */
.pull-none {
	float: none !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

@media (max-width: 767px) {
	.pull-xs-left {
		float: left;
	}

	.pull-xs-right {
		float: right;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.pull-sm-left {
		float: left;
	}

	.pull-sm-right {
		float: right;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.pull-md-left {
		float: left;
	}

	.pull-md-right {
		float: right;
	}
}

@media (min-width: 1200px) {
	.pull-lg-left {
		float: left;
	}

	.pull-lg-right {
		float: right;
	}
}

.align-center, .center {
	text-align: center !important;
}

.align-left {
	text-align: left !important;
}

.align-right {
	text-align: right !important;
}

.bold {
	font-weight: bold;
}

.semi-bold {
	font-weight: 600;
}

.inverted {
	color: #FFF;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}

h1 .inverted {
	padding-left: 10px;
	padding-right: 10px;
}

h2 .inverted {
	padding-left: 7px;
	padding-right: 7px;
}

h3 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h4 .inverted {
	padding-left: 4px;
	padding-right: 4px;
}

h5 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h6 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

/* Cursor */
.cur-pointer {
	cursor: pointer;
}

/* Font Size */
.font-size-xs {
	font-size: 0.8em !important;
}

.font-size-sm {
	font-size: 0.9em !important;
}

.font-size-md {
	font-size: 1em !important;
}

.font-size-lg {
	font-size: 1.1em !important;
}

.font-size-xl {
	font-size: 1.2em !important;
}

/* Line Height */
.line-height-xs {
	line-height: 16px !important;
}

.line-height-sm {
	line-height: 20px !important;
}

.line-height-md {
	line-height: 24px !important;
}

.line-height-lg {
	line-height: 28px !important;
}

.line-height-xl {
	line-height: 32px !important;
}

/* Outline */
.outline-none {
	outline: 0 !important;
}

/* Text Decoration */
.text-decoration-none {
	text-decoration: none !important;
}

/* Display */
.display-block {
	display: block !important;
}

.display-inline-block {
	display: inline-block !important;
}

.display-inline {
	display: inline !important;
}

/* Block Link */
.block-link {
	display: block !important;
}

/* No Borders */
.no-borders {
	border: none !important;
}

/* Clearfix */
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

/* Container */
.container-xl {
	width: 100%;
	max-width: 1630px;
}

/* Sample Item */
.sample-item-container {
	max-width: 1648px;
}

.sample-item-list {
	list-style: none;
	padding: 0;
	margin: 0;
	opacity: 0;
}

.sample-item-list li {
	text-align: center;
}

.sample-item-list.sample-item-list-loaded {
	opacity: 1;
}

.sample-item {
	text-align: center;
	max-width: 500px;
	width: 100%;
	display: inline-block;
	margin-bottom: 35px;
}

.sample-item a {
	text-decoration: none !important;
}

.sample-item .owl-carousel {
	margin-bottom: 0;
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
	position: relative;
	height: 22.8vw;
	max-height: 365px;
	display: block !important;
	overflow: hidden;
	-webkit-transition: box-shadow 0.3s ease;
	-moz-transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
}

.sample-item .owl-carousel:hover {
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}

.sample-item .owl-carousel .sample-item-image-wrapper {
	box-shadow: none;
}

.sample-item .owl-carousel .owl-item > div {
	height: 365px;
	max-height: 365px;
}

.sample-item .owl-carousel .owl-nav .owl-prev,
.sample-item .owl-carousel .owl-nav .owl-next {
	border: 0 none;
	border-radius: 0 4px 4px 0;
	color: #777;
	height: 50px;
	line-height: 38px;
	margin-top: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sample-item .owl-carousel .owl-nav .owl-prev:hover, .sample-item .owl-carousel .owl-nav .owl-prev:focus,
.sample-item .owl-carousel .owl-nav .owl-next:hover,
.sample-item .owl-carousel .owl-nav .owl-next:focus {
	color: #000 !important;
}

.sample-item .owl-carousel .owl-nav .owl-prev:before,
.sample-item .owl-carousel .owl-nav .owl-next:before {
	font-size: 11px;
}

.sample-item .owl-carousel .owl-nav .owl-prev {
	background-color: #E7E7E7;
	-webkit-transform: translate3d(-30px, 0, 0);
	-moz-transform: translate3d(-30px, 0, 0);
	-ms-transform: translate3d(-30px, 0, 0);
	-o-transform: translate3d(-30px, 0, 0);
	transform: translate3d(-30px, 0, 0);
}

.sample-item .owl-carousel .owl-nav .owl-prev:hover, .sample-item .owl-carousel .owl-nav .owl-prev:focus {
	background-color: #E7E7E7 !important;
}

.sample-item .owl-carousel .owl-nav .owl-next {
	background-color: #E7E7E7;
	-webkit-transform: translate3d(30px, 0, 0);
	-moz-transform: translate3d(30px, 0, 0);
	-ms-transform: translate3d(30px, 0, 0);
	-o-transform: translate3d(30px, 0, 0);
	transform: translate3d(30px, 0, 0);
	border-radius: 4px 0 0 4px;
}

.sample-item .owl-carousel .owl-nav .owl-next:hover, .sample-item .owl-carousel .owl-nav .owl-next:focus {
	background-color: #E7E7E7 !important;
}

.sample-item .owl-carousel:hover .owl-nav .owl-prev {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.sample-item .owl-carousel:hover .owl-nav .owl-next {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.sample-item .sample-item-image-wrapper {
	background: #FFF;
	height: 22vw;
	max-height: 365px;
	max-width: 500px;
	width: 100%;
	display: inline-block;
	border: none;
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
	position: relative;
	-webkit-transition: box-shadow 0.3s ease;
	-moz-transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
}

.sample-item .sample-item-image-wrapper .fa-spin {
	background: transparent none repeat scroll 0 0;
	color: #ccc;
	font-size: 25px;
	left: 50%;
	margin: -13px;
	position: absolute;
	top: 50%;
	z-index: 1;
}

.sample-item a:hover .sample-item-image-wrapper {
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}

.sample-item .sample-item-image {
	height: 22.8vw;
	max-height: 365px;
	max-width: 500px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	z-index: 2;
}

.sample-item .sample-item-description {
	display: block;
	padding: 15px 0;
}

.sample-item h5 {
	padding: 0;
	margin: 0;
	font-size: 14px;
}

.sample-item h5 .sample-item-info {
	display: block;
	font-size: 11px;
	opacity: 0.6;
}

.sample-item p {
	padding: 0;
	margin: 0;
	font-size: 12px;
}

.sample-item.sample-item-home .sample-item-image-wrapper {
	height: 13.9vw;
}

.sample-item.sample-item-home .sample-item-image-wrapper,
.sample-item.sample-item-home .sample-item-image {
	max-height: 260px;
}

.sample-item.sample-item-coming-soon .sample-item-image {
	background-color: #FBFBFB;
	background-position: center center;
}

.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
	height: 15vw;
}

@media (max-width: 991px) {
	.sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
		height: 33vw;
	}

	.sample-item .sample-item-image {
		height: 34vw;
	}

	.sample-item.sample-item-home .sample-item-image-wrapper {
		height: 30vw;
	}

	.sample-item.sample-item-home .sample-item-image {
		height: 30vw;
	}

	.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
		height: 30vw;
	}
}

@media (max-width: 767px) {
	.sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
		height: 63.4vw;
	}

	.sample-item .sample-item-image {
		height: 67.5vw;
	}

	.sample-item.sample-item-home .sample-item-image-wrapper {
		height: 63.4vw;
		max-height: 345px;
	}

	.sample-item.sample-item-home .sample-item-image {
		height: 67.5vw;
		max-height: 345px;
	}

	.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
		height: 63.4vw;
	}
}
/* Sample Sub Items Lightbox */
.sample-sub-items-lightbox {
	margin: 0 auto;
	max-width: 1650px;
	padding: 10vh 10px 50px;
	width: 100%;
}

/* Magnific Popup Demo Options */
.mfp-demo-options.mfp-bg {
	opacity: 0.98 !important;
	background: #F4F4F4;
}

.mfp-demo-options.mfp-bg.mfp-removing {
	opacity: 0 !important;
}

.mfp-demo-options .mfp-close {
	position: fixed;
	top: 0;
	right: 17px;
	z-index: 3000;
}

.mfp-demo-options.mfp-wrap {
	height: 100vh !important;
}

.mfp-demo-options.mfp-wrap:before {
	background: -webkit-linear-gradient( top , #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	background: linear-gradient(to bottom, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 2000;
	opacity: 1;
	opacity: 0.7;
}

.mfp-demo-options.mfp-wrap:after {
	background: -webkit-linear-gradient( bottom , #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	background: linear-gradient(to top, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	content: '';
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 2000;
	opacity: 1;
	opacity: 0.7;
}

.mfp-demo-options.mfp-wrap.mfp-removing:before, .mfp-demo-options.mfp-wrap.mfp-removing:after {
	opacity: 0 !important;
}

/* Pagination */
.pagination {
	position: relative;
	z-index: 1;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #CCC;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #CCC;
	border-color: #CCC;
}

/* Read More */
.read-more,
.learn-more {
	display: inline-block;
	white-space: nowrap;
}

/* Offset Anchor */
.offset-anchor {
	display: block;
	position: relative;
	visibility: hidden;
	top: -100px;
}

/* Responsive */
@media (max-width: 991px) {
	.offset-anchor {
		top: 0;
	}
}
/* Show Grid */
.show-grid [class*="col-md-"] .show-grid-block {
	background-color: #EEE;
	line-height: 40px;
	min-height: 40px;
	text-align: center;
	border: 1px solid #FFF;
	display: block;
}

/* Alerts */
.alert .fa, .alert .icons {
	margin-right: 8px;
	font-size: 1.1em;
}

.alert ul {
	padding: 0;
	margin: 7px 0 0 40px;
}

.alert ul li {
	font-size: 0.9em;
}

.alert.alert-sm {
	padding: 5px 10px;
	font-size: 0.9em;
}

.alert.alert-lg {
	padding: 20px;
	font-size: 1.2em;
}

.alert.alert-default {
	background-color: #f2f2f2;
	border-color: #eaeaea;
	color: #737373;
}

.alert.alert-default .alert-link {
	color: #4c4c4c;
}

.alert.alert-dark {
	background-color: #333a44;
	border-color: #07080a;
	color: #ced3db;
}

.alert.alert-dark .alert-link {
	color: #fafbfb;
}

/* Embed Responsive */
.embed-responsive.embed-soundcloud {
	padding-bottom: 19.25%;
}

.embed-responsive.embed-responsive-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Content Grid */
.content-grid {
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.content-grid .content-grid-item {
	position: relative;
	padding: 30px;
	margin: 0;
}

.content-grid .content-grid-item:before, .content-grid .content-grid-item:after {
	content: '';
	position: absolute;
}

.content-grid .content-grid-item:before {
	height: 100%;
	top: 0;
	left: -1px;
	border-left: 1px solid #DADADA;
}

.content-grid .content-grid-item:after {
	width: 100%;
	height: 0;
	top: auto;
	left: 0;
	bottom: -1px;
	border-bottom: 1px solid #DADADA;
}

.content-grid .content-grid-item .img-responsive {
	display: inline-block;
}

.content-grid.content-grid-dashed .content-grid-item:before {
	border-left: 1px dashed #DADADA;
}

.content-grid.content-grid-dashed .content-grid-item:after {
	border-bottom: 1px dashed #DADADA;
}

/* Alert Admin */
.alert-admin {
	margin: 25px 0;
}

.alert-admin img {
	margin: -50px 0 0;
}

.alert-admin .btn {
	margin: 0 0 -15px;
}

.alert-admin p {
	color: #444;
}

.alert-admin h4 {
	color: #111;
	font-size: 1.2em;
	font-weight: 600;
	text-transform: uppercase;
}

.alert-admin .warning {
	color: #B20000;
}

/* Not Included */
.not-included {
	color: #b7b7b7;
	display: block;
	font-size: 0.8em;
	font-style: normal;
	margin: -4px 0;
	padding: 0;
}

/* Tip */
.tip {
	display: inline-block;
	padding: 0 5px;
	background: #FFF;
	color: #111;
	text-shadow: none;
	border-radius: 3px;
	margin-left: 8px;
	position: relative;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: bold;
}

.tip:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 5px;
	margin-top: -5px;
}

.tip.skin {
	color: #FFF;
}

/* Custom Underline */
.custom-underline {
	background: url(../img/custom-underline.png) no-repeat center 100%;
	padding-bottom: 25px;
	font-size: 1.5em;
}

/* Ajax Box */
.ajax-box {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	position: relative;
}

.ajax-box .ajax-box-content {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	opacity: 1;
	overflow: hidden;
	height: auto;
}

.ajax-box .bounce-loader {
	opacity: 0;
}

.ajax-box.ajax-box-init {
	height: 0;
}

.ajax-box.ajax-box-loading {
	height: 300px;
}

.ajax-box.ajax-box-loading .ajax-box-content {
	opacity: 0;
	height: 300px;
}

.ajax-box.ajax-box-loading .bounce-loader {
	opacity: 1;
}

/* Go to Demos */
.go-to-demos {
	-webkit-transition: padding 0.3s ease;
	-moz-transition: padding 0.3s ease;
	transition: padding 0.3s ease;
	background-color: #EEE;
	border-radius: 0 6px 6px 0;
	color: #888 !important;
	display: block;
	font-size: 10px;
	height: 40px;
	left: 0;
	line-height: 38px;
	padding: 0 15px 0 10px;
	position: fixed;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	top: 90px;
	width: auto;
	z-index: 999;
}

.go-to-demos .fa {
	-webkit-transition: left 0.3s ease;
	-moz-transition: left 0.3s ease;
	transition: left 0.3s ease;
	display: inline-block;
	padding-right: 5px;
	position: relative;
	left: 0;
}

.go-to-demos:hover {
	text-decoration: none;
	padding-left: 25px;
}

.go-to-demos:hover .fa {
	left: -7px;
}

@media (max-width: 991px) {
	.go-to-demos {
		display: none;
	}
}
/* Dividers */
hr {
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB, transparent);
	background-image: linear-gradient(to right, transparent, #DBDBDB, transparent);
	border: 0;
	height: 1px;
	margin: 22px 0;
}

hr.short {
	margin: 11px 0;
}

hr.tall {
	margin: 44px 0;
}

hr.taller {
	margin: 66px 0;
}

hr.gradient-to-right {
	background-image: -webkit-linear-gradient(left, #DBDBDB, transparent);
	background-image: linear-gradient(to right, #DBDBDB, transparent);
}

hr.gradient-to-left {
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB);
	background-image: linear-gradient(to right, transparent, #DBDBDB);
}

hr.solid {
	background: #DBDBDB;
}

hr.light {
	background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.15), transparent);
	background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.15), transparent);
}

hr.invisible {
	background: none;
}

.divider {
	border: 0;
	height: 1px;
	margin: 44px auto;
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB, transparent);
	background-image: linear-gradient(to right, transparent, #DBDBDB, transparent);
	text-align: center;
	position: relative;
	clear: both;
}

.divider.taller {
	margin: 66px auto;
}

.divider .fa, .divider .icons {
	text-align: center;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	background: #FFF;
	border-radius: 50px;
	color: #a9a9a9;
	display: inline-block;
	height: 50px;
	line-height: 50px;
	position: absolute;
	text-align: center;
	width: 50px;
	font-size: 20px;
	margin: 0 auto 0 -25px;
	top: -25px;
	left: 50%;
	z-index: 1;
}

.divider.divider-solid {
	background: #DBDBDB;
}

.divider.divider-icon-sm .fa, .divider.divider-icon-sm .icons {
	width: 30px;
	height: 30px;
	line-height: 30px;
	top: -15px;
	font-size: 15px;
	margin-left: -16px;
}

.divider.divider-icon-lg {
	margin: 86px 0;
}

.divider.divider-icon-lg .fa, .divider.divider-icon-lg .icons {
	width: 60px;
	height: 60px;
	line-height: 60px;
	top: -30px;
	font-size: 30px;
	margin-left: -30px;
}

.divider.divider-icon-lg.taller {
	margin: 100px auto;
}

.divider.divider-xs {
	width: 35%;
}

.divider.divider-sm {
	width: 67%;
}

.divider.divider-left .fa, .divider.divider-left .icons {
	left: 0;
	margin-left: 0;
	margin-right: 0;
	right: auto;
}

.divider.divider-right .fa, .divider.divider-right .icons {
	left: auto;
	margin-left: 0;
	margin-right: 0;
	right: 0;
}

.divider.divider-style-2 .fa, .divider.divider-style-2 .icons {
	background: #f4f4f4;
	color: #a9a9a9;
}

.divider.divider-style-3 .fa, .divider.divider-style-3 .icons {
	border: 1px solid #CECECE;
}

.divider.divider-style-4 .fa, .divider.divider-style-4 .icons {
	border: 1px solid #CECECE;
}

.divider.divider-style-4 .fa:after, .divider.divider-style-4 .icons:after {
	border: 3px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -4px;
	padding: 1px;
	position: absolute;
	top: -4px;
	width: 100%;
}

.divider.divider-small {
	margin: 22px auto;
	background: transparent;
}

.divider.divider-small hr {
	width: 50px;
	height: 3px;
	background: #555;
}

.divider.divider-small.divider-small-center {
	text-align: center;
}

.divider.divider-small.divider-small-center hr {
	margin-left: auto;
	margin-right: auto;
}

.divider.divider-small.divider-small-right {
	text-align: right;
}

.divider.divider-small.divider-small-right hr {
	margin-left: auto;
}

.divider.divider-small.divider-light hr {
	background: #DDD;
}

.divider.divider-small.divider-small-sm hr {
	height: 1px;
}

.divider.divider-small.divider-small-lg hr {
	height: 6px;
}

.divider.divider-small.tall {
	margin: 34px 0;
}

.divider.divider-small.taller {
	margin: 46px 0;
}

hr.dashed, .divider.dashed {
	background: none;
	position: relative;
}

hr.dashed:after, .divider.dashed:after {
	border-top: 1px dashed #DBDBDB;
	content: "";
	display: block;
	height: 0;
	position: absolute;
	top: 50%;
	margin-top: -1px;
	width: 100%;
}

hr.pattern, .divider.pattern {
	background: none;
	position: relative;
}

hr.pattern:after, .divider.pattern:after {
	background: transparent url(../img/patterns/worn_dots.png) repeat 0 0;
	content: "";
	display: block;
	height: 15px;
	position: absolute;
	top: 50%;
	margin-top: -7px;
	width: 100%;
}

hr.pattern.pattern-2:after, .divider.pattern.pattern-2:after {
	background-image: url(../img/patterns/az_subtle.png);
}

/* Sort Source Wrapper */
.sort-source-wrapper {
	padding: 8px 0;
	position: relative;
}

.sort-source-wrapper .nav {
	margin: -100px 0 5px;
}

.sort-source-wrapper .nav > li > a {
	color: #FFF;
	background: transparent;
}

.sort-source-wrapper .nav > li > a:hover, .sort-source-wrapper .nav > li > a:focus {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a:hover, .sort-source-wrapper .nav > li.active > a:focus {
	background: transparent;
}

/* Sort Source */
.sort-source {
	clear: both;
}

.sort-source > li > a {
	padding: 6px 12px;
}

.sort-source.sort-source-style-2 {
	margin: -10px auto;
}

.sort-source.sort-source-style-2 > li {
	float: none;
	display: inline-block;
}

.sort-source.sort-source-style-2 > li > a, .sort-source.sort-source-style-2 > li > a:focus, .sort-source.sort-source-style-2 > li > a:hover {
	background: transparent;
	color: #FFF;
	display: inline-block;
	border-radius: 0;
	margin: 0;
	padding: 18px 28px;
	position: relative;
}

.sort-source.sort-source-style-2 > li > a:after {
	-webkit-transition: margin 0.3s;
	-moz-transition: margin 0.3s;
	transition: margin 0.3s;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 15px solid #CCC;
	content: " ";
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	margin-top: 0;
	opacity: 0;
}

.sort-source.sort-source-style-2 > li.active > a, .sort-source.sort-source-style-2 > li.active > a:focus, .sort-source.sort-source-style-2 > li.active > a:hover {
	background: transparent;
}

.sort-source.sort-source-style-2 > li.active > a:after {
	opacity: 1;
	margin-top: 18px;
}

@media (max-width: 991px) {
	.sort-source.sort-source-style-2 > li {
		display: block;
	}

	.sort-source.sort-source-style-2 > li.active > a:after {
		display: none !important;
	}
}
/* Sort Destination */
.sort-destination {
	overflow: hidden;
}

.sort-destination.full-width {
	position: relative;
}

.sort-destination.full-width .isotope-item {
	width: 20%;
	float: left;
}

.sort-destination.full-width .isotope-item.w2 {
	width: 40%;
}

.sort-destination.full-width .isotope-item .img-thumbnail, .sort-destination.full-width .isotope-item a, .sort-destination.full-width .isotope-item .thumb-info img {
	border-radius: 0;
}

.sort-destination.full-width-spaced {
	margin-top: 50px;
	margin-left: 20px;
	margin-right: 20px;
}

.sort-destination.full-width-spaced .portfolio-item {
	margin-left: 20px;
	margin-right: 20px;
}

.sort-source-wrapper + .sort-destination.full-width {
	margin: 0 0 -81px;
	top: -51px;
}

.sort-source-wrapper + .sort-destination-loader .full-width {
	margin: 0 0 -81px;
	top: -51px;
}

/* Responsive */
@media (min-width: 992px) {
	.col-md-6.isotope-item:nth-child(2n+1) {
		clear: both;
	}

	.col-md-4.isotope-item:nth-child(3n+1) {
		clear: both;
	}

	.col-md-3.isotope-item:nth-child(4n+1) {
		clear: both;
	}

	.col-md-1-5.isotope-item:nth-child(5n+1) {
		clear: both;
	}

	.col-md-2.isotope-item:nth-child(6n+1) {
		clear: both;
	}

	.sort-destination.full-width .isotope-item:nth-child(5n+1) {
		clear: both;
	}
}

@media (max-width: 991px) {
	.isotope-item {
		max-width: 100%;
	}

	.sort-destination.isotope {
		overflow: visible !important;
		height: auto !important;
	}

	.sort-destination.isotope.full-width {
		overflow: hidden !important;
	}

	.isotope-hidden {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.isotope-item {
		position: static !important;
		text-align: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
	}

	.isotope-item.product {
		position: relative !important;
	}

	.sort-source-wrapper {
		background: #171717;
		clear: both;
		margin-top: -35px;
	}

	.sort-source-wrapper .nav {
		margin: 0;
	}

	.sort-destination {
		height: auto !important;
	}

	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width,
	.sort-source-wrapper + .sort-destination.full-width {
		max-width: none;
		margin: 0 0 -30px;
		top: 0;
	}

	.sort-destination.full-width .isotope-item,
	.sort-source-wrapper + .sort-destination.full-width .isotope-item {
		max-width: none;
	}
}

@media (max-width: 479px) {
	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width .isotope-item,
	.sort-source-wrapper + .sort-destination.full-width .isotope-item {
		position: static !important;
		clear: both;
		float: none;
		width: auto;
	}
}
/* Sort Loading */
.sort-destination-loader {
	height: auto;
	overflow: hidden;
	position: relative;
}

.sort-destination-loader:after {
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
	content: ' ';
	background: #FFF;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}

.sort-destination-loader .bounce-loader {
	display: none;
}

.sort-destination-loader.sort-destination-loader-showing {
	max-height: 200px;
}

.sort-destination-loader.sort-destination-loader-showing:after {
	opacity: 1;
}

.sort-destination-loader.sort-destination-loader-showing .bounce-loader {
	display: block;
}

.sort-destination-loader.sort-destination-loader-loaded {
	overflow: visible;
}

.sort-destination-loader.sort-destination-loader-loaded:after {
	display: none;
}

.sort-destination-loader-full-width {
	overflow: visible;
}

.sort-destination-loader-full-width:after {
	bottom: -81px;
	top: -51px;
}

.sort-destination-loader-full-width.sort-destination-loader-showing {
	overflow: hidden;
	height: 600px;
}

.sort-destination-loader-lg.sort-destination-loader-showing {
	height: 600px;
}

/* Pricing Tables */
.pricing-table {
	margin: 10px 0;
	padding: 0 15px;
	text-align: center;
}

.pricing-table ul {
	list-style: none;
	margin: 20px 0 0 0;
	padding: 0;
}

.pricing-table li {
	border-top: 1px solid #ddd;
	padding: 10px 0;
	color: #777;
}

.pricing-table h3 {
	background-color: #eee;
	border-radius: 2px 2px 0 0;
	font-size: 1.3em;
	font-weight: normal;
	margin: -20px -20px 50px -20px;
	padding: 20px;
}

.pricing-table h3 .desc {
	font-size: 0.7em;
	margin-bottom: 0;
	padding-bottom: 0;
	color: #777;
	font-style: normal;
	text-transform: none;
	display: block;
}

.pricing-table h3 span {
	background: #FFF;
	border: 5px solid #FFF;
	border-radius: 100px;
	box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
	color: #777;
	display: block;
	font: bold 25px / 100px Georgia, Serif;
	height: 100px;
	margin: 20px auto -65px;
	width: 100px;
}

.pricing-table .most-popular {
	border: 3px solid #CCC;
	box-shadow: 11px 0 10px -10px rgba(0, 0, 0, 0.1), -11px 0 10px -10px rgba(0, 0, 0, 0.1);
	padding: 30px 20px;
	top: -10px;
	z-index: 2;
}

.pricing-table .most-popular h3 {
	background-color: #666;
	color: #FFF;
	padding-top: 30px;
	text-shadow: 0 1px #555;
}

.pricing-table .most-popular h3 .desc {
	color: #FFF;
}

.pricing-table .plan-ribbon-wrapper {
	height: 88px;
	overflow: hidden;
	position: absolute;
	right: -3px;
	top: -5px;
	width: 85px;
}

.pricing-table .plan-ribbon {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background-image: -webkit-linear-gradient(top, #bfdc7a, #8ebf45);
	background-image: linear-gradient(to bottom, #bfdc7a, #8ebf45);
	background-color: #bfdc7a;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	color: #333;
	font-size: 14px;
	left: -5px;
	padding: 7px 0;
	position: relative;
	text-align: center;
	text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
	top: 15px;
	width: 120px;
}

.pricing-table .plan-ribbon:before {
	left: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
}

.pricing-table .plan-ribbon:after {
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
	right: 0;
}

.pricing-table [class*="col-md-"] {
	padding-left: 0;
	padding-right: 0;
}

.pricing-table .plan {
	background: #FFF;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #333;
	margin-bottom: 35px;
	margin-right: 0;
	padding: 20px;
	position: relative;
	text-shadow: 0 1px rgba(255, 255, 255, 0.8);
}

.pricing-table .btn {
	margin-top: 5px;
}

.pricing-table.pricing-table-sm h3 .desc {
	font-size: 0.7em;
}

.pricing-table.pricing-table-sm h3 span {
	border: 3px solid #FFF;
	box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
	font: bold 19px/65px Georgia,serif;
	height: 70px;
	margin: 10px auto -65px;
	width: 70px;
}

.pricing-table.pricing-table-sm .most-popular {
	padding-bottom: 30px;
}

.pricing-table.princig-table-flat .plan {
	padding-left: 0;
	padding-right: 0;
	margin: -1px;
}

.pricing-table.princig-table-flat .plan h3 {
	padding-left: 0;
	padding-right: 0;
	margin: -20px 0 0;
	background-color: #666;
	color: #FFF;
	padding-top: 30px;
	text-shadow: 0 1px #555;
}

.pricing-table.princig-table-flat .plan h3 .desc {
	color: #FFF;
}

.pricing-table.princig-table-flat .plan h3 span {
	border-radius: 0;
	border: 0;
	background: #666;
	color: #FFF;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 600;
	box-shadow: none;
	width: 100%;
	font-size: 1.5em;
	height: auto;
	line-height: 38px;
	margin: 0;
}

.pricing-table.princig-table-flat .plan ul {
	margin-top: 0;
	margin-bottom: -15px;
}

.pricing-table.princig-table-flat .plan ul li {
	padding: 8px 0;
}

.pricing-table.princig-table-flat .plan.most-popular ul li {
	padding: 10px 0;
}

.pricing-table.spaced [class*="col-md-"] {
	padding-left: 2px;
	padding-right: 2px;
}

.pricing-table.no-borders .plan {
	border: 0 !important;
}

/* Pricing Tables Boxed */
html.boxed .pricing-table .plan, html.boxed .pricing-table h3 {
	border-radius: 0;
}

/* Responsive */
@media (max-width: 767px) {
	/* Pricing Tables */
	.pricing-table {
		margin-left: 0;
	}

	.pricing-table .plan {
		margin-right: 0;
	}

	html.boxed .pricing-table {
		margin-left: 0;
	}
}
/* Icon Featured */
.icon-featured {
	display: inline-block;
	font-size: 0;
	margin: 15px;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	z-index: 1;
	color: #FFF;
	font-size: 40px;
	line-height: 110px;
}

.icon-featured:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

/* Featured Box */
.featured-box {
	background: #F5F5F5;
	background: -webkit-linear-gradient( top , #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-radius: 8px;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	min-height: 100px;
	position: relative;
	text-align: center;
	z-index: 1;
}

.featured-box h4 {
	font-size: 1.3em;
	font-weight: 400;
	letter-spacing: -0.7px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.featured-box .box-content {
	border-radius: 8px;
	border-top: 1px solid #CCC;
	border-top-width: 4px;
	padding: 30px 30px 10px 30px;
	position: relative;
	top: -1px;
}

.featured-box .learn-more {
	display: block;
	margin-top: 10px;
}

/* Featured Box Left */
.featured-box-text-left {
	text-align: left;
}

/* Featured Boxes - Flat */
.featured-boxes-flat .featured-box {
	box-shadow: none;
	margin: 10px 0;
}

.featured-boxes-flat .featured-box .box-content {
	background: #FFF;
	margin-top: 65px;
}

.featured-boxes-flat .featured-box .icon-featured {
	display: inline-block;
	font-size: 38px;
	height: 90px;
	line-height: 90px;
	padding: 0;
	width: 90px;
	margin: -100px 0 -15px;
	position: relative;
	top: -37px;
}

/* Featured Boxes - Style 2 */
.featured-boxes-style-2 .featured-box {
	background: none;
	border: 0;
	margin: 10px 0;
	box-shadow: none;
}

.featured-boxes-style-2 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-2 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	line-height: 75px;
	padding: 0;
	width: 75px;
	margin-top: 0;
}

/* Featured Boxes - Style 3 */
.featured-boxes-style-3 .featured-box {
	margin: 10px 0;
}

.featured-boxes-style-3 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	line-height: 75px;
	padding: 0;
	width: 75px;
	margin-top: -35px;
	background: #FFF;
	border: 3px solid #CCC;
	color: #CCC;
	line-height: 68px;
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box {
	background: none;
	border: 0;
	box-shadow: none;
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

/* Featured Boxes - Style 4 */
.featured-boxes-style-4 .featured-box {
	background: none;
	border: 0;
	margin: 10px 0;
	box-shadow: none;
}

.featured-boxes-style-4 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-4 .featured-box .icon-featured {
	display: inline-block;
	font-size: 40px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	width: 45px;
	margin-top: 0;
	margin-bottom: 10px;
	background: transparent;
	color: #CCC;
	border-radius: 0;
}

/* Featured Boxes - Style 5, 6 and 7 */
.featured-boxes-style-5 .featured-box, .featured-boxes-style-6 .featured-box, .featured-boxes-style-7 .featured-box {
	background: none;
	border: 0;
	box-shadow: none;
	margin: 10px 0;
}

.featured-boxes-style-5 .featured-box .box-content, .featured-boxes-style-6 .featured-box .box-content, .featured-boxes-style-7 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-5 .featured-box .box-content h4, .featured-boxes-style-6 .featured-box .box-content h4, .featured-boxes-style-7 .featured-box .box-content h4 {
	color: #2e353e;
}

.featured-boxes-style-5 .featured-box .icon-featured, .featured-boxes-style-6 .featured-box .icon-featured, .featured-boxes-style-7 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	padding: 0;
	width: 75px;
	margin-top: 0;
	margin-bottom: 10px;
	background: #FFF;
	border: 1px solid #dfdfdf;
	color: #777;
	line-height: 73px;
}

/* Featured Boxes - Style 6 */
.featured-boxes-style-6 .featured-box .icon-featured {
	border: 1px solid #cecece;
	color: #777;
}

.featured-boxes-style-6 .featured-box .icon-featured:after {
	border: 5px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -6px;
	padding: 1px;
	position: absolute;
	top: -6px;
	width: 100%;
}

/* Featured Boxes - Style 7 */
.featured-boxes-style-7 .featured-box .icon-featured {
	border: 1px solid #dfdfdf;
	color: #777;
}

/* Featured Boxes - Style 8 */
.featured-boxes-style-8 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	padding: 0;
	width: 75px;
	margin: -15px -15px 0 0;
	background: #FFF;
	line-height: 73px;
	border: 0;
	color: #777;
}

.featured-boxes-style-8 .featured-box .icon-featured:after {
	display: none;
}

/* Featured Boxes */
.featured-boxes .featured-box {
	margin-bottom: 30px;
	margin-top: 45px;
}

/* Effects */
.featured-box-effect-1 .icon-featured:after {
	top: -7px;
	left: -7px;
	padding: 7px;
	box-shadow: 0 0 0 3px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(1);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(1);
	opacity: 0;
}

.featured-box-effect-1:hover .icon-featured:after {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 1;
}

.featured-box-effect-2 .icon-featured:after {
	top: -7px;
	left: -7px;
	padding: 7px;
	box-shadow: 0 0 0 3px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(0.8);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(0.8);
	opacity: 0;
}

.featured-box-effect-2:hover .icon-featured:after {
	-webkit-transform: scale(0.95);
	-moz-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
	opacity: 1;
}

.featured-box-effect-3 .icon-featured:after {
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(0.9);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(0.9);
	opacity: 0;
}

.featured-box-effect-3:hover .icon-featured {
	color: #FFF !important;
}

.featured-box-effect-3:hover .icon-featured:after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 0.8;
}

.featured-box-effect-4 .icon-featured {
	-webkit-transition: -webkit-transform 0.2s, transform 0.2s;
	-moz-transition: -moz-transform 0.2s, transform 0.2s;
	transition: transform 0.2s, transform 0.2s;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.featured-box-effect-4:hover .icon-featured {
	-webkit-transform: scale(1.15);
	-moz-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
}

.featured-box-effect-5 .icon-featured {
	overflow: hidden;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.featured-box-effect-5:hover .icon-featured {
	-webkit-animation: toRightFromLeft 0.3s forwards;
	-moz-animation: toRightFromLeft 0.3s forwards;
	animation: toRightFromLeft 0.3s forwards;
}

@-webkit-keyframes toRightFromLeft {
	49% {
		-webkit-transform: translate(100%);
	}

	50% {
		opacity: 0;
		-webkit-transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

@-moz-keyframes toRightFromLeft {
	49% {
		-moz-transform: translate(100%);
	}

	50% {
		opacity: 0;
		-moz-transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

@keyframes toRightFromLeft {
	49% {
		transform: translate(100%);
	}

	50% {
		opacity: 0;
		transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

.featured-box-effect-6 .icon-featured:after {
	-webkit-transition: all 0.2s, -webkit-transform 0.2s;
	-moz-transition: all 0.2s, -moz-transform 0.2s;
	transition: all 0.2s, transform 0.2s;
}

.featured-box-effect-6:hover .box-content .icon-featured:after {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.featured-box-effect-7 .icon-featured:after {
	opacity: 0;
	box-shadow: 3px 3px #d5d5d5;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	-moz-transition: opacity 0.2s, -moz-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
	left: 0;
	top: -1px;
}

.featured-box-effect-7:hover .icon-featured:after {
	opacity: 1;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.featured-box-effect-7 .icon-featured:before {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0.7;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	transition: transform 0.2s, opacity 0.2s;
}

.featured-box-effect-7:hover .icon-featured:before {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Feature Box */
.feature-box {
	clear: both;
}

.feature-box .feature-box-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	float: left;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.feature-box .feature-box-info {
	padding-left: 50px;
}

/* Style 2 */
.feature-box.feature-box-style-2 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-2 .feature-box-icon {
	background: transparent;
	width: 50px;
	height: 50px;
}

.feature-box.feature-box-style-2 .feature-box-icon i.fa,
.feature-box.feature-box-style-2 .feature-box-icon i.icons {
	font-size: 28px;
}

.feature-box.feature-box-style-2 .feature-box-info {
	padding-left: 60px;
}

/* Style 3 */
.feature-box.feature-box-style-3 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-3 .feature-box-icon {
	border: 1px solid #CCC;
	background: transparent;
	line-height: 32px;
}

.feature-box.feature-box-style-3 .feature-box-icon i.fa,
.feature-box.feature-box-style-3 .feature-box-icon i.icons {
	color: #CCC;
}

/* Style 4 */
.feature-box.feature-box-style-4 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-4 .feature-box-icon {
	background: transparent;
	padding-bottom: 10px;
	height: auto;
	width: auto;
	float: none;
}

.feature-box.feature-box-style-4 .feature-box-icon i.fa,
.feature-box.feature-box-style-4 .feature-box-icon i.icons {
	font-size: 38px;
}

.feature-box.feature-box-style-4 .feature-box-info {
	clear: both;
	padding-left: 0;
}

/* Style 5 */
.feature-box.feature-box-style-5 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-5 .feature-box-icon {
	background: transparent;
	width: 74px;
	height: 74px;
}

.feature-box.feature-box-style-5 .feature-box-icon i.fa,
.feature-box.feature-box-style-5 .feature-box-icon i.icons {
	font-size: 44px;
}

.feature-box.feature-box-style-5 .feature-box-info {
	padding-left: 84px;
}

/* Style 6 */
.feature-box.feature-box-style-6 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-6 .feature-box-icon {
	border: 1px solid #cecece;
	background: transparent;
	line-height: 32px;
}

.feature-box.feature-box-style-6 .feature-box-icon:after {
	border: 3px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -4px;
	padding: 1px;
	position: absolute;
	top: -4px;
	width: 100%;
}

.feature-box.feature-box-style-6 .feature-box-icon i.fa,
.feature-box.feature-box-style-6 .feature-box-icon i.icons {
	color: #a9a9a9;
}

/* Reverse */
@media (min-width: 992px) {
	.feature-box.reverse {
		text-align: right;
	}

	.feature-box.reverse .feature-box-info {
		padding-right: 50px;
		padding-left: 0;
	}

	.feature-box.reverse .feature-box-icon {
		float: right;
		margin-right: 0;
		margin-left: 10px;
	}

	.feature-box.reverse.feature-box-style-2 .feature-box-info {
		padding-right: 60px;
	}

	.feature-box.reverse.feature-box-style-4 .feature-box-info {
		padding-right: 0;
	}

	.feature-box.reverse.feature-box-style-5 .feature-box-info {
		padding-right: 84px;
	}
}
/* Full Width */
.featured-boxes-full {
	width: 100%;
}

.featured-boxes-full > [class*="col-"] {
	padding: 0;
}

.featured-boxes-full .featured-box-full {
	text-align: center;
	padding: 55px;
}

.featured-boxes-full .featured-box-full .fa, .featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full h1, .featured-boxes-full .featured-box-full h2, .featured-boxes-full .featured-box-full h3, .featured-boxes-full .featured-box-full h4, .featured-boxes-full .featured-box-full h5, .featured-boxes-full .featured-box-full h6, .featured-boxes-full .featured-box-full p, .featured-boxes-full .featured-box-full a {
	color: #FFF;
	padding: 0;
	margin: 0;
}

.featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full .fa {
	font-size: 55px;
	margin-bottom: 15px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.featured-boxes-full .featured-box-full p {
	padding-top: 12px;
	opacity: 0.8;
}

/* Thumb Info */
.thumb-info {
	display: block;
	position: relative;
	text-decoration: none;
	max-width: 100%;
	background-color: #FFF;
	border: 1px solid #DDD;
	border-radius: 4px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.thumb-info .thumb-info-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	border-radius: 4px;
	margin: 4px;
	overflow: hidden;
	display: block;
	position: relative;
}

.thumb-info .thumb-info-wrapper:after {
	content: "";
	background: rgba(23, 23, 23, 0.8);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 4px;
	bottom: -4px;
	color: #FFF;
	left: -4px;
	position: absolute;
	right: -4px;
	top: -4px;
	display: block;
	opacity: 0;
	z-index: 1;
}

.thumb-info .thumb-info-action-icon {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: #CCC;
	border-radius: 0 0 0 25px;
	display: inline-block;
	font-size: 25px;
	height: 50px;
	line-height: 50px;
	position: absolute;
	right: -100px;
	text-align: center;
	top: -100px;
	width: 50px;
	z-index: 2;
}

.thumb-info .thumb-info-action-icon i {
	font-size: 24px;
	left: 3px;
	position: relative;
	top: -4px;
	color: #FFF;
}

.thumb-info .thumb-info-inner {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	display: block;
}

.thumb-info .thumb-info-inner em {
	display: block;
	font-size: 0.8em;
	font-style: normal;
	font-weight: normal;
}

.thumb-info .thumb-info-title {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: rgba(23, 23, 23, 0.8);
	bottom: 10%;
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
	left: 0;
	letter-spacing: -1px;
	padding: 9px 11px 9px;
	position: absolute;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	z-index: 2;
	max-width: 90%;
}

.thumb-info .thumb-info-type {
	background-color: #CCC;
	border-radius: 2px;
	display: inline-block;
	float: left;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0;
	margin: 8px -2px -15px -2px;
	padding: 2px 9px;
	text-transform: none;
	z-index: 2;
}

.thumb-info .owl-carousel {
	z-index: auto;
}

.thumb-info .owl-carousel .owl-dots {
	z-index: 2;
}

.thumb-info img {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	border-radius: 3px;
	position: relative;
	width: 100%;
}

.thumb-info:hover .thumb-info-wrapper:after {
	opacity: 1;
}

.thumb-info:hover .thumb-info-action-icon {
	right: 0;
	top: 0;
}

.thumb-info:hover .thumb-info-title {
	background: #000;
}

.thumb-info:hover img {
	-webkit-transform: scale(1.1, 1.1);
	-moz-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

.thumb-info.thumb-info-no-zoom img {
	-webkit-transition: initial;
	-moz-transition: initial;
	transition: initial;
}

.thumb-info.thumb-info-no-zoom:hover img {
	-webkit-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}

.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
	background: rgba(23, 23, 23, 0.25);
}

.thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
	display: none;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action {
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate3d(0, -10px, 0);
	top: 50%;
	left: 3px;
	right: auto;
	bottom: auto;
	width: 100%;
	height: 40px;
	margin-top: -20px;
	text-align: center;
	position: absolute;
	opacity: 0;
	z-index: 2;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	transition: all 0.1s ease;
	position: relative;
	z-index: 2;
	left: auto;
	right: auto;
	top: auto;
	bottom: auto;
	width: 40px;
	height: 40px;
	line-height: 33px;
	border-radius: 50%;
	margin-right: 3px;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
	position: static;
	font-size: 18px;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
	-webkit-transform: scale(1.15, 1.15);
	-moz-transform: scale(1.15, 1.15);
	-ms-transform: scale(1.15, 1.15);
	-o-transform: scale(1.15, 1.15);
	transform: scale(1.15, 1.15);
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
	position: relative;
}

.thumb-info.thumb-info-centered-info .thumb-info-title {
	background: transparent;
	left: 5%;
	width: 90%;
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate(0%, -30%);
	top: 50%;
	bottom: auto;
	opacity: 0;
	text-align: center;
	padding: 0;
	position: absolute;
}

.thumb-info.thumb-info-centered-info .thumb-info-type {
	float: none;
}

.thumb-info.thumb-info-centered-info:hover .thumb-info-title {
	opacity: 1;
	transform: translate(0%, -50%);
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
	background: #FFF;
	left: 0;
	width: 100%;
	max-width: 100%;
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate3d(0, 100px, 0);
	top: auto;
	bottom: 0;
	opacity: 0;
	padding: 15px;
	text-shadow: none;
	color: #777;
}

.thumb-info.thumb-info-bottom-info .thumb-info-type {
	float: none;
	background: none;
	padding: 0;
	margin: 0;
}

.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.thumb-info.thumb-info-bottom-info:hover img {
	-webkit-transform: translateY(-18px);
	-moz-transform: translateY(-18px);
	-ms-transform: translateY(-18px);
	-o-transform: translateY(-18px);
	transform: translateY(-18px);
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
	background: -webkit-linear-gradient( bottom , rgba(23, 23, 23, 0.8) 0, transparent 100%) repeat scroll 0 0 transparent;
	background: linear-gradient(to top, rgba(23, 23, 23, 0.8) 0, transparent 100%) repeat scroll 0 0 transparent;
	color: #FFF;
}

.thumb-info.thumb-info-push-hor img {
	-webkit-transform: translatex(-18px);
	-moz-transform: translatex(-18px);
	-ms-transform: translatex(-18px);
	-o-transform: translatex(-18px);
	transform: translatex(-18px);
	width: calc(100% + 19px);
	max-width: none;
}

.thumb-info.thumb-info-push-hor:hover img {
	-webkit-transform: translatex(0);
	-moz-transform: translatex(0);
	-ms-transform: translatex(0);
	-o-transform: translatex(0);
	transform: translatex(0);
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
	opacity: 0.65;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-title {
	opacity: 1;
	top: 50%;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-type {
	float: none;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
	opacity: 0.1;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
	opacity: 0;
}

.thumb-info.thumb-info-no-borders, .thumb-info.thumb-info-no-borders img {
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
	border-radius: 0;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

.thumb-info.thumb-info-preview .thumb-info-wrapper:after {
	display: none;
}

.thumb-info.thumb-info-preview .thumb-info-image {
	min-height: 232px;
	display: block;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	position: relative;
	transition: background-position 0.8s linear 0s;
}

.thumb-info.thumb-info-preview:hover .thumb-info-image {
	transition: background-position 2.5s linear 0s;
	background-position: center bottom;
}

.thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
	transition: background-position 1s linear 0s;
}

.thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
	transition: background-position 5s linear 0s;
}

html.webkit .thumb-info .thumb-info-wrapper {
	margin: 4px 4px 3px 4px;
}

html.webkit .full-width .thumb-info .thumb-info-wrapper,
html.webkit .masonry-item .thumb-info .thumb-info-wrapper,
html.webkit .thumb-info-no-borders .thumb-info-wrapper {
	margin: 0;
}

/* Thumb Info Ribbon */
.thumb-info-ribbon {
	background: #999;
	position: absolute;
	margin: -16px 0 0 0;
	padding: 5px 13px 6px;
	right: 15px;
	z-index: 1;
}

.thumb-info-ribbon:before {
	border-right: 10px solid #646464;
	border-top: 16px solid transparent;
	content: "";
	display: block;
	height: 0;
	left: -10px;
	position: absolute;
	top: 0;
	width: 7px;
}

.thumb-info-ribbon span {
	color: #FFF;
	font-size: 1.1em;
	font-family: "Shadows Into Light", cursive;
}

/* Thumb Info - Full Width */
.full-width .thumb-info img {
	border-radius: 0 !important;
}

/* Thumb Info Caption	*/
.thumb-info-caption {
	padding: 10px 0;
}

.thumb-info-caption .thumb-info-caption-text, .thumb-info-caption p {
	font-size: 0.9em;
	line-height: 20px;
	margin: 0 0 8px;
	padding: 10px;
	display: block;
}

/* Thumb Info Side Image	*/
.thumb-info-side-image .thumb-info-side-image-wrapper {
	padding: 4px;
	float: left;
	margin-right: 20px;
}

.thumb-info-side-image .thumb-info-caption .thumb-info-caption-text {
	padding: 17px;
}

.thumb-info-side-image-right .thumb-info-side-image-wrapper {
	float: right;
	margin-left: 20px;
	margin-right: 0;
}

/* Thumb Info Social Icons */
.thumb-info-social-icons {
	border-top: 1px dotted #DDD;
	margin: 0;
	padding: 15px 10px;
	display: block;
}

.thumb-info-social-icons a {
	background: #CCC;
	border-radius: 25px;
	display: inline-block;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.thumb-info-social-icons a:hover {
	text-decoration: none;
}

.thumb-info-social-icons a span {
	display: none;
}

.thumb-info-social-icons a i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Responsive */
@media (max-width: 991px) {
	.thumb-info .thumb-info-title {
		font-size: 14px;
	}

	.thumb-info .thumb-info-more {
		font-size: 11px;
	}
}
/* Timeline */
section.timeline {
	width: 75%;
	margin: 50px 12.5% 0;
	position: relative;
	float: left;
	padding-bottom: 120px;
}

section.timeline:after {
	background: #505050;
	background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
	background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	content: "";
	display: block;
	height: 100%;
	left: 50%;
	margin-left: -2px;
	position: absolute;
	top: -60px;
	width: 3px;
	z-index: 0;
	filter: alpha(opacity=35);
	opacity: 0.35;
}

section.timeline .timeline-date {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	position: relative;
	display: block;
	clear: both;
	width: 200px;
	height: 45px;
	padding: 5px;
	border: 1px solid #E5E5E5;
	margin: 0 auto 10px;
	text-shadow: 0 1px 1px #fff;
	border-radius: 4px;
	background: #FFF;
	z-index: 1;
}

section.timeline .timeline-date h3 {
	display: block;
	text-align: center;
	color: #757575;
	font-size: 0.9em;
	line-height: 32px;
}

section.timeline .timeline-title {
	background: #F4F4F4;
	padding: 12px;
}

section.timeline .timeline-title h4 {
	padding: 0;
	margin: 0;
	color: #171717;
	font-size: 1.4em;
}

section.timeline .timeline-title a {
	color: #171717;
}

section.timeline .timeline-title .timeline-title-tags {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-title .timeline-title-tags a {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-box {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	margin: 20px 10px 10px 10px;
	padding: 10px;
	position: relative;
	width: 45.7%;
	float: left;
	z-index: 1;
	margin-bottom: 30px;
	background: #FFF;
}

section.timeline .timeline-box.left {
	clear: both;
}

section.timeline .timeline-box.left:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-right: -5px;
	position: absolute;
	right: -6.8%;
	top: 22px;
	width: 8px;
}

section.timeline .timeline-box.left:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-right: 1px solid #E5E5E5;
	border-top: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	right: -8px;
	top: 20px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box.right {
	clear: right;
	float: right;
	right: -1px;
	margin-top: 40px;
}

section.timeline .timeline-box.right:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-left: -5px;
	position: absolute;
	left: -7.2%;
	top: 32px;
	width: 8px;
}

section.timeline .timeline-box.right:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-left: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	left: -8px;
	top: 30px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box .owl-carousel {
	border-radius: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
}

/* Responsive */
@media (max-width: 991px) {
	section.timeline .timeline-box {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.right {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.left:after, section.timeline .timeline-box.right:after, section.timeline .timeline-box.left:before, section.timeline .timeline-box.right:before {
		display: none;
	}
}

@media (max-width: 479px) {
	section.timeline {
		width: auto;
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

	section.timeline .timeline-box {
		margin: 15px auto;
		max-width: 90%;
	}

	section.timeline .timeline-box.right {
		margin: 15px auto;
		max-width: 90%;
	}

	article.post-large {
		margin-left: 0;
	}

	article.post-large .post-image, article.post-large .post-date {
		margin-left: 0;
	}
}
/* Testimonials */
.testimonial {
	margin-bottom: 20px;
}

.testimonial blockquote {
	background: #CCC;
	border-radius: 10px;
	border: 0;
	color: #666;
	font-family: Georgia, serif;
	font-style: italic;
	margin: 0;
	padding: 10px 50px;
	position: relative;
}

.testimonial blockquote:before {
	left: 10px;
	top: 0;
	color: #FFF;
	content: "“";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

.testimonial blockquote:after {
	color: #FFF;
	content: "”";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	right: 10px;
}

.testimonial blockquote p {
	color: #FFF;
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
	line-height: 1.3;
}

.testimonial .testimonial-arrow-down {
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #CCC;
	height: 0;
	margin: 0 0 0 17px;
	width: 0;
}

.testimonial .testimonial-author {
	margin: 8px 0 0 0;
}

.testimonial .testimonial-author::after {
	clear: both;
	content: "";
	display: table;
}

.testimonial .testimonial-author .testimonial-author-thumbnail {
	float: left;
	margin-right: 10px;
	width: auto;
}

.testimonial .testimonial-author img {
	max-width: 55px;
}

.testimonial .testimonial-author strong {
	color: #111;
	display: block;
	padding-top: 10px;
	margin-bottom: -5px;
}

.testimonial .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial .testimonial-author p {
	color: #999;
	margin: 0 0 0 25px;
	text-align: left;
}

/* Style 2 */
.testimonial.testimonial-style-2 {
	text-align: center;
}

.testimonial.testimonial-style-2 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 6px 20px;
}

.testimonial.testimonial-style-2 blockquote:before, .testimonial.testimonial-style-2 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-2 blockquote p {
	color: #777;
}

.testimonial.testimonial-style-2 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-2 .testimonial-author {
	padding: 0;
	margin: 6px 0 0;
}

.testimonial.testimonial-style-2 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-2 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* Style 3 */
.testimonial.testimonial-style-3 blockquote {
	background: #f2f2f2 !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-3 blockquote:before {
	top: 5px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-3 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-3 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-3 .testimonial-author {
	margin-left: 8px;
}

.testimonial.testimonial-style-3 .testimonial-arrow-down {
	margin-left: 20px;
	border-top: 10px solid #f2f2f2 !important;
}

/* Style 4 */
.testimonial.testimonial-style-4 {
	border-radius: 6px;
	padding: 8px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.testimonial.testimonial-style-4 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-4 blockquote:before {
	top: 5px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-4 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-4 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-4 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-4 .testimonial-author {
	margin-left: 30px;
	margin-bottom: 15px;
}

.testimonial.testimonial-style-4.testimonial-no-borders {
	border: 0;
	box-shadow: none;
}

/* Style 5 */
.testimonial.testimonial-style-5 {
	text-align: center;
}

.testimonial.testimonial-style-5 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 6px 20px;
}

.testimonial.testimonial-style-5 blockquote:before, .testimonial.testimonial-style-5 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-5 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1.2em;
	font-style: normal;
}

.testimonial.testimonial-style-5 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-5 .testimonial-author {
	padding: 6px 0 0;
	margin: 12px 0 0;
	border-top: 1px solid #f2f2f2;
}

.testimonial.testimonial-style-5 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-5 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* Style 6 */
.testimonial.testimonial-style-6 {
	text-align: center;
}

.testimonial.testimonial-style-6 blockquote {
	background: transparent !important;
	border-radius: 4px;
	border: 0;
	padding: 0 10%;
}

.testimonial.testimonial-style-6 blockquote:before, .testimonial.testimonial-style-6 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-6 blockquote i.fa-quote-left {
	font-size: 34px;
	position: relative;
	left: -5px;
}

.testimonial.testimonial-style-6 blockquote p {
	color: #777;
	font-size: 24px;
	line-height: 30px;
}

.testimonial.testimonial-style-6 blockquote span {
	font-size: 16px;
	line-height: 20px;
	color: #999;
	position: relative;
	left: -5px;
}

.testimonial.testimonial-style-6 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-6 .testimonial-author {
	padding: 0;
	margin: 6px 0 0;
}

.testimonial.testimonial-style-6 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-6 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* With Quotes */
.testimonial.testimonial-with-quotes blockquote:before {
	color: #777;
	display: block !important;
	left: 10px;
	top: 0;
	content: "“";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

.testimonial.testimonial-with-quotes blockquote:after {
	color: #777;
	display: block !important;
	right: 10px;
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	content: "”";
}

.testimonial.testimonial-with-quotes blockquote p {
	padding: 0 40px;
}

/* Transparent Background */
.testimonial.testimonial-trasnparent-background blockquote {
	background: transparent !important;
}

/* Alternarive Font */
.testimonial.testimonial-alternarive-font blockquote p {
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
}

/* Light */
.section-text-light .testimonial.testimonial blockquote:before, .section-text-light .testimonial.testimonial blockquote:after, .section-text-light .testimonial.testimonial blockquote p {
	opacity: 0.9;
	color: #FFF;
}

.section-text-light .testimonial.testimonial .testimonial-author span, .section-text-light .testimonial.testimonial .testimonial-author strong {
	opacity: 0.6;
	color: #FFF;
}

/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
}

.social-icons li {
	display: inline-block;
	margin: -1px 1px 5px 0;
	padding: 0;
	border-radius: 100%;
	overflow: visible;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.social-icons li a {
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background: #FFF;
	border-radius: 100%;
	display: block;
	height: 30px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	color: #333 !important;
	text-decoration: none;
}

.social-icons li:hover a {
	background: #171717;
	color: #FFF !important;
}

.social-icons li:hover.social-icons-twitter a {
	background: #1aa9e1;
}

.social-icons li:hover.social-icons-facebook a {
	background: #3b5a9a;
}

.social-icons li:hover.social-icons-linkedin a {
	background: #0073b2;
}

.social-icons li:hover.social-icons-rss a {
	background: #ff8201;
}

.social-icons li:hover.social-icons-googleplus a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-pinterest a {
	background: #cc2127;
}

.social-icons li:hover.social-icons-youtube a {
	background: #c3191e;
}

.social-icons li:hover.social-icons-instagram a {
	background: #7c4a3a;
}

.social-icons li:hover.social-icons-skype a {
	background: #00b0f6;
}

.social-icons li:hover.social-icons-email a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-vk a {
	background: #6383a8;
}

.social-icons li:hover.social-icons-xing a {
	background: #1a7576;
}

.social-icons li:hover.social-icons-tumblr a {
	background: #304e6c;
}

.social-icons li:hover.social-icons-reddit a {
	background: #ff4107;
}

.social-icons.social-icons-transparent li {
	box-shadow: none;
}

.social-icons.social-icons-transparent li a {
	background: transparent;
}

.social-icons.social-icons-icon-light li a i {
	color: #FFF;
}

/* Arrows */
.arrow {
	background: transparent url(../img/arrows.png) no-repeat 0 0;
	width: 47px;
	height: 120px;
	display: inline-block;
	position: relative;
}

.arrow.arrow-light {
	background-image: url(../img/arrows-dark.png);
}

.arrow.vtl {
	background-position: 0 0;
	width: 47px;
	height: 96px;
}

.arrow.vtr {
	background-position: -101px 0;
	width: 47px;
	height: 96px;
}

.arrow.vbl {
	background-position: 0 -144px;
	width: 47px;
	height: 96px;
}

.arrow.vbr {
	background-position: -101px -144px;
	width: 47px;
	height: 96px;
}

.arrow.hlt {
	background-position: -209px 0;
	width: 120px;
	height: 47px;
}

.arrow.hlb {
	background-position: -209px -101px;
	width: 120px;
	height: 47px;
}

.arrow.hrt {
	background-position: -353px 0;
	width: 120px;
	height: 47px;
}

.arrow.hrb {
	background-position: -353px -101px;
	width: 120px;
	height: 47px;
}

/* Responsive */
@media (max-width: 479px) {
	.arrow {
		display: none;
	}
}
/* Call to Action */
.call-to-action {
	position: relative;
	clear: both;
	min-height: 125px;
	padding-left: 25px;
	padding-right: 25px;
}

.call-to-action::after {
	clear: both;
	content: "";
	display: table;
}

.call-to-action h3 {
	text-transform: none;
	color: #1d2127;
	padding: 0;
	margin: 0 0 9px;
}

.call-to-action p {
	font-size: 1.2em;
}

.call-to-action .call-to-action-content {
	text-align: center;
	width: 70%;
	margin-left: 3%;
	padding-top: 35px;
}

.call-to-action .call-to-action-btn {
	width: 22%;
	text-align: center;
	position: absolute;
	top: 50%;
	margin: -18px 0 0;
	left: auto;
	right: 25px;
}

.call-to-action.call-to-action-front {
	z-index: 1;
}

.call-to-action.call-to-action-default {
	background: #F4F4F4;
}

.call-to-action.call-to-action-dark {
	background: #2e353e;
}

.call-to-action.call-to-action-dark h3, .call-to-action.call-to-action-dark p {
	color: #FFF;
}

.call-to-action.with-borders {
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.with-full-borders {
	border: 2px solid #CCC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.featured {
	background: -webkit-linear-gradient( top , #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	padding: 0;
	margin: 0;
	border-top: 3px solid #CCC;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.featured.featured-primary {
	border-top-color: #CCC;
}

.call-to-action.featured.featured-secondary {
	border-top-color: #E36159;
}

.call-to-action.featured.featured-tertiary {
	border-top-color: #2BAAB1;
}

.call-to-action.featured.featured-quaternary {
	border-top-color: #2e353e;
}

.call-to-action.with-button-arrow .call-to-action-btn {
	top: 30%;
}

.call-to-action.button-centered .call-to-action-content {
	text-align: center;
	width: 100%;
	padding: 35px 0 0;
	margin: 0;
}

.call-to-action.button-centered .call-to-action-btn {
	text-align: center;
	position: static;
	margin: 20px auto 25px;
	width: auto;
}

.call-to-action.call-to-action-in-footer {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 30px;
	margin-bottom: -50px !important;
}

.call-to-action.call-to-action-in-footer:before {
	content: "";
	display: block;
	position: absolute;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #FFF;
	height: 20px;
	width: 20px;
	top: -5px;
	left: 50%;
	margin-left: -20px;
}

.call-to-action.no-top-arrow:before {
	display: none;
}

.call-to-action.call-to-action-in-footer-margin-top {
	margin-top: 60px;
}

.container .call-to-action {
	border-radius: 8px;
}

/* Responsive */
@media (max-width: 767px) {
	.call-to-action .call-to-action-content,
	.call-to-action .call-to-action-btn {
		margin: 0;
		padding: 20px;
		position: static;
		width: auto;
	}
}
/* Circular Bars */
.circular-bar {
	text-align: center;
	margin-bottom: 25px;
}

.circular-bar .circular-bar-chart {
	position: relative;
}

.circular-bar strong {
	display: block;
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	position: absolute;
	top: 35%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar label {
	display: block;
	font-weight: 100;
	font-size: 17px;
	line-height: 20px;
	position: absolute;
	top: 50%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar.only-icon .fa, .circular-bar.only-icon .icons {
	display: block;
	font-weight: 600;
	font-size: 38px;
	line-height: 30px;
	position: absolute;
	top: 40%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar.single-line strong {
	top: 40%;
}

.circular-bar.circular-bar-sm label {
	font-size: 14px;
	top: 36%;
}

.circular-bar.circular-bar-lg strong {
	font-size: 20px;
	top: 40%;
}

/* Progress */
.progress {
	border-radius: 25px;
	height: 20px;
	background: #FAFAFA;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
	overflow: visible;
}

.progress.progress-border-radius {
	border-radius: 3px;
}

.progress.progress-border-radius .progress-bar {
	border-radius: 3px;
}

.progress.progress-no-border-radius {
	border-radius: 0;
}

.progress.progress-no-border-radius .progress-bar {
	border-radius: 0;
}

.progress.progress-sm {
	height: 10px;
}

.progress.progress-sm .progress-bar {
	line-height: 10px;
}

.progress.progress-lg {
	height: 35px;
}

.progress.progress-lg .progress-bar {
	font-size: 13px;
	line-height: 35px;
}

.progress-bar {
	background-color: #CCC;
	box-shadow: none;
	position: relative;
	border-radius: 25px;
}

.progress-bar-tooltip {
	position: absolute;
	padding: 4px 8px;
	background-color: #333;
	color: #FFF;
	line-height: 15px;
	font-size: 11px;
	display: block;
	position: absolute;
	top: -28px;
	right: 5px;
	border-radius: 3px;
	opacity: 0;
}

.progress-bar-tooltip:after {
	border-color: #333 transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	bottom: -5px;
	content: "";
	display: block;
	left: 13px;
	position: absolute;
	width: 0;
}

.progress-bar-primary {
	background-color: #CCC;
	color: #FFF;
}

.progress-bar-success {
	background-color: #47a447;
	color: #FFF;
}

.progress-bar-warning {
	background-color: #ed9c28;
	color: #FFF;
}

.progress-bar-danger {
	background-color: #d2322d;
	color: #FFF;
}

.progress-bar-info {
	background-color: #5bc0de;
	color: #FFF;
}

.progress-bar-dark {
	background-color: #1d2127;
	color: #FFF;
}

/* Counters */
.counters .counter {
	text-align: center;
}

.counters .fa, .counters .icons {
	display: block;
	font-size: 35px;
	line-height: 35px;
	margin-bottom: 5px;
}

.counters strong {
	display: block;
	font-weight: bold;
	font-size: 50px;
	line-height: 50px;
}

.counters label {
	display: block;
	font-weight: 100;
	font-size: 20px;
	line-height: 20px;
}

.counters .counter-side-icon .fa, .counters .counter-side-icon .icons, .counters .counter-side-icon strong {
	display: inline-block;
}

.counters .counter-with-unit strong {
	display: inline-block;
}

.counters.with-borders .counter {
	border-radius: 6px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	padding: 25px;
}

.counters.counters-sm .fa, .counters.counters-sm .icons {
	font-size: 25px;
	line-height: 25px;
}

.counters.counters-sm strong {
	font-size: 35px;
	line-height: 35px;
}

.counters.counters-sm label {
	font-size: 15px;
	line-height: 15px;
}

.counters.counters-lg .fa {
	font-size: 45px;
	line-height: 45px;
}

.counters.counters-lg strong {
	font-size: 60px;
	line-height: 60px;
}

.counters.counters-lg label {
	font-size: 25px;
	line-height: 25px;
}

.counters.counters-text-light .counter {
	color: #FFF !important;
}

.counters.counters-text-light .counter .fa, .counters.counters-text-light .counter .icons, .counters.counters-text-light .counter strong, .counters.counters-text-light .counter label {
	color: #FFF !important;
}

.counters.counters-text-dark .counter {
	color: #2e353e !important;
}

.counters.counters-text-dark .counter .fa, .counters.counters-text-dark .counter .icons, .counters.counters-text-dark .counter strong, .counters.counters-text-dark .counter label {
	color: #2e353e !important;
}

/* Word Rotate */
.word-rotate {
	display: inline-block;
	overflow: hidden;
	text-align: center;
	position: relative;
	max-height: 22px;
	line-height: 22px;
}

.word-rotate .word-rotate-items {
	position: relative;
	top: 0;
	width: 0;
	display: inline-block;
}

.word-rotate .word-rotate-items span {
	display: inline-block;
	white-space: nowrap;
}

.word-rotate .word-rotate-items span:not(:first-child) {
	display: none;
}

.word-rotate.active .word-rotate-items {
	position: relative;
}

.word-rotate.active .word-rotate-items span {
	display: inline-block;
	float: left;
	clear: both;
}

h1.word-rotator-title .inverted {
	min-height: 56px;
}

h1.word-rotator-title .word-rotate {
	max-height: 46px;
	line-height: 46px;
	margin-bottom: -9px;
}

h2.word-rotator-title .inverted {
	min-height: 46px;
}

h2.word-rotator-title .word-rotate {
	max-height: 42px;
	line-height: 42px;
	margin-bottom: -9px;
}

h3.word-rotator-title .inverted {
	min-height: 25px;
}

h3.word-rotator-title .word-rotate {
	max-height: 24px;
	line-height: 24px;
	margin-bottom: -2px;
}

h4.word-rotator-title .inverted {
	min-height: 32px;
}

h4.word-rotator-title .word-rotate {
	max-height: 27px;
	line-height: 27px;
	margin-bottom: -6px;
}

h5.word-rotator-title .inverted {
	min-height: 18px;
}

h5.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

h6.word-rotator-title .inverted {
	min-height: 20px;
}

h6.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

p .inverted {
	min-height: 24px;
}

p .word-rotate {
	max-height: 22px;
	line-height: 22px;
	margin-bottom: -6px;
}

/* Scroll to Top */
html .scroll-to-top {
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
	background: #404040;
	border-radius: 7px 7px 0 0;
	bottom: 0;
	color: #FFF;
	display: block;
	height: 9px;
	opacity: 0;
	padding: 13px 10px 35px;
	position: fixed;
	right: 10px;
	text-align: center;
	text-decoration: none;
	min-width: 49px;
	z-index: 1040;
}

html .scroll-to-top:hover {
	opacity: 1;
}

html .scroll-to-top.visible {
	opacity: 0.75;
}

html .scroll-to-top span {
	display: inline-block;
	padding: 0 5px;
}

html.lightbox-opened .scroll-to-top {
	right: 27px;
}

html.ie11 .scroll-to-top {
	right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
	html .scroll-to-top.hidden-mobile {
		display: none !important;
	}
}

.flag {
	width: 16px;
	height: 11px;
	background: url(../img/flags.png) no-repeat;
}

.flag.flag-ad {
	background-position: -16px 0;
}

.flag.flag-ae {
	background-position: -32px 0;
}

.flag.flag-af {
	background-position: -48px 0;
}

.flag.flag-ag {
	background-position: -64px 0;
}

.flag.flag-ai {
	background-position: -80px 0;
}

.flag.flag-al {
	background-position: -96px 0;
}

.flag.flag-am {
	background-position: -112px 0;
}

.flag.flag-an {
	background-position: -128px 0;
}

.flag.flag-ao {
	background-position: -144px 0;
}

.flag.flag-ar {
	background-position: -160px 0;
}

.flag.flag-as {
	background-position: -176px 0;
}

.flag.flag-at {
	background-position: -192px 0;
}

.flag.flag-au {
	background-position: -208px 0;
}

.flag.flag-aw {
	background-position: -224px 0;
}

.flag.flag-az {
	background-position: -240px 0;
}

.flag.flag-ba {
	background-position: 0 -11px;
}

.flag.flag-bb {
	background-position: -16px -11px;
}

.flag.flag-bd {
	background-position: -32px -11px;
}

.flag.flag-be {
	background-position: -48px -11px;
}

.flag.flag-bf {
	background-position: -64px -11px;
}

.flag.flag-bg {
	background-position: -80px -11px;
}

.flag.flag-bh {
	background-position: -96px -11px;
}

.flag.flag-bi {
	background-position: -112px -11px;
}

.flag.flag-bj {
	background-position: -128px -11px;
}

.flag.flag-bm {
	background-position: -144px -11px;
}

.flag.flag-bn {
	background-position: -160px -11px;
}

.flag.flag-bo {
	background-position: -176px -11px;
}

.flag.flag-br {
	background-position: -192px -11px;
}

.flag.flag-bs {
	background-position: -208px -11px;
}

.flag.flag-bt {
	background-position: -224px -11px;
}

.flag.flag-bv {
	background-position: -240px -11px;
}

.flag.flag-bw {
	background-position: 0 -22px;
}

.flag.flag-by {
	background-position: -16px -22px;
}

.flag.flag-bz {
	background-position: -32px -22px;
}

.flag.flag-ca {
	background-position: -48px -22px;
}

.flag.flag-catalonia {
	background-position: -64px -22px;
}

.flag.flag-cd {
	background-position: -80px -22px;
}

.flag.flag-cf {
	background-position: -96px -22px;
}

.flag.flag-cg {
	background-position: -112px -22px;
}

.flag.flag-ch {
	background-position: -128px -22px;
}

.flag.flag-ci {
	background-position: -144px -22px;
}

.flag.flag-ck {
	background-position: -160px -22px;
}

.flag.flag-cl {
	background-position: -176px -22px;
}

.flag.flag-cm {
	background-position: -192px -22px;
}

.flag.flag-cn {
	background-position: -208px -22px;
}

.flag.flag-co {
	background-position: -224px -22px;
}

.flag.flag-cr {
	background-position: -240px -22px;
}

.flag.flag-cu {
	background-position: 0 -33px;
}

.flag.flag-cv {
	background-position: -16px -33px;
}

.flag.flag-cw {
	background-position: -32px -33px;
}

.flag.flag-cy {
	background-position: -48px -33px;
}

.flag.flag-cz {
	background-position: -64px -33px;
}

.flag.flag-de {
	background-position: -80px -33px;
}

.flag.flag-dj {
	background-position: -96px -33px;
}

.flag.flag-dk {
	background-position: -112px -33px;
}

.flag.flag-dm {
	background-position: -128px -33px;
}

.flag.flag-do {
	background-position: -144px -33px;
}

.flag.flag-dz {
	background-position: -160px -33px;
}

.flag.flag-ec {
	background-position: -176px -33px;
}

.flag.flag-ee {
	background-position: -192px -33px;
}

.flag.flag-eg {
	background-position: -208px -33px;
}

.flag.flag-eh {
	background-position: -224px -33px;
}

.flag.flag-england {
	background-position: -240px -33px;
}

.flag.flag-er {
	background-position: 0 -44px;
}

.flag.flag-es {
	background-position: -16px -44px;
}

.flag.flag-et {
	background-position: -32px -44px;
}

.flag.flag-eu {
	background-position: -48px -44px;
}

.flag.flag-fi {
	background-position: -64px -44px;
}

.flag.flag-fj {
	background-position: -80px -44px;
}

.flag.flag-fk {
	background-position: -96px -44px;
}

.flag.flag-fm {
	background-position: -112px -44px;
}

.flag.flag-fo {
	background-position: -128px -44px;
}

.flag.flag-fr {
	background-position: -144px -44px;
}

.flag.flag-ga {
	background-position: -160px -44px;
}

.flag.flag-gb {
	background-position: -176px -44px;
}

.flag.flag-gd {
	background-position: -192px -44px;
}

.flag.flag-ge {
	background-position: -208px -44px;
}

.flag.flag-gf {
	background-position: -224px -44px;
}

.flag.flag-gg {
	background-position: -240px -44px;
}

.flag.flag-gh {
	background-position: 0 -55px;
}

.flag.flag-gi {
	background-position: -16px -55px;
}

.flag.flag-gl {
	background-position: -32px -55px;
}

.flag.flag-gm {
	background-position: -48px -55px;
}

.flag.flag-gn {
	background-position: -64px -55px;
}

.flag.flag-gp {
	background-position: -80px -55px;
}

.flag.flag-gq {
	background-position: -96px -55px;
}

.flag.flag-gr {
	background-position: -112px -55px;
}

.flag.flag-gs {
	background-position: -128px -55px;
}

.flag.flag-gt {
	background-position: -144px -55px;
}

.flag.flag-gu {
	background-position: -160px -55px;
}

.flag.flag-gw {
	background-position: -176px -55px;
}

.flag.flag-gy {
	background-position: -192px -55px;
}

.flag.flag-hk {
	background-position: -208px -55px;
}

.flag.flag-hm {
	background-position: -224px -55px;
}

.flag.flag-hn {
	background-position: -240px -55px;
}

.flag.flag-hr {
	background-position: 0 -66px;
}

.flag.flag-ht {
	background-position: -16px -66px;
}

.flag.flag-hu {
	background-position: -32px -66px;
}

.flag.flag-ic {
	background-position: -48px -66px;
}

.flag.flag-id {
	background-position: -64px -66px;
}

.flag.flag-ie {
	background-position: -80px -66px;
}

.flag.flag-il {
	background-position: -96px -66px;
}

.flag.flag-im {
	background-position: -112px -66px;
}

.flag.flag-in {
	background-position: -128px -66px;
}

.flag.flag-io {
	background-position: -144px -66px;
}

.flag.flag-iq {
	background-position: -160px -66px;
}

.flag.flag-ir {
	background-position: -176px -66px;
}

.flag.flag-is {
	background-position: -192px -66px;
}

.flag.flag-it {
	background-position: -208px -66px;
}

.flag.flag-je {
	background-position: -224px -66px;
}

.flag.flag-jm {
	background-position: -240px -66px;
}

.flag.flag-jo {
	background-position: 0 -77px;
}

.flag.flag-jp {
	background-position: -16px -77px;
}

.flag.flag-ke {
	background-position: -32px -77px;
}

.flag.flag-kg {
	background-position: -48px -77px;
}

.flag.flag-kh {
	background-position: -64px -77px;
}

.flag.flag-ki {
	background-position: -80px -77px;
}

.flag.flag-km {
	background-position: -96px -77px;
}

.flag.flag-kn {
	background-position: -112px -77px;
}

.flag.flag-kp {
	background-position: -128px -77px;
}

.flag.flag-kr {
	background-position: -144px -77px;
}

.flag.flag-kurdistan {
	background-position: -160px -77px;
}

.flag.flag-kw {
	background-position: -176px -77px;
}

.flag.flag-ky {
	background-position: -192px -77px;
}

.flag.flag-kz {
	background-position: -208px -77px;
}

.flag.flag-la {
	background-position: -224px -77px;
}

.flag.flag-lb {
	background-position: -240px -77px;
}

.flag.flag-lc {
	background-position: 0 -88px;
}

.flag.flag-li {
	background-position: -16px -88px;
}

.flag.flag-lk {
	background-position: -32px -88px;
}

.flag.flag-lr {
	background-position: -48px -88px;
}

.flag.flag-ls {
	background-position: -64px -88px;
}

.flag.flag-lt {
	background-position: -80px -88px;
}

.flag.flag-lu {
	background-position: -96px -88px;
}

.flag.flag-lv {
	background-position: -112px -88px;
}

.flag.flag-ly {
	background-position: -128px -88px;
}

.flag.flag-ma {
	background-position: -144px -88px;
}

.flag.flag-mc {
	background-position: -160px -88px;
}

.flag.flag-md {
	background-position: -176px -88px;
}

.flag.flag-me {
	background-position: -192px -88px;
}

.flag.flag-mg {
	background-position: -208px -88px;
}

.flag.flag-mh {
	background-position: -224px -88px;
}

.flag.flag-mk {
	background-position: -240px -88px;
}

.flag.flag-ml {
	background-position: 0 -99px;
}

.flag.flag-mm {
	background-position: -16px -99px;
}

.flag.flag-mn {
	background-position: -32px -99px;
}

.flag.flag-mo {
	background-position: -48px -99px;
}

.flag.flag-mp {
	background-position: -64px -99px;
}

.flag.flag-mq {
	background-position: -80px -99px;
}

.flag.flag-mr {
	background-position: -96px -99px;
}

.flag.flag-ms {
	background-position: -112px -99px;
}

.flag.flag-mt {
	background-position: -128px -99px;
}

.flag.flag-mu {
	background-position: -144px -99px;
}

.flag.flag-mv {
	background-position: -160px -99px;
}

.flag.flag-mw {
	background-position: -176px -99px;
}

.flag.flag-mx {
	background-position: -192px -99px;
}

.flag.flag-my {
	background-position: -208px -99px;
}

.flag.flag-mz {
	background-position: -224px -99px;
}

.flag.flag-na {
	background-position: -240px -99px;
}

.flag.flag-nc {
	background-position: 0 -110px;
}

.flag.flag-ne {
	background-position: -16px -110px;
}

.flag.flag-nf {
	background-position: -32px -110px;
}

.flag.flag-ng {
	background-position: -48px -110px;
}

.flag.flag-ni {
	background-position: -64px -110px;
}

.flag.flag-nl {
	background-position: -80px -110px;
}

.flag.flag-no {
	background-position: -96px -110px;
}

.flag.flag-np {
	background-position: -112px -110px;
}

.flag.flag-nr {
	background-position: -128px -110px;
}

.flag.flag-nu {
	background-position: -144px -110px;
}

.flag.flag-nz {
	background-position: -160px -110px;
}

.flag.flag-om {
	background-position: -176px -110px;
}

.flag.flag-pa {
	background-position: -192px -110px;
}

.flag.flag-pe {
	background-position: -208px -110px;
}

.flag.flag-pf {
	background-position: -224px -110px;
}

.flag.flag-pg {
	background-position: -240px -110px;
}

.flag.flag-ph {
	background-position: 0 -121px;
}

.flag.flag-pk {
	background-position: -16px -121px;
}

.flag.flag-pl {
	background-position: -32px -121px;
}

.flag.flag-pm {
	background-position: -48px -121px;
}

.flag.flag-pn {
	background-position: -64px -121px;
}

.flag.flag-pr {
	background-position: -80px -121px;
}

.flag.flag-ps {
	background-position: -96px -121px;
}

.flag.flag-pt {
	background-position: -112px -121px;
}

.flag.flag-pw {
	background-position: -128px -121px;
}

.flag.flag-py {
	background-position: -144px -121px;
}

.flag.flag-qa {
	background-position: -160px -121px;
}

.flag.flag-re {
	background-position: -176px -121px;
}

.flag.flag-ro {
	background-position: -192px -121px;
}

.flag.flag-rs {
	background-position: -208px -121px;
}

.flag.flag-ru {
	background-position: -224px -121px;
}

.flag.flag-rw {
	background-position: -240px -121px;
}

.flag.flag-sa {
	background-position: 0 -132px;
}

.flag.flag-sb {
	background-position: -16px -132px;
}

.flag.flag-sc {
	background-position: -32px -132px;
}

.flag.flag-scotland {
	background-position: -48px -132px;
}

.flag.flag-sd {
	background-position: -64px -132px;
}

.flag.flag-se {
	background-position: -80px -132px;
}

.flag.flag-sg {
	background-position: -96px -132px;
}

.flag.flag-sh {
	background-position: -112px -132px;
}

.flag.flag-si {
	background-position: -128px -132px;
}

.flag.flag-sk {
	background-position: -144px -132px;
}

.flag.flag-sl {
	background-position: -160px -132px;
}

.flag.flag-sm {
	background-position: -176px -132px;
}

.flag.flag-sn {
	background-position: -192px -132px;
}

.flag.flag-so {
	background-position: -208px -132px;
}

.flag.flag-somaliland {
	background-position: -224px -132px;
}

.flag.flag-sr {
	background-position: -240px -132px;
}

.flag.flag-ss {
	background-position: 0 -143px;
}

.flag.flag-st {
	background-position: -16px -143px;
}

.flag.flag-sv {
	background-position: -32px -143px;
}

.flag.flag-sx {
	background-position: -48px -143px;
}

.flag.flag-sy {
	background-position: -64px -143px;
}

.flag.flag-sz {
	background-position: -80px -143px;
}

.flag.flag-tc {
	background-position: -96px -143px;
}

.flag.flag-td {
	background-position: -112px -143px;
}

.flag.flag-tf {
	background-position: -128px -143px;
}

.flag.flag-tg {
	background-position: -144px -143px;
}

.flag.flag-th {
	background-position: -160px -143px;
}

.flag.flag-tibet {
	background-position: -176px -143px;
}

.flag.flag-tj {
	background-position: -192px -143px;
}

.flag.flag-tk {
	background-position: -208px -143px;
}

.flag.flag-tl {
	background-position: -224px -143px;
}

.flag.flag-tm {
	background-position: -240px -143px;
}

.flag.flag-tn {
	background-position: 0 -154px;
}

.flag.flag-to {
	background-position: -16px -154px;
}

.flag.flag-tr {
	background-position: -32px -154px;
}

.flag.flag-tt {
	background-position: -48px -154px;
}

.flag.flag-tv {
	background-position: -64px -154px;
}

.flag.flag-tw {
	background-position: -80px -154px;
}

.flag.flag-tz {
	background-position: -96px -154px;
}

.flag.flag-ua {
	background-position: -112px -154px;
}

.flag.flag-ug {
	background-position: -128px -154px;
}

.flag.flag-um {
	background-position: -144px -154px;
}

.flag.flag-us {
	background-position: -160px -154px;
}

.flag.flag-uy {
	background-position: -176px -154px;
}

.flag.flag-uz {
	background-position: -192px -154px;
}

.flag.flag-va {
	background-position: -208px -154px;
}

.flag.flag-vc {
	background-position: -224px -154px;
}

.flag.flag-ve {
	background-position: -240px -154px;
}

.flag.flag-vg {
	background-position: 0 -165px;
}

.flag.flag-vi {
	background-position: -16px -165px;
}

.flag.flag-vn {
	background-position: -32px -165px;
}

.flag.flag-vu {
	background-position: -48px -165px;
}

.flag.flag-wales {
	background-position: -64px -165px;
}

.flag.flag-wf {
	background-position: -80px -165px;
}

.flag.flag-ws {
	background-position: -96px -165px;
}

.flag.flag-xk {
	background-position: -112px -165px;
}

.flag.flag-ye {
	background-position: -128px -165px;
}

.flag.flag-yt {
	background-position: -144px -165px;
}

.flag.flag-za {
	background-position: -160px -165px;
}

.flag.flag-zanzibar {
	background-position: -176px -165px;
}

.flag.flag-zm {
	background-position: -192px -165px;
}

.flag.flag-zw {
	background-position: -208px -165px;
}

.loading-overlay-showing {
	overflow: hidden;
}

.loading-overlay-showing > .loading-overlay {
	opacity: 1;
	visibility: visible;
}

.loading-overlay {
	transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
	bottom: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	right: 0;
	top: 0;
	visibility: hidden;
	background: #FFF;
}

body > .loading-overlay {
	position: fixed;
	z-index: 999999;
}

/* Bounce Loading */
.bounce-loader {
	transition: all 0.2;
	margin: -9px 0 0 -35px;
	text-align: center;
	width: 70px;
	left: 50%;
	position: absolute;
	top: 50%;
	z-index: 10000;
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
	animation: 1.4s ease-in-out 0s normal both infinite running bouncedelay;
	background-color: #CCC;
	border-radius: 100%;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	display: inline-block;
	height: 18px;
	width: 18px;
}

.bounce-loader .bounce1 {
	animation-delay: -0.32s;
}

.bounce-loader .bounce2 {
	animation-delay: -0.16s;
}

@keyframes bouncedelay {
	0%, 80%, 100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
/* Portfolio */
.portfolio-title {
	margin-bottom: -10px;
}

.portfolio-title a {
	display: inline-block;
}

.portfolio-title .fa, .portfolio-title .icons {
	font-size: 1.7em;
}

.portfolio-title .portfolio-nav {
	margin-top: 11px;
}

.portfolio-title .portfolio-nav-all {
	margin-top: 9px;
}

.portfolio-title .portfolio-nav {
	text-align: right;
}

.portfolio-title .portfolio-nav a {
	width: 40%;
}

.portfolio-title .portfolio-nav-all .fa,
.portfolio-title .portfolio-nav-all .icons {
	font-size: 2em;
}

.portfolio-title .fa-angle-right {
	text-align: right;
}

.portfolio-title .portfolio-nav-prev {
	text-align: left;
}

/* Portfolio Info */
.portfolio-info {
	margin: 2px 0 10px;
	float: right;
}

.portfolio-info ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.portfolio-info ul li {
	display: inline-block;
	border-right: 1px solid #E6E6E6;
	color: #B1B1B1;
	padding-right: 8px;
	margin-right: 8px;
	font-size: 0.9em;
}

.portfolio-info ul li:last-child {
	border-right: 0;
	padding-right: 0;
	margin-right: 0;
}

.portfolio-info ul li a {
	text-decoration: none;
	color: #B1B1B1;
}

.portfolio-info ul li a:hover .fa-heart {
	color: #E53F51;
}

.portfolio-info ul li .fa, .portfolio-info ul li .icons {
	margin-right: 4px;
}

/* Portfolio List */
ul.portfolio-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.portfolio-list .portfolio-item {
	position: relative;
	margin-bottom: 35px;
}

ul.portfolio-list.portfolio-list-no-margins {
	margin-left: 15px;
	margin-right: 15px;
}

/* Portfolio Details */
ul.portfolio-details {
	list-style: none;
	padding: 0;
	margin: 45px 0 0 5px;
	font-size: 0.9em;
}

ul.portfolio-details > li {
	padding: 0;
	margin: 0 0 20px;
}

ul.portfolio-details p {
	padding: 0;
	margin: 0;
}

ul.portfolio-details blockquote {
	padding: 0 0 0 10px;
	margin: 15px 0 0;
	color: #999;
}

ul.portfolio-details .list-icons {
	margin-left: 0;
}

ul.portfolio-details .list-icons > li {
	padding-left: 18px;
	margin-right: 10px;
}

ul.portfolio-details .list-icons > li .fa, ul.portfolio-details .list-icons > li .icons {
	top: 7px;
}

/* Portfolio Modal */
.portfolio-modal .mfp-arrow,
.portfolio-modal .mfp-close,
.portfolio-ajax-modal .mfp-arrow,
.portfolio-ajax-modal .mfp-close {
	display: none;
}

.portfolio-load-more-loader {
	display: none;
	position: relative;
	height: 49px;
}

.btn-portfolio-infinite-scroll {
	opacity: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.portfolio-nav, .portfolio-nav-all {
		display: none;
	}
}
/* Team List */
ul.team-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.team-list .team-item {
	margin-bottom: 35px;
}

ul.team-list .team-item .thumb-info-social-icons {
	margin: 0 10px;
}

/* Responsive */
@media (max-width: 991px) {
	ul.team-list .team-item {
		margin: 0 auto 35px;
		max-width: 270px;
	}
}
/* History */
ul.history {
	margin: 0 0 25px;
	padding: 0;
	list-style: none;
}

ul.history li {
	margin: 25px 0;
	padding: 0;
	clear: both;
}

ul.history li h4 {
	margin: 0 0 -27px 0;
	padding: 0;
}

ul.history li p {
	margin-left: 53px;
}

ul.history li .thumb {
	background: transparent url(../img/history-thumb.png) no-repeat 0 0;
	width: 227px;
	height: 160px;
	margin: 0 0 30px;
	padding: 0;
	float: left;
}

ul.history li .thumb img {
	border-radius: 150px;
	margin: 7px 8px;
	width: 145px;
	height: 145px;
}

ul.history li .featured-box {
	text-align: left;
	margin-left: 225px;
	margin-bottom: 50px;
	min-height: 115px;
}

ul.history li .featured-box .box-content {
	border-top-color: #CCC;
}

/* Responsive */
@media (max-width: 479px) {
	ul.history li .thumb {
		display: none;
	}

	ul.history li .featured-box {
		margin-left: 0;
	}
}
/* Search Results */
.search-results {
	min-height: 300px;
}

/* Maps */
.google-map {
	background: #E5E3DF;
	height: 400px;
	margin: -35px 0 30px 0;
	width: 100%;
}

.google-map.small {
	height: 250px;
	border-radius: 6px;
	margin: 0;
	clear: both;
}

.google-map img {
	max-width: 9999px;
}

.google-map-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Google Map */
.google-map-footer {
	margin: 35px 0 -50px !important;
}


/*
Name: 			theme.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	5.7.2
*/
html {
	direction: ltr;
	overflow-x: hidden;
	box-shadow: none !important;
}

body {
	background-color: #FFF;
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 14px;
	line-height: 22px;
	margin: 0;
}

body a {
	outline: none !important;
}

li {
	line-height: 24px;
}

/* Responsive */
@media (max-width: 479px) {
	body {
		font-size: 13px;
	}
}
/* Header */
#header {
	position: relative;
	z-index: 100;
}

#header .header-body {
	background: #FFF;
	border-top: 3px solid #EDEDED;
	border-bottom: 1px solid transparent;
	padding: 8px 0;
	-webkit-transition: min-height 0.3s ease;
	-moz-transition: min-height 0.3s ease;
	transition: min-height 0.3s ease;
	width: 100%;
	z-index: 1001;
	min-height: 125px;
}

#header.header-no-min-height .header-body {
	min-height: 0 !important;
}

#header .container {
	position: relative;
}

#header .header-container {
	position: relative;
	display: table;
}

#header .header-top {
	background-color: #f4f4f4;
	border-bottom: 1px solid #ededed;
	margin-top: -13px;
	padding: 8px 0;
	position: relative;
	z-index: 4;
}

#header .header-top::after {
	clear: both;
	content: "";
	display: table;
}

#header .header-top p {
	font-size: 0.9em;
	margin: 0;
	padding: 0;
	line-height: 37px;
	float: left;
}

#header .header-top p .fa, #header .header-top p .icons {
	position: relative;
	top: 1px;
}

#header .header-top .header-search {
	float: right;
	margin-top: 2px;
	margin-left: 10px;
	margin-right: 0;
}

#header .header-top .header-nav-top {
	float: left;
	margin-top: 0;
	margin-left: 0;
	margin-right: 10px;
}

#header .header-top .header-social-icons {
	margin-top: 3px;
}

#header .header-top.header-top-colored {
	margin-top: -13px;
	border-top: 5px solid #CCC;
	border-bottom: 0;
	color: #FFF;
}

#header .header-top.header-top-colored p, #header .header-top.header-top-colored a, #header .header-top.header-top-colored span {
	color: #FFF;
}

#header .header-top.header-top-colored .form-control {
	border: 0;
}

#header .header-top.header-top-style-2 {
	background: #f6f6f6;
	margin-top: -16px;
}

#header .header-top.header-top-style-3 {
	margin-top: -16px;
	margin-bottom: 10px;
}

#header .header-top.header-top-style-4 {
	background: #E4E6ED;
	margin-top: -16px;
	margin-bottom: 10px;
	border-bottom: 0;
}

#header .header-row {
	display: table-row;
	clear: both;
}

#header .header-column {
	display: table-cell;
	vertical-align: middle;
}

#header .header-column .row {
	margin: 0;
}

#header .header-column .header-row {
	display: block;
	clear: right;
}

#header .header-column .header-extra-info {
	list-style: outside none none;
	margin: 24px 0 0;
	padding: 0;
	float: right;
}

#header .header-column .header-extra-info li {
	display: inline-block;
	margin-left: 25px;
}

#header .header-column .header-extra-info li .feature-box small {
	position: relative;
	top: -4px;
}

#header .header-column.header-column-valign-top {
	vertical-align: top;
}

#header .header-column.header-column-valign-bottom {
	vertical-align: bottom;
}

#header .header-column.header-column-center {
	text-align: center;
}

#header .header-column.header-column-center .header-logo {
	width: 100% !important;
	padding: 9px 0;
}

#header .header-logo {
	float: left;
	position: relative;
}

#header .header-logo img {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	position: relative;
	margin: 15px 15px 15px 0;
	top: 0;
}

#header .header-nav-top {
	float: right;
	margin-top: 10px;
	margin-left: 10px;
}

#header .header-nav-top .nav > li > a, #header .header-nav-top .nav > li > span {
	color: #999;
	font-size: 0.9em;
	padding: 6px 10px;
	display: inline-block;
}

#header .header-nav-top .nav > li > a .fa, #header .header-nav-top .nav > li > a .icons, #header .header-nav-top .nav > li > span .fa, #header .header-nav-top .nav > li > span .icons {
	margin-right: 3px;
	position: relative;
	top: 1px;
}

#header .header-nav-top .nav > li > a:hover, #header .header-nav-top .nav > li > a a:focus {
	background: #EEE;
}

#header .header-nav-top .nav > li.open > .dropdown-menu-toggle {
	border-radius: 4px 4px 0 0;
}

#header .header-nav-top .dropdown-menu {
	border-color: #EEE;
	margin-top: -2px;
	border-radius: 4px 0 4px 4px;
	padding: 0;
}

#header .header-nav-top .dropdown-menu a {
	color: #999;
	padding: 6px 12px;
	font-size: 0.9em;
}

#header .header-nav-top .flag {
	margin-right: 2px;
}

#header .header-search {
	float: right;
	margin-top: 10px;
	margin-left: 10px;
}

#header .header-search .form-control {
	border-radius: 20px;
	font-size: 0.9em;
	height: 34px;
	margin: 0;
	padding: 6px 12px;
	-webkit-transition: width 0.3s ease;
	-moz-transition: width 0.3s ease;
	transition: width 0.3s ease;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	width: 170px;
}

#header .header-search .btn-default {
	background: transparent;
	border: 0 none;
	color: #CCC;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
}

#header .header-search .btn-default:hover {
	color: #000;
}

#header .header-social-icons {
	float: right;
	margin: 15px 0 0 10px;
}

#header .header-btn-collapse-nav {
	background: #CCC;
	color: #FFF;
	display: none;
	float: right;
	margin: 12px 0 8px 15px;
}

#header.header-narrow .header-body {
	min-height: 0;
}

#header.header-narrow .header-logo img {
	margin: 12px 12px 12px 0;
}

#header.header-narrow .header-nav-main {
	margin-bottom: 0;
}

#header.header-no-border-bottom .header-body {
	padding-bottom: 0 !important;
	border-bottom: 0 !important;
}

html:not(.boxed) #header.header-full-width .header-container {
	width: 100%;
}

html #header.header-transparent {
	min-height: 0 !important;
	width: 100%;
	position: absolute;
}

html #header.header-transparent .header-body {
	position: relative;
	top: 0;
	background: transparent;
	border-top: 0;
	border-bottom: 0;
	box-shadow: none;
}

html #header.header-transparent .header-body:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #FFF;
	opacity: 0;
	border-bottom: 1px solid #f1f1f1;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

html.sticky-header-enabled #header.header-transparent .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-transparent .header-body {
	border-bottom: 0;
	box-shadow: none;
}

html.sticky-header-active #header.header-transparent .header-body:before {
	opacity: 1;
}

@media (max-width: 991px) {
	html.sticky-header-active #header.has-nav-bar .header-logo img {
		top: 86px;
		padding: 5px 8px;
		z-index: 1;
	}
}

html #header.header-semi-transparent, html #header.header-semi-transparent-light {
	min-height: 0 !important;
	width: 100%;
	position: absolute;
}

html #header.header-semi-transparent .header-body, html #header.header-semi-transparent-light .header-body {
	position: relative;
	top: 0;
	background: transparent;
	border-top: 0;
	border-bottom: 0;
	box-shadow: none;
}

html #header.header-semi-transparent .header-body:before, html #header.header-semi-transparent-light .header-body:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #000;
	border-bottom: 0;
	opacity: 0.3;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

html.sticky-header-enabled #header.header-semi-transparent .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-semi-transparent .header-body {
	border-bottom: 0;
	box-shadow: none;
}

html.sticky-header-active #header.header-semi-transparent .header-body:before {
	opacity: 0.8;
}

html #header.header-semi-transparent-light .header-body:before {
	opacity: 0.1;
	background: #FFF;
}

html.sticky-header-enabled #header.header-semi-transparent-light .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-semi-transparent-light .header-body {
	border-bottom: 0;
	box-shadow: none;
}

html.sticky-header-active #header.header-semi-transparent-light .header-body:before {
	border-bottom: 1px solid #f1f1f1;
	opacity: 0.9;
}

html #header.header-transparent-bottom-border .header-body {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

html.sticky-header-active #header .header-body {
	position: fixed;
	border-bottom-color: #e9e9e9;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

html:not(.sticky-header-active) #header.header-transparent-sticky-deactive .header-body:before {
	background: transparent !important;
}

/* Header Nav Main */
@media (min-width: 992px) {
	#header .header-nav-main {
		float: right;
		margin: 12px 0 0;
		min-height: 45px;
	}

	#header .header-nav-main nav > ul > li > a {
		display: inline-block;
		border-radius: 4px;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		padding: 10px 13px;
		text-transform: uppercase;
	}

	#header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-caret-down {
		display: none;
	}

	#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
		border-style: solid;
		border-width: 4px;
		content: " ";
		float: right;
		margin-top: 7px;
		margin-left: 4px;
	}

	#header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li:hover > a {
		background: #CCC;
		color: #FFF;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
		top: -10000px;
		display: block;
		opacity: 0;
		left: auto;
		border-radius: 0 4px 4px;
		border: 0;
		border-top: 3px solid #CCC;
		box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		margin: -3px 0 0 0;
		min-width: 200px;
		padding: 5px;
		text-align: left;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
		border-bottom: 1px solid #f4f4f4;
		color: #777;
		font-size: 0.9em;
		font-weight: 400;
		padding: 8px 20px 8px 8px;
		position: relative;
		text-transform: none;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
		position: relative;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a .fa-caret-down {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #CCC;
		border-style: solid;
		border-width: 4px 0 4px 4px;
		content: " ";
		position: absolute;
		top: 50%;
		right: 10px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		left: 100%;
		display: block;
		margin-top: -8px;
		margin-left: -1px;
		border-radius: 4px;
		opacity: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
		top: 0;
		opacity: 1;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child a {
		border-bottom: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > a, #header .header-nav-main nav > ul > li.dropdown:hover > a {
		padding-bottom: 15px;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		top: auto;
		display: block;
		opacity: 1;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega {
		position: static;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
		border-radius: 4px;
		left: 15px;
		right: 15px;
		width: auto;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-content {
		padding: 20px 30px;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title {
		color: #333333;
		display: block;
		font-size: 1em;
		font-weight: 600;
		margin-top: 20px;
		padding-bottom: 5px;
		text-transform: uppercase;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title:first-child {
		margin-top: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		border: 0 none;
		border-radius: 4px;
		color: #777;
		display: block;
		font-size: 0.9em;
		font-weight: normal;
		margin: 0 0 0 -8px;
		padding: 3px 8px;
		text-shadow: none;
		text-transform: none;
		text-decoration: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
		background: #f4f4f4;
	}

	#header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	#header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle:after {
		display: none;
	}

	#header .header-nav-main.header-nav-main-square {
		margin-bottom: -9px;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li > a {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
		margin-top: -1px;
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega > .dropdown-menu {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		border-radius: 0;
	}

	#header .header-nav-main a > .thumb-info-preview {
		position: absolute;
		display: block;
		left: 100%;
		opacity: 0;
		border: 0;
		padding-left: 10px;
		background: transparent;
		overflow: visible;
		margin-top: 15px;
		top: -10000px;
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(-20px, 0, 0);
	}

	#header .header-nav-main a > .thumb-info-preview .thumb-info-wrapper {
		background: #FFF;
		display: block;
		border-radius: 4px;
		border: 0;
		box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		margin: 0;
		padding: 4px;
		text-align: left;
		width: 190px;
	}

	#header .header-nav-main a > .thumb-info-preview .thumb-info-image {
		transition: all 6s linear 0s;
		width: 182px;
		height: 136px;
		min-height: 0;
	}

	#header .header-nav-main a:hover > .thumb-info-preview {
		transform: translate3d(0, 0, 0);
		top: 0;
		opacity: 1;
		margin-top: -5px;
	}

	#header .header-nav-main .dropdown-reverse a > .thumb-info-preview {
		transform: translate3d(20px, 0, 0);
		right: 100%;
		left: auto;
		padding-left: 0;
		margin-right: 10px;
	}

	#header .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav {
		display: block !important;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a, #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
		background: transparent;
		padding: 35px 13px;
		margin: 0;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a {
		color: #444;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:hover > a {
		color: #FFF;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a, #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
		background: transparent;
		color: #444;
		padding: 35px 13px;
		margin: 0;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a:before, #header .header-nav.header-nav-top-line nav > ul > li:hover > a:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 3px;
		top: -2px;
		left: 0;
		opacity: 0;
	}

	#header .header-nav.header-nav-top-line nav > ul > li.active > a:before, #header .header-nav.header-nav-top-line nav > ul > li:hover > a:before {
		opacity: 1;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
		background: transparent;
		color: #444;
		padding: 35px 13px;
		margin: 0;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown li a {
		border-bottom-color: #2a2a2a;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu {
		background: #1e1e1e;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a {
		color: #969696;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:hover, #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:focus {
		background: #282828;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-title {
		color: #ababab;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
		background: #282828;
	}

	#header .header-nav.header-nav-dark-dropdown .header-social-icons {
		margin-top: 27px;
	}

	#header .header-nav.header-nav-center .header-nav-main {
		float: none;
		margin: 0;
	}

	#header .header-nav.header-nav-center .header-nav-main > nav {
		width: 100%;
		text-align: center;
	}

	#header .header-nav.header-nav-center .header-nav-main > nav > ul > li {
		display: inline-block;
		float: none;
		text-align: left;
	}

	#header .header-nav-main {
		display: block !important;
		height: auto !important;
	}

	#header .header-nav-bar {
		background: #F4F4F4;
		padding: 0 10px 5px;
		margin-bottom: 0;
	}

	#header .header-nav-bar .header-nav-main {
		float: left;
		margin-bottom: 0;
	}
}
/* Header Narrow */
@media (min-width: 992px) {
	#header.header-narrow .header-nav.header-nav-stripe nav > ul > li > a, #header.header-narrow .header-nav.header-nav-stripe nav > ul > li:hover > a, #header.header-narrow .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header.header-narrow .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
		padding: 20px 13px;
	}

	#header.header-narrow .header-nav.header-nav-top-line nav > ul > li > a, #header.header-narrow .header-nav.header-nav-top-line nav > ul > li:hover > a {
		padding: 22px 13px;
	}

	#header.header-narrow .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle:after, #header.header-narrow .header-nav.header-nav-top-line nav > ul > li:hover > a.dropdown-toggle:after {
		top: 22px;
	}
}

@media (min-width: 992px) {
	#header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
		margin-top: 1px;
	}

	html.sticky-header-active #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu,
	html.ie9 #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
		margin-top: 0px;
	}

	html.sticky-header-active.ie9 #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
		margin-top: -1px;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main.header-nav-main-light nav > ul > li > a {
		color: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
		background: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a.dropdown-toggle:after, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu {
		border-top-color: #FFF;
		box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
		border-top-color: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a {
		background: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:hover, #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:focus {
		background: #f5f5f5;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-mega-sub-nav li a {
		-webkit-transition: -webkit-transform 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out;
		transition: transform 0.2s ease-out;
		transform: translate3d(0, -5px, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover .dropdown-mega-sub-nav li a {
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out;
		transition: transform 0.2s ease-out;
		transform: translate3d(0, -5px, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(0, -5px, 0);
		opacity: 0;
	}

	#header .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu {
		transform: translate3d(0, -1px, 0);
		opacity: 1;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-3 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out;
		transition: transform 0.2s ease-out;
		transform: translate3d(0, 10px, 0);
	}

	#header .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(-20px, 0, 0);
		opacity: 0;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(-20px, 0, 0);
		opacity: 0;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(20px, 0, 0);
		left: auto;
		right: 100%;
		opacity: 0;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown.dropdown-reverse:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(-20px, 0, 0);
		opacity: 0;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
		-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transform: translate3d(20px, 0, 0);
		left: auto;
		right: 100%;
		opacity: 0;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}
/* Header Nav Main Mobile */
@media (max-width: 991px) {
	#header .header-nav-main {
		background: transparent;
		clear: both;
		float: none;
	}

	#header .header-nav-main nav {
		margin: 0 0 6px;
	}

	#header .header-nav-main nav > ul li {
		border-bottom: 1px solid #e8e8e8;
		clear: both;
		display: block;
		float: none;
		margin: 0;
		padding: 0;
		position: relative;
	}

	#header .header-nav-main nav > ul li a {
		font-size: 13px;
		font-style: normal;
		line-height: 20px;
		padding: 7px 8px;
		margin: 1px 0;
		border-radius: 4px;
	}

	#header .header-nav-main nav > ul li a .fa-caret-down {
		line-height: 35px;
		min-height: 38px;
		min-width: 30px;
		position: absolute;
		right: 5px;
		text-align: center;
		top: 0;
	}

	#header .header-nav-main nav > ul li.dropdown .dropdown-menu {
		background: transparent;
		padding: 0;
		margin: 0;
		font-size: 13px;
		box-shadow: none;
		border-radius: 0;
		border: 0;
		clear: both;
		display: none;
		float: none;
		position: static;
	}

	#header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu.opened > .dropdown-menu {
		display: block;
		margin-left: 20px;
	}

	#header .header-nav-main nav > ul li.dropdown.opened > .dropdown-menu {
		display: block;
		margin-left: 20px;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-title {
		margin-top: 10px;
		display: block;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav {
		margin: 0 0 0 20px;
		padding: 0;
		list-style: none;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		display: block;
		text-decoration: none;
	}

	#header .header-nav-main nav > ul li:last-child {
		border-bottom: 0;
	}

	#header .header-nav-main nav > ul > li > a {
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 1px;
		margin-bottom: 1px;
	}

	#header .header-nav-main nav > ul > li.active > a, #header .header-nav-main nav > ul > li.active > a:focus, #header .header-nav-main nav > ul > li.active > a:hover {
		color: #FFF;
	}

	#header .header-nav-main nav .not-included {
		margin: 0;
	}

	#header .header-nav-main a > .thumb-info-preview {
		display: none !important;
	}

	#header .header-btn-collapse-nav {
		outline: 0;
		display: block;
		position: relative;
		z-index: 1;
	}

	#header .header-nav-bar {
		margin: 0 auto;
	}

	#header .header-nav-bar .header-btn-collapse-nav {
		margin-top: 14px;
	}

	#header.header-transparent .header-nav-main {
		padding: 10px;
		margin-bottom: 10px;
		background: #FFF;
		border-radius: 4px;
	}

	#header.header-semi-transparent .header-nav-main {
		padding: 10px;
		margin-bottom: 10px;
		background: #FFF;
		border-radius: 4px;
	}

	#header.header-semi-transparent-light .header-nav-main {
		padding: 10px;
		margin-bottom: 10px;
		background: #FFF;
		border-radius: 4px;
	}

	html.sticky-header-enabled #header .header-nav-main {
		max-height: 350px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	html.sticky-header-enabled #header .header-nav-main.collapsing {
		overflow: hidden;
	}
}

@media (min-width: 992px) {
	html:not(.sticky-header-active) #header.header-transparent .header-nav-main nav > ul > li:not(.active) > a {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li:not(.active) > a {
		color: #FFF;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:not(.active) > a {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html:not(.ie) #header.header-flex .header-container {
		display: flex;
		height: 100%;
	}

	html:not(.ie) #header.header-flex .header-row {
		display: flex;
		flex-flow: row wrap;
		flex: 1;
	}

	html:not(.ie) #header.header-flex .header-column {
		display: flex;
		flex-flow: column;
	}

	html:not(.ie) #header.header-flex .header-column:nth-child(1) {
		flex: 1;
	}

	html:not(.ie) #header.header-flex .header-column:nth-child(2) {
		flex: 8;
		align-items: flex-end;
	}

	html:not(.ie) #header.header-flex .header-logo {
		display: flex;
		flex-basis: 100%;
		align-items: center;
	}

	html.ie #header.header-flex .header-top {
		margin-bottom: -10px !important;
		margin-top: -4px !important;
	}

	html.ie #header.header-flex .header-body {
		padding: 0;
	}

	html.ie #header.header-flex .header-container {
		height: 100%;
	}

	html.ie #header.header-flex .header-row {
		display: table;
		width: 100%;
		height: 100%;
	}

	html.ie #header.header-flex .header-logo {
		margin: 8px 0 !important;
	}

	html.ie #header.header-flex .header-nav {
		height: 100%;
		margin: 0;
	}

	html.ie #header.header-flex .header-nav-main {
		margin: 0;
	}

	html.ie #header.header-flex .header-nav-main nav > ul > li > a {
		margin-bottom: -1px;
	}

	html.ie9 #header.header-flex .header-nav-main nav > ul > li > a, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a {
		position: relative;
	}

	html.ie9 #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a.dropdown-toggle {
		padding-right: 25px;
	}

	html.ie9 #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle:after, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		position: absolute;
		top: 50% !important;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	html #header.header-flex .header-top {
		margin-bottom: -2px;
	}

	html #header.header-flex .header-body {
		padding: 8px 0 0 0;
	}

	html #header.header-flex:not(.header-transparent-bottom-border) .header-body {
		border-bottom: 0 !important;
	}

	html #header.header-flex .header-logo {
		margin: 0 0 8px;
	}

	html #header.header-flex .header-nav-main,
	html #header.header-flex .header-nav-main nav,
	html #header.header-flex .header-nav-main nav > ul,
	html #header.header-flex .header-nav-main nav > ul > li {
		height: 100% !important;
	}

	html #header.header-flex .header-nav {
		margin-top: -9px;
	}

	html #header.header-flex .header-nav.header-nav-stripe nav > ul > li > a, html #header.header-flex .header-nav.header-nav-top-line nav > ul > li > a, html #header.header-flex .header-nav.header-nav-dark-dropdown nav > ul > li > a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	html #header.header-flex .header-nav.header-nav-stripe nav > ul > li > a.dropdown-toggle, html #header.header-flex .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle, html #header.header-flex .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle {
		height: calc(100% + 1px);
	}

	html #header.header-flex .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu,
	html #header.header-flex .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu,
	html #header.header-flex .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
		top: calc(100% + 1px);
	}

	html #header.header-flex .header-nav {
		display: flex !important;
		align-items: center;
		justify-content: flex-end;
	}

	html #header.header-flex .header-nav .header-social-icons {
		margin: 0 0 0 10px;
		order: 2;
	}

	html #header.header-flex .header-nav .header-social-icons li {
		margin: -1px 1px 0px 0px;
	}

	html #header.header-flex .header-nav-main {
		margin: 0 !important;
	}

	html #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		margin-top: 3px;
	}

	html #header.header-flex .header-nav-main.header-nav-main-square {
		margin-bottom: 0;
	}

	html #header.header-flex.header-semi-transparent .header-nav.header-nav-top-line nav > ul > li:hover, html #header.header-flex.header-semi-transparent .header-nav.header-nav-top-line nav > ul > li > a:before, html #header.header-flex.header-semi-transparent-light .header-nav.header-nav-top-line nav > ul > li:hover, html #header.header-flex.header-semi-transparent-light .header-nav.header-nav-top-line nav > ul > li > a:before {
		top: 0;
	}

	html.boxed #header.header-flex .header-nav.header-nav-stripe, html.boxed #header.header-flex .header-nav.header-nav-dark-dropdown {
		margin-top: -12px;
	}

	html.boxed.ie9 #header.header-flex .header-nav.header-nav-stripe, html.boxed.ie9 #header.header-flex .header-nav.header-nav-dark-dropdown {
		margin-top: 0 !important;
	}
}
/* Responsive */
@media (max-width: 991px) {
	#header .header-container {
		display: block;
	}

	#header .header-row {
		display: block;
	}

	#header .header-column {
		display: block;
	}

	#header .header-column .header-row {
		display: block;
	}

	#header.header-mobile-nav-only .header-logo {
		float: none;
	}

	#header.header-mobile-nav-only .header-nav {
		margin-top: -35px;
	}

	html.sticky-header-negative.sticky-header-active #header .header-logo {
		padding-top: 7px;
	}
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
	width: 40%;
	right: 15px;
	left: auto;
	border-radius: 6px 0 6px 6px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu a {
	transform: translate3d(0, 0, 0);
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .fa-user {
	margin-right: 5px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-mega-sub-title {
	margin-bottom: 10px;
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .sign-up-info, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .log-in-info {
	text-align: center;
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signin-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signup-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .recover-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signin-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signup-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .recover-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signin-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signup-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .recover-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail {
	float: left;
	margin-right: 15px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail img {
	max-width: 55px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar strong {
	display: block;
	padding-top: 7px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar span {
	display: block;
	font-size: 12px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar p {
	margin: 0 0 0 25px;
	text-align: left;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
	list-style: none;
	margin: 6px 0 0;
	padding: 0;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options a:hover {
	text-decoration: none;
}

/* Responsive */
@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin {
		margin-right: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-toggle {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
		display: block !important;
		margin: 0 !important;
		padding: 0 10px 0 10px;
		width: 100%;
		right: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu .dropdown-mega-sub-title {
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		padding-top: 5px;
		padding-bottom: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-toggle {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-mega-content {
		margin: 15px 0 !important;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
		margin: 15px 0 0 0 !important;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options > li > a {
		line-height: 35px;
	}
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
	width: 40%;
	right: 15px;
	left: auto;
	border-radius: 6px 0 6px 6px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu a {
	transform: translate3d(0, 0, 0);
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table {
	width: 100%;
	margin-top: 0;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a {
	background-color: transparent !important;
	color: #333;
	padding: 0;
	margin: 0;
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a.remove {
	float: right;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a:hover {
	text-decoration: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .fa-shopping-cart {
	margin-right: 5px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-thumbnail {
	width: 120px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-actions {
	text-align: right;
	width: 80px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue {
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid #DDD;
	clear: both;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue .btn {
	margin-bottom: 10px;
}

/* Responsive */
@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
		margin: 15px 0;
		padding: 0 15px;
		width: 100%;
	}
}

html.side-header #header {
	min-height: 0 !important;
}

@media (min-width: 992px) {
	html.side-header .main .container, html.side-header #footer .container {
		padding: 0 35px;
		width: auto !important;
		max-width: 1210px;
	}

	html.side-header body > .body {
		margin: 0 0 0 255px;
		width: auto;
		overflow-x: hidden;
		overflow-y: visible;
	}

	html.side-header body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
		width: 100% !important;
		left: auto !important;
	}

	html.side-header #header {
		background: #FFF;
		position: fixed;
		box-shadow: 0 0 18px rgba(0, 0, 0, 0.07);
		top: 0;
		left: 0;
		width: 255px;
		height: 100%;
	}

	html.side-header #header .header-body {
		border-top: 0;
	}

	html.side-header #header .header-container {
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
	}

	html.side-header #header .header-search {
		margin: 5px 0 10px;
		float: none;
	}

	html.side-header #header .header-search .input-group {
		width: 100%;
	}

	html.side-header #header .header-search .input-group .form-control {
		width: 100%;
	}

	html.side-header #header .header-nav-top {
		margin: 0;
		float: none;
	}

	html.side-header #header .header-nav-top .nav > li > a, html.side-header #header .header-nav-top .nav > li > span {
		display: block;
	}

	html.side-header #header .header-logo {
		margin: 0;
		float: none;
		text-align: center;
		width: auto !important;
		height: auto !important;
		padding: 10px 0;
	}

	html.side-header #header .header-column {
		display: block;
	}

	html.side-header #header .header-column .header-row {
		clear: both;
	}

	html.side-header #header .header-nav-main {
		float: none;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
		content: "";
		border-color: transparent transparent transparent #CCC;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > a, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > a {
		padding-bottom: 10px;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		top: 3px;
		left: 100%;
		border-top: 0;
		border-left: 5px solid #CCC;
		margin-left: -5px;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown li.dropdown-submenu:hover > .dropdown-menu {
		margin-top: -5px;
		border-top: 0;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-mega {
		position: relative;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-menu {
		min-width: 720px;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li a {
		padding-right: 8px;
		padding-left: 20px;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li.dropdown-submenu > a:after {
		border-width: 4px 4px 4px 0;
	}

	html.side-header #header .header-nav-main nav > ul > li > a {
		display: block;
	}

	html.side-header #header .nav-pills > li {
		float: none;
		margin: 0 0 1px 0;
	}

	html.side-header #header .header-social-icons {
		float: none;
		text-align: center;
		margin-top: 25px;
	}

	html.side-header-right body > .body {
		margin: 0 255px 0 0;
	}

	html.side-header-right #header {
		left: auto;
		right: 0;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle {
		padding-right: 5px;
		padding-left: 5px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
		float: left;
		margin-right: 9px;
		margin-top: 6px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-right #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		right: 100%;
		left: auto;
		border-right: 5px solid #CCC;
		border-left: 0;
		margin-left: 0;
		margin-right: -5px;
		border-radius: 4px 0 0 4px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		left: 10px;
		right: initial;
	}

	html.side-header-semi-transparent body > .body {
		margin: 0;
	}

	html.side-header-semi-transparent #header {
		background: rgba(0, 0, 0, 0.3);
	}

	html.side-header-semi-transparent #header .header-body {
		background: transparent !important;
	}
}

@media (max-height: 768px) {
	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		bottom: 0;
		top: auto;
	}
}

html[dir="rtl"] #header .header-search .btn-default {
	right: -35px;
}

/* Page Header */
.page-header {
	background-color: #171717;
	border-bottom: 5px solid #CCC;
	border-top: 5px solid #384045;
	margin: 0 0 35px 0;
	min-height: 50px;
	padding: 20px 0;
	position: relative;
	text-align: left;
}

.page-header .breadcrumb {
	background: none;
	margin: 0 0 2px 2px;
	padding: 0;
	position: relative;
	z-index: 1;
}

.page-header .breadcrumb > li {
	display: inline-block;
	font-size: 0.85em;
	text-transform: uppercase;
	text-shadow: none;
}

.page-header .breadcrumb > li + li:before {
	color: inherit;
	opacity: 0.5;
	font-family: FontAwesome;
	content: "\f105";
	padding: 0 7px 0 5px;
}

.page-header .breadcrumb.breadcrumb-valign-mid {
	position: absolute;
	top: 36%;
	right: 20px;
}

.page-header h1 {
	border-bottom: 5px solid #CCC;
	color: #fff;
	display: inline-block;
	font-size: 32px;
	font-weight: 200;
	line-height: 32px;
	margin: 0 0 -25px;
	padding: 0 0 25px;
	position: relative;
}

.page-header h1 span {
	color: #999da3;
	display: block;
	font-size: 0.6em;
	font-weight: 300;
	line-height: 1.3em;
	margin: 7px 0 0;
}

.page-header-no-title-border h1 {
	border: 0;
}

/* Page Header - Color */
.page-header-color {
	background-color: #F7F7F7;
	border-bottom: 5px solid #F4F4F4;
	border-top: none;
}

.page-header-color h1 {
	border-bottom: none;
	color: #1d2127;
	font-size: 2.3em;
	padding: 0;
	margin: 0;
}

.page-header-color h1 span {
	opacity: 0.65;
}

.page-header-color .breadcrumb > .active {
	opacity: 0.65;
}

/* Page Header - More Padding */
.page-header-more-padding {
	padding: 35px 0;
}

/* Page Header - Light */
.page-header-light {
	background-color: #F7F7F7;
	border-bottom: 5px solid #F4F4F4;
	border-top: none;
}

.page-header-light h1 {
	border-bottom: none;
	color: #1d2127;
	font-size: 2.3em;
}

/* Page Header - Reverse */
.page-header-reverse {
	text-align: right;
}

.page-header-reverse .breadcrumb, .page-header-reverse .breadcrumb.breadcrumb-valign-mid {
	float: left;
	left: 20px;
	right: auto;
}

/* Page Header - Parallax */
.page-header.parallax {
	margin: 0 0 35px;
	padding: 180px 0 60px;
}

.page-header-center {
	text-align: center;
}

.page-header-center .breadcrumb {
	margin: 15px 0 0;
}

/* Page Header Custom Background */
.page-header-custom-background {
	padding: 45px 0;
	background-repeat: repeat;
	background-color: transparent;
	border-top: 0;
	border-bottom: 0;
}

.page-header-custom-background h1 {
	color: #FFF;
	border: 0;
}

.page-header-custom-background h1 span {
	color: #FFF;
	opacity: 0.7;
}

.page-header-custom-background .breadcrumb.breadcrumb-valign-mid {
	top: 36%;
}

.page-header-custom-background .breadcrumb.breadcrumb-valign-mid li.active {
	color: #FFF;
	opacity: 0.7;
}

.page-header-custom-background .breadcrumb.breadcrumb-valign-mid a {
	color: #FFF;
}

/* Page Header Custom - Create your Own */
.page-header.custom-product {
	background-image: url(../img/custom-header-bg.jpg);
	background-repeat: repeat;
	background-color: #999;
	border-top: 5px solid #888;
	overflow: hidden;
}

.page-header.custom-product .row {
	position: relative;
}

.page-header.custom-product p.lead {
	color: #E8E8E8;
}

.page-header.custom-product img {
	bottom: -47px;
	position: relative;
}

.page-header.custom-product h1 {
	color: #FFF;
	margin-bottom: 10px;
	margin-top: 30px;
	border: 0;
}

/* Responsive */
@media (min-width: 992px) {
	.page-header-more-padding h1,
	.page-header-custom-background h1,
	.page-header-float-breadcrumb h1 {
		max-width: 70%;
	}

	.page-header-more-padding .breadcrumb-valign-mid,
	.page-header-custom-background .breadcrumb-valign-mid,
	.page-header-float-breadcrumb .breadcrumb-valign-mid {
		width: 30%;
		text-align: right;
	}

	.page-header-more-padding.page-header-reverse .breadcrumb-valign-mid,
	.page-header-custom-background.page-header-reverse .breadcrumb-valign-mid,
	.page-header-float-breadcrumb.page-header-reverse .breadcrumb-valign-mid {
		text-align: left;
	}
}

@media (max-width: 991px) {
	.page-header .breadcrumb {
		float: none !important;
		position: static !important;
	}

	.page-header .breadcrumb-valign-mid {
		margin-top: 15px;
	}

	.page-header-reverse .breadcrumb-valign-mid {
		margin-top: 0;
		margin-bottom: 15px;
	}
}

@media (max-width: 479px) {
	.page-header h1 {
		font-size: 26px;
		line-height: 26px;
	}
}
/* Parallax */
.parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	margin: 70px 0;
	padding: 50px 0;
	position: relative;
	clear: both;
}

.parallax blockquote {
	border: 0;
	padding: 0 10%;
}

.parallax blockquote i.fa-quote-left {
	font-size: 34px;
	position: relative;
	left: -5px;
}

.parallax blockquote p {
	font-size: 24px;
	line-height: 30px;
}

.parallax blockquote span {
	font-size: 16px;
	line-height: 20px;
	color: #999;
	position: relative;
	left: -5px;
}

.parallax.parallax-disabled {
	background-attachment: scroll !important;
	background-position: 50% 50% !important;
}

/* Section Featured */
section.section {
	background: #f4f4f4;
	border-top: 5px solid #f1f1f1;
	margin: 30px 0;
	padding: 50px 0;
}

section.section.section-no-background {
	background: transparent;
	border-top-color: transparent;
}

section.section.section-center {
	text-align: center;
}

section.section.section-text-light h1, section.section.section-text-light h2, section.section.section-text-light h3, section.section.section-text-light h4, section.section.section-text-light h5, section.section.section-text-light h6 {
	color: #FFF;
}

section.section.section-text-light p {
	color: #e6e6e6;
}

section.section.section-background {
	background-repeat: repeat;
	border: 0;
}

section.section.section-overlay {
	position: relative;
}

section.section.section-overlay:before {
	content: "";
	display: block;
	background: url("../img/video-overlay.png") repeat scroll 0 0 transparent;
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay-opacity {
	position: relative;
}

section.section.section-overlay-opacity:before {
	content: "";
	display: block;
	background: rgba(0, 0, 0, 0.5);
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
	background: rgba(0, 0, 0, 0.1);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
	background: rgba(0, 0, 0, 0.2);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
	background: rgba(0, 0, 0, 0.3);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
	background: rgba(0, 0, 0, 0.4);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
	background: rgba(0, 0, 0, 0.5);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
	background: rgba(0, 0, 0, 0.6);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
	background: rgba(0, 0, 0, 0.7);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
	background: rgba(0, 0, 0, 0.8);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
	background: rgba(0, 0, 0, 0.9);
}

section.section.section-overlay-opacity-light:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
	background: rgba(255, 255, 255, 0.1);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
	background: rgba(255, 255, 255, 0.2);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
	background: rgba(255, 255, 255, 0.3);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
	background: rgba(255, 255, 255, 0.4);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
	background: rgba(255, 255, 255, 0.6);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
	background: rgba(255, 255, 255, 0.7);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
	background: rgba(255, 255, 255, 0.8);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
	background: rgba(255, 255, 255, 0.9);
}

section.section.section-default-scale-lighten {
	background-color: #fcfcfc !important;
	border-top-color: #fcfcfc !important;
}

section.section.section-video {
	background: none !important;
	border: 0;
}

section.section.section-parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	border: 0;
}

section.section.section-parallax.parallax-disabled {
	background-attachment: scroll !important;
	background-position: 50% 50% !important;
}

section.section.section-no-border {
	border: 0;
}

section.section.section-with-divider {
	margin: 56px 0 35px;
}

section.section.section-with-divider .divider {
	margin: -56px 0 44px;
}

section.section.section-footer {
	margin-bottom: -50px;
}

section.section.section-with-mockup {
	margin-top: 120px;
}

section.section.section-front {
	position: relative;
	z-index: 1;
}

/* Responsive */
@media (max-width: 991px) {
	.container-fluid > .row > .p-none section.section {
		margin-bottom: 0;
		margin-top: 0;
	}

	.container-fluid > .row > .p-none:first-child section.section {
		margin-bottom: 0;
		margin-top: 30px;
	}

	.container-fluid > .row > .p-none:last-child section.section {
		margin-bottom: 30px;
		margin-top: 0;
	}
}
/* Sidebar */
aside ul.nav-list > li > a {
	color: #666;
	font-size: 0.9em;
	padding: 8px 0 8px 18px;
}

/* Home Slider - Revolution Slider */
.slider-container {
	background: #171717;
	height: 500px;
	overflow: hidden;
	width: 100%;
	direction: ltr;
}

.slider-container .tparrows {
	border-radius: 6px;
}

.slider-container .slider-single-slide .tparrows {
	display: none;
}

.slider-container .top-label {
	color: #FFF;
	font-size: 24px;
	font-weight: 300;
}

.slider-container .main-label {
	color: #FFF;
	font-size: 62px;
	line-height: 62px;
	font-weight: 800;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.slider-container .featured-label {
	color: #FFF;
	font-size: 52px;
	line-height: 52px;
	font-weight: 800;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.slider-container .bottom-label {
	color: #FFF;
	font-size: 20px;
	line-height: 20px;
	font-weight: 300;
}

.slider-container .blackboard-text {
	color: #BAB9BE;
	font-size: 46px;
	line-height: 46px;
	font-family: "Shadows Into Light", cursive;
	text-transform: uppercase;
}

.slider-container .btn-slider-action {
	font-size: 22px;
	font-weight: 600;
	line-height: 20px;
	padding: 20px 25px;
}

.slider-container .top-label-dark, .slider-container .main-label-dark, .slider-container .bottom-label-dark {
	color: #333;
}

.slider-container .tp-caption-dark a {
	color: #333;
}

.slider-container .tp-caption-dark a:hover {
	color: #333;
}

.slider-container .tp-caption-overlay {
	background: #000;
	padding: 10px;
}

.slider-container .tp-caption-overlay-opacity {
	background: rgba(0, 0, 0, 0.4);
	padding: 10px;
}

.slider-container .tp-opacity-overlay {
	background: rgba(0, 0, 0, 0.75);
}

.slider-container.transparent {
	background-color: transparent;
}

.slider-container.light {
	background-color: #EAEAEA;
}

.slider-container.light .tp-bannershadow {
	opacity: 0.05;
	top: 0;
	bottom: auto;
	z-index: 1000;
}

.slider-container.light .featured-label, .slider-container.light .top-label, .slider-container.light .main-label, .slider-container.light .bottom-label {
	color: #333;
}

.slider-container.light .tp-caption a {
	color: #333;
}

.slider-container.light .tp-caption a:hover {
	color: #333;
}

/* Arrows */
.tparrows.tparrows-carousel {
	display: inline-block;
	position: absolute;
	width: 30px;
	height: auto;
	padding: 20px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow {
	right: auto !important;
	left: -30px !important;
	border-radius: 0 4px 4px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow:before {
	font-family: FontAwesome;
	content: "\f053";
	position: relative;
	left: -1px;
	top: 1px;
}

.tparrows.tparrows-carousel.tp-rightarrow {
	right: -60px !important;
	left: auto !important;
	border-radius: 4px 0 0 4px;
}

.tparrows.tparrows-carousel.tp-rightarrow:before {
	font-family: FontAwesome;
	content: "\f054";
	position: relative;
	left: 2px;
	top: 1px;
}

/* Slider IE Fix */
html.ie .forcefullwidth_wrapper_tp_banner {
	overflow: hidden;
}

/* Embed Border */
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
	border: 0 !important;
}

/* Defaults */
.hesperiden-custom {
	padding: 10px 0 !important;
}

.hesperiden-custom .tp-tab {
	border: 0 !important;
}

/* Slider With Overlay */
.slider-with-overlay {
	position: relative;
}

.slider-with-overlay .slider-container {
	z-index: auto;
}

/* Slider Contact Form */
.slider-contact-form {
	margin: -45px 0 25px;
}

.slider-contact-form .featured-box {
	text-align: left;
}

.slider-contact-form label.error {
	display: none !important;
}

.slider-contact-form .alert {
	padding: 6px;
	text-align: center;
}

.slider-contact-form textarea {
	resize: none;
}

/* Responsive */
@media (min-width: 992px) {
	.slider-contact-form {
		margin: 0;
		position: absolute;
		top: 20px;
		z-index: 99;
		width: 100%;
	}
}
/* Boxed */
html.boxed .slider-container {
	width: 100% !important;
	left: auto !important;
}

/* Home Slider - Nivo Slider */
.nivo-slider {
	position: relative;
}

.nivo-slider .theme-default .nivoSlider {
	border-radius: 10px 10px 0 0;
	box-shadow: none;
	margin-top: 10px;
}

.nivo-slider .theme-default .nivoSlider img {
	border-radius: 10px 10px 0 0;
}

.nivo-slider .theme-default .nivo-controlNav {
	margin-top: -72px;
	position: absolute;
	z-index: 99;
	right: 5px;
	bottom: -17px;
}

.nivo-slider .theme-default .nivo-caption {
	padding-left: 20px;
}

/* Custom Map */
section.section-custom-map {
	background: transparent url(../img/map.png) center 0 no-repeat;
	padding: 129px 0 0;
	margin: 20px 0 0;
	border: 0;
}

section.section-custom-map section.section {
	border-top-color: rgba(241, 241, 241, 0.8);
	background: rgba(244, 244, 244, 0.8);
}

section.section-custom-map-2 {
	background: transparent url(../img/map-2.png) center 0 no-repeat;
	padding: 129px 0 0;
	margin: 50px 0 0;
	border: 0;
}

section.section-custom-map-2 section.section {
	border-top-color: rgba(241, 241, 241, 0.8);
	background: rgba(244, 244, 244, 0.8);
}

/* Home Intro */
.home-intro {
	background-color: #171717;
	margin-bottom: 60px;
	overflow: hidden;
	padding: 20px 0 10px 0;
	position: relative;
	text-align: left;
}

.home-intro p {
	color: #FFF;
	display: inline-block;
	font-size: 1.4em;
	font-weight: 300;
	max-width: 800px;
	padding-top: 5px;
}

.home-intro p span {
	color: #999;
	display: block;
	font-size: 0.8em;
	padding-top: 5px;
}

.home-intro p em {
	font-family: "Shadows Into Light", cursive;
	font-size: 1.6em;
}

.home-intro .get-started {
	margin-top: 15px;
	margin-bottom: 15px;
	position: relative;
	text-align: right;
}

.home-intro .get-started .btn {
	position: relative;
	z-index: 1;
}

.home-intro .get-started .btn i {
	font-size: 1.1em;
	font-weight: normal;
	margin-left: 5px;
}

.home-intro .get-started a {
	color: #FFF;
}

.home-intro .get-started a:not(.btn) {
	color: #777;
}

.home-intro .learn-more {
	margin-left: 15px;
}

.home-intro.light {
	background-color: #E2E2E2;
}

.home-intro.light.secondary {
	background-color: #E0E1E4;
}

.home-intro.light p {
	color: #777;
}

/* Home Intro Compact */
.home-intro-compact {
	border-radius: 0 0 10px 10px;
	margin-top: -10px;
}

.home-intro-compact p {
	padding-left: 20px;
}

.home-intro-compact .get-started {
	padding-right: 20px;
}

/* Responsive */
@media (max-width: 767px) {
	/* Home Intro */
	.home-intro-compact {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}
/* Home Concept SVG Animations */
@keyframes animatedLineBg {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1360;
	}
}

@keyframes animatedLineSmallCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1120;
	}
}

@keyframes animatedLineBigCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1266;
	}
}

@keyframes animatedInitialPin {
	0% {
		-webkit-transform: translate(2px, 69px) scale(0.23);
		-moz-transform: translate(2px, 69px) scale(0.23);
		-ms-transform: translate(2px, 69px) scale(0.23);
		-o-transform: translate(2px, 69px) scale(0.23);
		transform: translate(2px, 69px) scale(0.23);
	}

	33% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		-moz-transform: translate(2px, 79px) scale(0.23);
		-ms-transform: translate(2px, 79px) scale(0.23);
		-o-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}

	66% {
		-webkit-transform: translate(2px, 74px) scale(0.23);
		-moz-transform: translate(2px, 74px) scale(0.23);
		-ms-transform: translate(2px, 74px) scale(0.23);
		-o-transform: translate(2px, 74px) scale(0.23);
		transform: translate(2px, 74px) scale(0.23);
	}

	100% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		-moz-transform: translate(2px, 79px) scale(0.23);
		-ms-transform: translate(2px, 79px) scale(0.23);
		-o-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}
}

@keyframes animatedFinalPin {
	0% {
		-webkit-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		-moz-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		-ms-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		-o-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		transform: translate(325px, -19px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		-moz-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		-ms-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		-o-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		transform: translate(325px, -16px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		-o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}
}
/* Firefox Final Pin Animation */
@keyframes animatedFinalPinFirefox {
	0% {
		-webkit-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		-moz-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		-ms-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		-o-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		transform: translate(193px, -16px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-moz-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-ms-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-o-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		-moz-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		-ms-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		-o-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		transform: translate(193px, -13px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-moz-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-ms-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		-o-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}
}
/* Home Concept */
.home-concept {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.home-concept svg.home-concept-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	max-width: 1440px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -60%);
	-moz-transform: translate(-50%, -60%);
	-ms-transform: translate(-50%, -60%);
	-o-transform: translate(-50%, -60%);
	transform: translate(-50%, -60%);
}

.home-concept svg.home-concept-bg .line {
	stroke-dashoffset: 1000;
}

.home-concept svg.home-concept-bg .line.animated-line {
	animation-name: animatedLineBg;
	animation-timing-function: linear;
}

.home-concept svg.home-concept-bg .initial-pin {
	-webkit-transform: translate(2px, 79px) scale(0.23);
	-moz-transform: translate(2px, 79px) scale(0.23);
	-ms-transform: translate(2px, 79px) scale(0.23);
	-o-transform: translate(2px, 79px) scale(0.23);
	transform: translate(2px, 79px) scale(0.23);
}

.home-concept svg.home-concept-bg .initial-pin.animated-pin {
	animation-name: animatedInitialPin;
}

.home-concept svg.home-concept-bg .final-pin {
	-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	-moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	-ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	-o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	-webkit-transform-origin: center;
	-moz-transform-origin: center;
	-ms-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
}

.home-concept svg.home-concept-bg .final-pin.animated-pin {
	animation-name: animatedFinalPin;
}

.home-concept strong {
	display: block;
	font-family: "Shadows Into Light", cursive;
	font-size: 2.1em;
	font-weight: normal;
	position: relative;
	margin-top: 30px;
}

.home-concept .row {
	position: relative;
}

.home-concept .process-image {
	width: 160px;
	margin: 135px auto 0 auto;
	padding-bottom: 50px;
	position: relative;
	z-index: 1;
}

.home-concept .process-image .box-image {
	position: relative;
}

.home-concept .process-image .box-image .small-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.home-concept .process-image .box-image .small-circle .line {
	stroke-dashoffset: 1000;
	-webkit-transform: rotate(168deg);
	-moz-transform: rotate(168deg);
	-ms-transform: rotate(168deg);
	-o-transform: rotate(168deg);
	transform: rotate(168deg);
	-webkit-transform-origin: center;
	-moz-transform-origin: center;
	-ms-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
}

.home-concept .process-image .box-image .small-circle .line.animated-line {
	animation-name: animatedLineSmallCircle;
	animation-timing-function: linear;
}

.home-concept .process-image .box-image img {
	position: relative;
	border-radius: 150px;
	margin: 7px 8px;
	width: auto;
	height: auto;
	max-width: 145px;
	max-height: 145px;
	z-index: 1;
}

.home-concept .our-work {
	margin-top: 52px;
	font-size: 2.6em;
}

.home-concept .project-image {
	width: 350px;
	margin: 15px 0 0 -30px;
	padding-bottom: 45px;
	position: relative;
	z-index: 1;
}

.home-concept .project-image .box-image {
	position: relative;
}

.home-concept .project-image .box-image .big-circle {
	position: absolute;
	top: calc(50% + 6px);
	left: 50%;
	width: 106%;
	height: 106%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.home-concept .project-image .box-image .big-circle .line {
	stroke-dashoffset: 1000;
	-webkit-transform: rotate(171deg);
	-moz-transform: rotate(171deg);
	-ms-transform: rotate(171deg);
	-o-transform: rotate(171deg);
	transform: rotate(171deg);
	-webkit-transform-origin: center;
	-moz-transform-origin: center;
	-ms-transform-origin: center;
	-o-transform-origin: center;
	transform-origin: center;
}

.home-concept .project-image .box-image .big-circle .line.animated-line {
	animation-name: animatedLineBigCircle;
	animation-timing-function: linear;
}

.home-concept .sun {
	background: transparent url(../img/home-concept-icons.png) no-repeat 0 0;
	width: 60px;
	height: 56px;
	display: block;
	position: absolute;
	left: 10%;
	top: 35px;
}

.home-concept .cloud {
	background: transparent url(../img/home-concept-icons.png) no-repeat 100% 0;
	width: 116px;
	height: 56px;
	display: block;
	position: absolute;
	left: 57%;
	top: 35px;
}

/* Home Concept - Firefox Adjusts */
html.gecko .home-concept svg.home-concept-bg .final-pin.animated-pin {
	animation-name: animatedFinalPinFirefox;
}

/* Home Concept - Internet Explorer Adjusts */
html.ie .home-concept {
	background: transparent url(../img/home-concept.png) no-repeat center 0;
}

html.ie .home-concept svg {
	display: none;
}

html.ie .home-concept .process-image {
	background: transparent url(../img/home-concept-item.png) no-repeat 0 0;
}

html.ie .home-concept .project-image {
	background: transparent url(../img/home-concept-item.png) no-repeat 100% 0;
}

/* Responsive */
@media (max-width: 991px) {
	.home-concept {
		background: none;
	}

	.home-concept svg.home-concept-bg {
		display: none;
	}

	.home-concept .project-image {
		margin: 20px auto 0 auto;
	}

	.home-concept .process-image {
		margin-top: 0;
		padding-bottom: 25px;
	}
}

@media (max-width: 479px) {
	.home-concept .project-image {
		background-position: 0 0;
		margin-left: auto;
		max-width: 160px;
	}

	.home-concept .fc-slideshow {
		max-width: 145px;
		height: 145px;
	}

	.home-concept .fc-slideshow img {
		max-height: 145px;
		max-width: 145px;
	}
}
/* Page 404 */
.page-not-found {
	margin: 50px 0;
}

.page-not-found h2 {
	font-size: 140px;
	font-weight: 600;
	letter-spacing: -10px;
	line-height: 140px;
}

.page-not-found h4 {
	color: #777;
}

.page-not-found p {
	font-size: 1.4em;
	line-height: 36px;
}

/* Responsive */
@media (max-width: 479px) {
	.page-not-found {
		margin: 0;
	}

	.page-not-found h2 {
		font-size: 100px;
		letter-spacing: 0;
		line-height: 100px;
	}
}

.text-xs {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 1.3rem !important;
}

.text-md {
	font-size: 1.6rem !important;
}

.text-lg {
	font-size: 1.9rem !important;
}

.text-xl {
	font-size: 2.2rem !important;
}

.text-muted {
	color: #999 !important;
}

html.dark .text-muted {
	color: #505461 !important;
}

.text-dark {
	color: #1d2127 !important;
}

.text-light {
	color: #FFF !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-extra-bold {
	font-weight: 900 !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.rounded {
	border-radius: 5px !important;
}

.b-thin {
	border-width: 3px !important;
}

.b-normal {
	border-width: 5px !important;
}

.b-thick {
	border-width: 7px !important;
}

/* Spacements */
/* spacement top & bottom */
.m-none {
	margin: 0 !important;
}

.m-auto {
	margin: 0 auto !important;
}

.m-xs {
	margin: 5px !important;
}

.m-sm {
	margin: 10px !important;
}

.m-md {
	margin: 15px !important;
}

.m-lg {
	margin: 20px !important;
}

.m-xl {
	margin: 25px !important;
}

.m-xlg {
	margin: 30px !important;
}

/* spacement top	*/
.mt-none {
	margin-top: 0 !important;
}

.mt-xs {
	margin-top: 5px !important;
}

.mt-sm {
	margin-top: 10px !important;
}

.mt-md {
	margin-top: 15px !important;
}

.mt-lg {
	margin-top: 20px !important;
}

.mt-xl {
	margin-top: 25px !important;
}

.mt-xlg {
	margin-top: 30px !important;
}

/* spacement bottom	*/
.mb-none {
	margin-bottom: 0 !important;
}

.mb-xs {
	margin-bottom: 5px !important;
}

.mb-sm {
	margin-bottom: 10px !important;
}

.mb-md {
	margin-bottom: 15px !important;
}

.mb-lg {
	margin-bottom: 20px !important;
}

.mb-xl {
	margin-bottom: 25px !important;
}

.mb-xlg {
	margin-bottom: 30px !important;
}

/* spacement left	*/
.ml-none {
	margin-left: 0 !important;
}

.ml-xs {
	margin-left: 5px !important;
}

.ml-sm {
	margin-left: 10px !important;
}

.ml-md {
	margin-left: 15px !important;
}

.ml-lg {
	margin-left: 20px !important;
}

.ml-xl {
	margin-left: 25px !important;
}

.ml-xlg {
	margin-left: 30px !important;
}

/* spacement right	*/
.mr-none {
	margin-right: 0 !important;
}

.mr-xs {
	margin-right: 5px !important;
}

.mr-sm {
	margin-right: 10px !important;
}

.mr-md {
	margin-right: 15px !important;
}

.mr-lg {
	margin-right: 20px !important;
}

.mr-xl {
	margin-right: 25px !important;
}

.mr-xlg {
	margin-right: 30px !important;
}

/* Spacement Padding */
.p-none {
	padding: 0 !important;
}

.p-xs {
	padding: 5px !important;
}

.p-sm {
	padding: 10px !important;
}

.p-md {
	padding: 15px !important;
}

.p-lg {
	padding: 20px !important;
}

.p-xl {
	padding: 25px !important;
}

.p-xlg {
	padding: 30px !important;
}

/* spacement top	*/
.pt-none {
	padding-top: 0 !important;
}

.pt-xs {
	padding-top: 5px !important;
}

.pt-sm {
	padding-top: 10px !important;
}

.pt-md {
	padding-top: 15px !important;
}

.pt-lg {
	padding-top: 20px !important;
}

.pt-xl {
	padding-top: 25px !important;
}

.pt-xlg {
	padding-top: 30px !important;
}

/* spacement bottom	*/
.pb-none {
	padding-bottom: 0 !important;
}

.pb-xs {
	padding-bottom: 5px !important;
}

.pb-sm {
	padding-bottom: 10px !important;
}

.pb-md {
	padding-bottom: 15px !important;
}

.pb-lg {
	padding-bottom: 20px !important;
}

.pb-xl {
	padding-bottom: 25px !important;
}

.pb-xlg {
	padding-bottom: 30px !important;
}

/* spacement left	*/
.pl-none {
	padding-left: 0 !important;
}

.pl-xs {
	padding-left: 5px !important;
}

.pl-sm {
	padding-left: 10px !important;
}

.pl-md {
	padding-left: 15px !important;
}

.pl-lg {
	padding-left: 20px !important;
}

.pl-xl {
	padding-left: 25px !important;
}

.pl-xlg {
	padding-left: 30px !important;
}

/* spacement right	*/
.pr-none {
	padding-right: 0 !important;
}

.pr-xs {
	padding-right: 5px !important;
}

.pr-sm {
	padding-right: 10px !important;
}

.pr-md {
	padding-right: 15px !important;
}

.pr-lg {
	padding-right: 20px !important;
}

.pr-xl {
	padding-right: 25px !important;
}

.pr-xlg {
	padding-right: 30px !important;
}

.ib {
	display: inline-block;
	vertical-align: top;
}

.va-middle {
	vertical-align: middle;
}

.ws-nowrap {
	white-space: nowrap;
}

.ws-normal {
	white-space: normal;
}

.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5, .col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5, .col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5, .col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-1-5 {
	width: 20%;
	float: left;
}

.col-xs-2-5 {
	width: 40%;
	float: left;
}

.col-xs-3-5 {
	width: 60%;
	float: left;
}

.col-xs-4-5 {
	width: 80%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-1-5 {
		width: 20%;
		float: left;
	}

	col-sm-2-5 {
		width: 40%;
		float: left;
	}

	col-sm-3-5 {
		width: 60%;
		float: left;
	}

	col-sm-4-5 {
		width: 80%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-1-5 {
		width: 20%;
		float: left;
	}

	.col-md-2-5 {
		width: 40%;
		float: left;
	}

	.col-md-3-5 {
		width: 60%;
		float: left;
	}

	.col-md-4-5 {
		width: 80%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-1-5 {
		width: 20%;
		float: left;
	}

	.col-lg-2-5 {
		width: 40%;
		float: left;
	}

	.col-lg-3-5 {
		width: 60%;
		float: left;
	}

	.col-lg-4-5 {
		width: 80%;
		float: left;
	}
}

@media (max-width: 991px) {
	.col-md-3 .sidebar {
		clear: both;
	}
}
/* #Footer */
#footer {
	background: #0e0e0e;
	border-top: 4px solid #0e0e0e;
	font-size: 0.9em;
	margin-top: 50px;
	padding: 70px 0 0;
	position: relative;
	clear: both;
}

#footer .container .row > div {
	margin-bottom: 25px;
}

#footer .container input:focus {
	box-shadow: none;
}

#footer a:hover {
	color: #CCC;
}

#footer h1, #footer h2, #footer h3, #footer h4, #footer a {
	color: #FFF;
}

#footer h4 {
	font-size: 1.8em;
	font-weight: 200;
}

#footer h5 {
	color: #CCC;
}

#footer ul.nav-list > li a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	line-height: 20px;
	padding-bottom: 11px;
}

#footer ul.nav-list > li:last-child a {
	border-bottom: 0;
}

#footer ul.nav-list > li a:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

#footer ul.list.icons li {
	margin-bottom: 5px;
}

#footer form {
	opacity: 0.85;
}

#footer form label.error {
	line-height: 16px;
	margin: 5px 0 -5px;
	display: block;
	clear: both;
}

#footer form .alert {
	padding: 6px;
	text-align: center;
}

#footer .footer-ribbon {
	background: #999;
	position: absolute;
	margin: -90px 0 0 14px;
	padding: 10px 20px 6px 20px;
}

#footer .footer-ribbon:before {
	border-right: 10px solid #646464;
	border-top: 16px solid transparent;
	content: "";
	display: block;
	height: 0;
	left: -10px;
	position: absolute;
	top: 0;
	width: 7px;
}

#footer .footer-ribbon span {
	color: #FFF;
	font-size: 1.6em;
	font-family: "Shadows Into Light", cursive;
}

#footer .newsletter form {
	width: 100%;
	max-width: 262px;
}

#footer .newsletter form input.form-control {
	height: 34px;
	padding: 6px 12px;
}

#footer .alert a {
	color: #222;
}

#footer .alert a:hover {
	color: #666;
}

#footer .twitter .fa, #footer .twitter .icons {
	clear: both;
	font-size: 1.5em;
	position: relative;
	top: 3px;
}

#footer .twitter .meta {
	color: #999;
	display: block;
	font-size: 0.9em;
	padding-top: 3px;
}

#footer .twitter ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .twitter ul li {
	padding-bottom: 20px;
}

#footer .twitter ul li:last-child {
	padding-bottom: 0;
}

#footer .twitter-account {
	color: #FFF;
	display: block;
	font-size: 0.9em;
	margin: -15px 0 5px;
	opacity: 0.55;
}

#footer .twitter-account:hover {
	opacity: 1;
}

#footer .contact {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .contact i {
	display: block;
	float: left;
	font-size: 1.2em;
	margin: 7px 8px 10px 0;
	position: relative;
	text-align: center;
	top: -2px;
	width: 16px;
}

#footer .contact i.fa-envelope {
	font-size: 1em;
	top: 0;
}

#footer .contact p {
	margin-bottom: 10px;
}

#footer .logo {
	display: block;
}

#footer .logo.mb-xl {
	margin-bottom: 12px;
}

#footer .footer-copyright {
	background: #060606;
	border-top: 4px solid #060606;
	margin-top: 40px;
	padding: 30px 0 10px;
}

#footer .footer-copyright nav {
	float: right;
}

#footer .footer-copyright nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .footer-copyright nav ul li {
	border-left: 1px solid #505050;
	display: inline-block;
	line-height: 12px;
	margin: 0;
	padding: 0 8px;
}

#footer .footer-copyright nav ul li:first-child {
	border: medium none;
	padding-left: 0;
}

#footer .footer-copyright p {
	color: #555;
	margin: 0;
	padding: 0;
}

#footer .phone {
	color: #FFF;
	font-size: 26px;
	font-weight: bold;
	display: block;
	padding-bottom: 15px;
	margin-top: -5px;
}

#footer .narrow-phone {
	text-align: right;
	margin: -2px 0 0 0;
}

#footer .narrow-phone .phone {
	font-size: 16px;
	padding-bottom: 0;
	margin-top: -13px;
}

#footer .recent-work {
	list-style: none;
	padding: 0;
	margin: 0;
}

#footer .recent-work li {
	float: left;
	width: 30%;
	margin: 10px 3% 0 0;
}

#footer .recent-work img {
	border: 0;
	width: 100%;
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);
}

#footer .recent-work .img-thumbnail-hover-icon {
	border: 0;
}

#footer .recent-work .img-thumbnail-hover-icon:after {
	font-size: 22px;
	margin-left: -2px;
}

#footer .view-more-recent-work {
	padding: 12px;
}

#footer.short {
	padding-top: 50px;
}

#footer.short .footer-copyright {
	background: transparent;
	border: 0;
	padding-top: 0;
	margin-top: 0;
}

#footer.light {
	padding-top: 60px;
	background: #FFF;
	border-top: 1px solid #DDD;
}

#footer.light .footer-ribbon {
	margin-top: -76px;
	margin-left: 14px;
}

#footer.light .footer-copyright {
	margin-top: 15px;
	background: #FFF;
	border-top: 1px solid #DDD;
}

#footer.light .footer-copyright p {
	color: #777;
}

#footer.light .phone,
#footer.light .twitter-account {
	color: #777;
}

#footer.light ul.nav-pills > li.active > a {
	color: #FFF;
}

#footer.narrow {
	padding-top: 30px;
}

#footer.narrow .footer-copyright {
	margin-top: 0;
}

#footer.narrow .nav > li > a {
	padding: 5px 12px;
}

#footer.color {
	color: #FFF;
}

#footer.color p {
	color: #FFF;
}

#footer.color .footer-copyright nav ul li {
	border-left-color: #FFF;
}

/* Responsive */
@media (max-width: 991px) {
	#footer .phone {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	/* Footer */
	#footer .phone {
		font-size: 20px;
	}
}
/* Dark Version */
html.dark body {
	background-color: #1d2127;
}

html.dark.boxed .body {
	background-color: #1d2127;
}

html.dark #header .header-top {
	background: #282d36;
	border-bottom-color: #2e353e;
}

html.dark #header .header-search .form-control {
	background: #21262d;
}

html.dark #header .header-body {
	background: #1d2127;
	border-top-color: #2e353e;
}

html.dark #header .header-nav-bar {
	background-color: #21262d;
}

html.dark #header .header-nav-top .nav > li > a:hover, html.dark #header .header-nav-top .nav > li > a a:focus {
	background-color: #21262d;
}

html.dark #header .header-nav-top .dropdown-menu {
	background: #21262d;
	border-color: #21262d;
}

html.dark #header .header-nav-top .dropdown-menu a:hover, html.dark #header .header-nav-top .dropdown-menu a:focus {
	background: #282d36 !important;
}

html.dark.sticky-header-active #header .header-body {
	border-bottom-color: #2e353e;
}

html.dark #header.header-transparent .header-body {
	background: transparent;
}

html.dark #header.header-transparent .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark #header.header-semi-transparent .header-body {
	background: transparent;
}

html.dark #header.header-semi-transparent .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark #header.header-semi-transparent-light .header-body {
	background: transparent;
}

html.dark #header.header-semi-transparent-light .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark h1, html.dark h2, html.dark h3, html.dark h4, html.dark h5, html.dark h6 {
	color: #FFF;
}

html.dark .alert h1, html.dark .alert h2, html.dark .alert h3, html.dark .alert h4, html.dark .alert h5, html.dark .alert h6 {
	color: #111;
}

html.dark blockquote {
	border-color: #282d36;
}

html.dark section.featured {
	background-color: #191c21;
	border-top-color: #2e353e;
}

html.dark section.section {
	background-color: #21262d;
	border-top-color: #282d36;
}

html.dark .dropdown-menu > li > a {
	color: #FFF;
}

html.dark .dropdown-menu > li > a:hover, html.dark .dropdown-menu > li > a:focus {
	background-color: #2e353e;
}

html.dark .tabs .nav-tabs li a,
html.dark .tabs .nav-tabs li a:focus {
	border-top-color: #282d36;
	border-left-color: #282d36;
	border-right-color: #282d36;
	background: #282d36;
}

html.dark .tabs .nav-tabs li a:hover {
	border-top-color: #808697;
}

html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus {
	border-top-color: #808697;
}

html.dark .tabs .nav-tabs.nav-justified {
	border-left-width: 0;
	border-right-width: 0;
	border-left-color: transparent;
	border-right-color: transparent;
}

html.dark .tabs .nav-tabs.nav-justified li a,
html.dark .tabs .nav-tabs.nav-justified li a:hover,
html.dark .tabs .nav-tabs.nav-justified li a:focus {
	border-bottom-color: #282d36;
}

html.dark .tabs.tabs-left .nav-tabs > li a, html.dark .tabs.tabs-right .nav-tabs > li a {
	background: #282d36;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .tabs.tabs-left .nav-tabs > li:last-child a, html.dark .tabs.tabs-right .nav-tabs > li:last-child a {
	border-bottom-color: #282d36;
}

html.dark .tabs .nav-tabs {
	border-color: #282d36;
}

html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus,
html.dark .tabs .nav-tabs.nav-justified li.active a,
html.dark .tabs .nav-tabs.nav-justified li.active a:hover,
html.dark .tabs .nav-tabs.nav-justified li.active a:focus {
	background: #2e353e;
	border-left-color: #2e353e;
	border-right-color: #2e353e;
}

html.dark .tabs .nav-tabs.nav-justified li.active a {
	border-bottom-color: #2e353e;
}

html.dark .tabs.tabs-vertical {
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li a,
html.dark .tabs.tabs-bottom .nav-tabs li a:focus {
	border-bottom-color: #282d36;
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom-color: #808697;
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li.active a,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:hover,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom-color: #808697;
	border-top-color: #2e353e;
}

html.dark .tabs .tab-content {
	background: #2e353e;
	border-color: #2e353e;
}

html.dark .tabs-primary.tabs-bottom .nav-tabs li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:focus, html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:focus {
	border-top-color: #2e353e !important;
}

html.dark .nav-tabs li.active a,
html.dark .nav-tabs li.active a:hover,
html.dark .nav-tabs li.active a:focus,
html.dark .nav-tabs li a {
	color: #808697;
}

html.dark .tab-content {
	background: #2e353e;
	border-color: #1d2127;
}

html.dark .tabs-simple .tab-content,
html.dark .tabs-simple .nav-tabs li a,
html.dark .tabs-simple .nav-tabs li.active a {
	background: transparent !important;
}

html.dark .nav > li > a:hover,
html.dark .nav > li > a:focus {
	background-color: #2e353e;
}

html.dark .call-to-action.call-to-action-default {
	background: #282d36;
}

html.dark .call-to-action.call-to-action-dark {
	background: #2e353e;
}

html.dark .call-to-action.with-borders {
	border-top-color: #2e353e;
	border-bottom-color: #2e353e;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .call-to-action.with-full-borders {
	border-color: #2e353e;
}

html.dark .call-to-action.featured {
	background: -webkit-linear-gradient( top , #282d36 1%, #2e353e 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, #282d36 1%, #2e353e 98%) repeat scroll 0 0 transparent;
	border-bottom-color: #2e353e;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .call-to-action.call-to-action-in-footer:before {
	border-top-color: #1d2127;
}

html.dark .counters.with-borders .counter {
	border-top: 1px solid #2e353e;
	border-bottom: 1px solid #2e353e;
	border-left: 1px solid #282d36;
	border-right: 1px solid #282d36;
}

html.dark .counters.counters-text-dark .counter {
	color: #FFF !important;
}

html.dark .counters.counters-text-dark .counter .fa, html.dark .counters.counters-text-dark .counter .icons, html.dark .counters.counters-text-dark .counter strong, html.dark .counters.counters-text-dark .counter label {
	color: #FFF !important;
}

html.dark section.timeline:after {
	background: #505050;
	background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
	background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
}

html.dark section.timeline .timeline-date {
	background-color: #282d36;
	border-color: #282d36;
	text-shadow: none;
}

html.dark section.timeline .timeline-title {
	background: #282d36;
}

html.dark section.timeline .timeline-box {
	border-color: #282d36;
	background: #282d36;
}

html.dark section.timeline .timeline-box.left:before {
	box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}

html.dark section.timeline .timeline-box.left:after {
	background: #282d36;
	border-right-color: #282d36;
	border-top-color: #282d36;
}

html.dark section.timeline .timeline-box.right:before {
	box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}

html.dark section.timeline .timeline-box.right:after {
	background: #282d36;
	border-left-color: #282d36;
	border-bottom-color: #282d36;
}

html.dark .form-control {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .btn-default {
	background-color: #282d36;
	border-color: #282d36;
	color: #FFF;
}

html.dark .btn.disabled, html.dark .btn.disabled:hover, html.dark .btn.disabled:active, html.dark .btn.disabled:focus,
html.dark .btn[disabled],
html.dark .btn[disabled]:hover,
html.dark .btn[disabled]:active,
html.dark .btn[disabled]:focus {
	border-color: #282d36 !important;
}

html.dark .pagination > li > a,
html.dark .pagination > li > span {
	background-color: #282d36;
	border-color: #242830;
}

html.dark .theme-default .nivoSlider {
	background-color: #1d2127;
}

html.dark section.section-custom-map {
	background: transparent url(../img/map-dark.png) center 0 no-repeat;
}

html.dark section.section-custom-map section.section {
	border-top-color: rgba(40, 45, 54, 0.8);
	background: rgba(33, 38, 45, 0.8);
}

html.dark .home-concept {
	background-image: url(../img/home-concept-dark.png);
}

html.dark .home-concept .process-image {
	background-image: url(../img/home-concept-item-dark.png);
}

html.dark .home-concept .project-image {
	background-image: url(../img/home-concept-item-dark.png);
}

html.dark .home-concept .sun {
	background-image: url(../img/home-concept-icons-dark.png);
}

html.dark .home-concept .cloud {
	background-image: url(../img/home-concept-icons-dark.png);
}

html.dark .page-header {
	border-bottom-color: #191c21;
}

html.dark .page-header-light {
	background-color: #2e353e;
}

html.dark .panel-group .panel {
	background-color: #282d36;
}

html.dark .panel-group .panel-default {
	border-color: #282d36;
}

html.dark .panel-group .panel-heading {
	background-color: #2e353e;
}

html.dark .panel-group .form-control {
	background-color: #21262d;
	border-color: #21262d;
}

html.dark .toggle > label {
	background-color: #2e353e;
}

html.dark .toggle.toggle-simple .toggle > label {
	color: #FFF;
}

html.dark .featured-box {
	background: #2e353e;
	border-left-color: #2e353e;
	border-right-color: #2e353e;
	border-bottom-color: #2e353e;
}

html.dark .featured-box.secondary h4 {
	color: #FFF;
}

html.dark .featured-boxes-flat .featured-box .box-content {
	background: #2e353e;
}

html.dark .featured-boxes-style-5 .featured-box .box-content h4, html.dark .featured-boxes-style-6 .featured-box .box-content h4, html.dark .featured-boxes-style-7 .featured-box .box-content h4 {
	color: #FFF;
}

html.dark .featured-boxes-style-2 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-3 .featured-box .icon-featured {
	background: #1d2127;
}

html.dark .featured-boxes-style-4 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-5 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-5 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-6 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-6 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-6 .featured-box .icon-featured:after {
	border-color: #2e353e;
}

html.dark .featured-boxes-style-7 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-7 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-7 .featured-box .icon-featured:after {
	box-shadow: 3px 3px #161a1e;
}

html.dark .featured-boxes-style-8 .featured-box .icon-featured {
	background: #2e353e;
}

html.dark .featured-box-effect-1 .icon-featured:after {
	box-shadow: 0 0 0 3px #2e353e;
}

html.dark .feature-box.feature-box-style-2 h4, html.dark .feature-box.feature-box-style-3 h4, html.dark .feature-box.feature-box-style-4 h4 {
	color: #FFF;
}

html.dark .feature-box.feature-box-style-6 .feature-box-icon {
	border-color: #2e353e;
}

html.dark .feature-box.feature-box-style-6 .feature-box-icon:after {
	border-color: #2e353e;
}

html.dark .owl-dots .owl-dot span {
	background: #2e353e;
}

html.dark .owl-carousel.top-border {
	border-top-color: #3F4247;
}

html.dark .progress {
	background: #2e353e;
}

html.dark .arrow {
	background-image: url(../img/arrows-dark.png);
}

html.dark .thumbnail,
html.dark .img-thumbnail,
html.dark .thumb-info {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .thumb-info .thumb-info-wrapper:after {
	background-color: rgba(29, 33, 39, 0.9);
}

html.dark .thumb-info-social-icons {
	border-top-color: #373f4a;
}

html.dark ul.nav-list > li a {
	border-bottom-color: #282d36;
}

html.dark ul.nav-list > li a:hover {
	background-color: #282d36;
}

html.dark .content-grid .content-grid-item:before {
	border-left-color: #2e353e;
}

html.dark .content-grid .content-grid-item:after {
	border-bottom-color: #2e353e;
}

html.dark .content-grid.content-grid-dashed .content-grid-item:before {
	border-left-color: #2e353e;
}

html.dark .content-grid.content-grid-dashed .content-grid-item:after {
	border-bottom-color: #2e353e;
}

html.dark .testimonial .testimonial-author strong {
	color: #FFF;
}

html.dark .testimonial.testimonial-style-3 blockquote {
	background: #2e353e !important;
}

html.dark .testimonial.testimonial-style-3 .testimonial-arrow-down {
	border-top-color: #2e353e !important;
}

html.dark .testimonial.testimonial-style-4 {
	border-color: #2e353e !important;
}

html.dark .testimonial.testimonial-style-5 .testimonial-author {
	border-top-color: #2e353e !important;
}

html.dark .popover {
	background-color: #2e353e;
	border: 1px solid #282d36;
}

html.dark .popover.top > .arrow {
	border-top-color: #282d36;
}

html.dark .popover.top > .arrow:after {
	border-top-color: #2e353e;
}

html.dark .popover.right > .arrow {
	border-right-color: #282d36;
}

html.dark .popover.right > .arrow:after {
	border-right-color: #2e353e;
}

html.dark .popover.bottom > .arrow {
	border-bottom-color: #282d36;
}

html.dark .popover.bottom > .arrow:after {
	border-bottom-color: #2e353e;
}

html.dark .popover.left > .arrow {
	border-left-color: #282d36;
}

html.dark .popover.left > .arrow:after {
	border-left-color: #2e353e;
}

html.dark .popover-title {
	background-color: #282d36;
	border-bottom: #2e353e;
}

html.dark .page-header {
	border-bottom-color: #282d36;
}

html.dark .table > thead > tr > th,
html.dark .table > tbody > tr > th,
html.dark .table > tfoot > tr > th,
html.dark .table > thead > tr > td,
html.dark .table > tbody > tr > td,
html.dark .table > tfoot > tr > td,
html.dark .table-bordered {
	border-color: #282d36;
}

html.dark .table-striped > tbody > tr:nth-child(2n+1) > td,
html.dark .table-striped > tbody > tr:nth-child(2n+1) > th {
	background-color: #2e353e;
}

html.dark pre {
	background-color: #282d36;
	border-color: #282d36;
	color: #777;
}

html.dark .show-grid [class*="col-md-"] .show-grid-block {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .google-map-borders,
html.dark .embed-responsive-borders {
	border-color: #2e353e;
}

html.dark .alert.alert-default {
	border-color: #2e353e;
	background-color: #282d36;
}

html.dark hr {
	background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
	background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}

html.dark hr.light {
	background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
	background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}

html.dark hr.solid {
	background: #3F4247;
}

html.dark .divider {
	background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
	background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}

html.dark .divider .fa, html.dark .divider .icons {
	background: #1d2127;
}

html.dark .divider.divider-solid {
	background: #3F4247;
}

html.dark .divider.divider-style-2 .fa, html.dark .divider.divider-style-2 .icons {
	background: #21262d;
}

html.dark .divider.divider-style-3 .fa, html.dark .divider.divider-style-3 .icons {
	border-color: #3F4247;
}

html.dark .divider.divider-style-4 .fa, html.dark .divider.divider-style-4 .icons {
	border-color: #3F4247;
}

html.dark .divider.divider-style-4 .fa:after, html.dark .divider.divider-style-4 .icons:after {
	border-color: #21262d;
}

html.dark .divider.divider-small {
	background: transparent;
}

html.dark .divider.divider-small hr {
	background: #3F4247;
}

html.dark .divider.divider-small.divider-light hr {
	background: #3F4247;
}

html.dark hr.dashed:after, html.dark .divider.dashed:after {
	border-color: #3F4247;
}

html.dark .heading.heading-bottom-border h1 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-border h2, html.dark .heading.heading-bottom-border h3 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-border h4, html.dark .heading.heading-bottom-border h5, html.dark .heading.heading-bottom-border h6 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-double-border h1, html.dark .heading.heading-bottom-double-border h2, html.dark .heading.heading-bottom-double-border h3 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-double-border h4, html.dark .heading.heading-bottom-double-border h5, html.dark .heading.heading-bottom-double-border h6 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-middle-border:before {
	border-top-color: #3f4247;
}

html.dark .heading.heading-middle-border h1, html.dark .heading.heading-middle-border h2, html.dark .heading.heading-middle-border h3, html.dark .heading.heading-middle-border h4, html.dark .heading.heading-middle-border h5, html.dark .heading.heading-middle-border h6 {
	background: #1d2127;
}

html.dark ul.history li .thumb {
	background-image: url(../img/history-thumb-dark.png);
}

html.dark .recent-posts .date .day {
	background-color: #2e353e;
}

html.dark .blog-posts article {
	border-color: #282d36;
}

html.dark section.featured .recent-posts .date .day,
html.dark article.post .post-date .day {
	background-color: #282d36;
}

html.dark article .post-video,
html.dark article .post-video iframe,
html.dark article .post-audio,
html.dark article .post-audio iframe {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark ul.simple-post-list li {
	border-bottom-color: #21262d;
}

html.dark .post-block {
	border-top-color: #191c21;
}

html.dark ul.comments .comment-block {
	background-color: #282d36;
}

html.dark ul.comments .comment-arrow {
	border-right-color: #282d36;
}

html.dark .pricing-table li {
	border-top-color: #21262d;
}

html.dark .pricing-table h3 {
	background-color: #21262d;
	text-shadow: none;
}

html.dark .pricing-table h3 span {
	background: #282d36;
	border-color: #242830;
	box-shadow: 0 5px 20px #242830 inset, 0 3px 0 #282d36 inset;
	color: #777;
}

html.dark .pricing-table .most-popular {
	border-color: #282d36;
}

html.dark .pricing-table .most-popular h3 {
	background-color: #282d36;
	color: #FFF;
	text-shadow: none;
}

html.dark .pricing-table .plan-ribbon {
	background-color: #282d36;
}

html.dark .pricing-table .plan {
	background: #282d36;
	border: 1px solid #282d36;
	color: #777;
	text-shadow: none;
}

html.dark .product-thumb-info {
	background-color: #282d36;
	border-color: transparent;
}

html.dark .shop .quantity .qty {
	background-color: #282d36;
	border-color: transparent;
}

html.dark .shop .quantity .minus,
html.dark .shop .quantity .plus {
	background-color: #21262d;
	border-color: transparent;
}

html.dark .shop table.cart td,
html.dark .shop .cart-totals th,
html.dark .shop .cart-totals td {
	border-color: #282d36;
}

html.dark .dialog {
	background-color: #1d2127;
}

html.dark .modal-content {
	background-color: #1d2127;
}

html.dark .modal-header {
	border-bottom-color: #282d36;
}

html.dark .modal-header h1, html.dark .modal-header h2, html.dark .modal-header h3, html.dark .modal-header h4, html.dark .modal-header h5, html.dark .modal-header h6 {
	color: #777;
}

html.dark .modal-header .close {
	text-shadow: none;
	color: #FFF;
}

html.dark .modal-footer {
	border-top-color: #282d36;
}

html.dark .popup-inline-content,
html.dark .mfp-content .ajax-container {
	background: #1d2127 !important;
}

html.dark .loading-overlay {
	background: #1d2127;
}

html.dark .sort-destination-loader:after {
	background-color: #1d2127;
}

html.dark #footer .newsletter form .btn-default {
	background-color: #21262d;
	border-color: #21262d;
	color: #777;
}

/* Responsive */
@media (min-width: 992px) {
	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu {
		background: #21262d;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a {
		border-bottom-color: #2e353e;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-title {
		color: #999;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-nav > li:hover > a {
		background: #2e353e;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) a > .thumb-info-preview .thumb-info-wrapper {
		background: #2e353e;
	}

	html.dark #header .header-nav.header-nav-stripe nav > ul > li > a {
		color: #CCC;
	}

	html.dark #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
		color: #FFF;
	}

	html.dark #header .header-nav.header-nav-top-line nav > ul > li > a, html.dark #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
		color: #CCC;
	}

	html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li > a, html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
		color: #CCC;
	}

	html.dark .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
		background-color: #1d2127;
	}
}

@media (max-width: 991px) {
	html.dark #header .header-nav-main nav > ul li {
		border-bottom-color: #2e353e;
	}

	html.dark #header.header-transparent .header-nav-main, html.dark #header.header-semi-transparent .header-nav-main, html.dark #header.header-semi-transparent-light .header-nav-main {
		background-color: #1d2127;
	}
}
/* Boxed Layout */
html.boxed body {
	background-color: transparent;
	background-position: 0 0;
	background-repeat: repeat;
}

html.boxed .body {
	position: relative;
	background-color: #FFF;
	border-bottom: 5px solid #1d2127;
	border-radius: 5px;
	border-top: 5px solid #CCC;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	margin: 25px auto;
	max-width: 1200px;
	height: auto;
}

html.boxed #header .header-body {
	border-top-color: transparent;
}

html.boxed .main {
	overflow: hidden;
}

/* Responsive */
@media (min-width: 1200px) {
	html.boxed.sticky-header-active #header .header-body {
		width: auto;
	}

	html.boxed #header.header-transparent .header-body,
	html.boxed #header.header-semi-transparent .header-body,
	html.boxed #header.header-semi-transparent-light .header-body {
		width: auto;
	}
}

@media (min-width: 992px) {
	html.boxed #header .header-top.header-top-colored {
		margin-top: -18px;
		border-radius: 4px 4px 0 0;
	}

	html.boxed #header.header-transparent .header-body,
	html.boxed #header.header-semi-transparent .header-body,
	html.boxed #header.header-semi-transparent-light .header-body {
		position: absolute;
		padding-left: 15px;
		padding-right: 15px;
	}

	html.boxed #header .header-top.header-top-style-2,
	html.boxed #header .header-top.header-top-style-3,
	html.boxed #header .header-top.header-top-style-4 {
		margin-top: -13px;
	}

	html.boxed.sticky-header-active #header .header-body {
		position: fixed !important;
		padding-left: 15px;
		padding-right: 15px;
	}

	html.boxed.sticky-header-active #header .header-nav-bar {
		margin: 0 -15px -9px;
	}
}

@media (max-width: 991px) {
	html.boxed .body {
		margin: 0 auto;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
}

@media (max-width: 767px) {
	/* Boxed */
	html.boxed {
		background: none !important;
	}
}


a {
	color: #0088cc;
}

a:hover {
	color: #0099e6;
}

a:focus {
	color: #0099e6;
}

a:active {
	color: #0077b3;
}

html .heading-primary,
html .lnk-primary,
html .text-color-primary {
	color: #0088cc !important;
}

html .heading.heading-primary h1,
html .heading.heading-primary h2,
html .heading.heading-primary h3,
html .heading.heading-primary h4,
html .heading.heading-primary h5,
html .heading.heading-primary h6 {
	border-color: #0088cc;
}

html .heading-secondary,
html .lnk-secondary,
html .text-color-secondary {
	color: #e36159 !important;
}

html .heading.heading-secondary h1,
html .heading.heading-secondary h2,
html .heading.heading-secondary h3,
html .heading.heading-secondary h4,
html .heading.heading-secondary h5,
html .heading.heading-secondary h6 {
	border-color: #e36159;
}

html .heading-tertiary,
html .lnk-tertiary,
html .text-color-tertiary {
	color: #2baab1 !important;
}

html .heading.heading-tertiary h1,
html .heading.heading-tertiary h2,
html .heading.heading-tertiary h3,
html .heading.heading-tertiary h4,
html .heading.heading-tertiary h5,
html .heading.heading-tertiary h6 {
	border-color: #2baab1;
}

html .heading-quaternary,
html .lnk-quaternary,
html .text-color-quaternary {
	color: #383f48 !important;
}

html .heading.heading-quaternary h1,
html .heading.heading-quaternary h2,
html .heading.heading-quaternary h3,
html .heading.heading-quaternary h4,
html .heading.heading-quaternary h5,
html .heading.heading-quaternary h6 {
	border-color: #383f48;
}

html .heading-dark,
html .lnk-dark,
html .text-color-dark {
	color: #2e353e !important;
}

html .heading.heading-dark h1,
html .heading.heading-dark h2,
html .heading.heading-dark h3,
html .heading.heading-dark h4,
html .heading.heading-dark h5,
html .heading.heading-dark h6 {
	border-color: #2e353e;
}

html .heading-light,
html .lnk-light,
html .text-color-light {
	color: #ffffff !important;
}

html .heading.heading-light h1,
html .heading.heading-light h2,
html .heading.heading-light h3,
html .heading.heading-light h4,
html .heading.heading-light h5,
html .heading.heading-light h6 {
	border-color: #ffffff;
}

html .background-color-primary {
	background-color: #0088cc !important;
}

html .background-color-secondary {
	background-color: #e36159 !important;
}

html .background-color-tertiary {
	background-color: #2baab1 !important;
}

html .background-color-quaternary {
	background-color: #383f48 !important;
}

html .background-color-dark {
	background-color: #2e353e !important;
}

html .background-color-light {
	background-color: #ffffff !important;
}

.alternative-font {
	color: #0088cc;
}

html .blockquote-primary {
	border-color: #0088cc !important;
}

html .blockquote-secondary {
	border-color: #e36159 !important;
}

html .blockquote-tertiary {
	border-color: #2baab1 !important;
}

html .blockquote-quaternary {
	border-color: #383f48 !important;
}

html .blockquote-dark {
	border-color: #2e353e !important;
}

html .blockquote-light {
	border-color: #ffffff !important;
}

p.drop-caps:first-letter {
	color: #0088cc;
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #0088cc;
}

ul.nav-pills > li.active > a {
	background-color: #0088cc;
}

ul.nav-pills > li.active > a:hover,
ul.nav-pills > li.active > a:focus {
	background-color: #0088cc;
}

html ul.nav-pills-primary a {
	color: #0088cc;
}

html ul.nav-pills-primary a:hover {
	color: #0099e6;
}

html ul.nav-pills-primary a:focus {
	color: #0099e6;
}

html ul.nav-pills-primary a:active {
	color: #0077b3;
}

html ul.nav-pills-primary > li.active > a {
	background-color: #0088cc;
}

html ul.nav-pills-primary > li.active > a:hover,
html ul.nav-pills-primary > li.active > a:focus {
	background-color: #0088cc;
}

html ul.nav-pills-secondary a {
	color: #e36159;
}

html ul.nav-pills-secondary a:hover {
	color: #e7766f;
}

html ul.nav-pills-secondary a:focus {
	color: #e7766f;
}

html ul.nav-pills-secondary a:active {
	color: #df4c43;
}

html ul.nav-pills-secondary > li.active > a {
	background-color: #e36159;
}

html ul.nav-pills-secondary > li.active > a:hover,
html ul.nav-pills-secondary > li.active > a:focus {
	background-color: #e36159;
}

html ul.nav-pills-tertiary a {
	color: #2baab1;
}

html ul.nav-pills-tertiary a:hover {
	color: #30bec6;
}

html ul.nav-pills-tertiary a:focus {
	color: #30bec6;
}

html ul.nav-pills-tertiary a:active {
	color: #26969c;
}

html ul.nav-pills-tertiary > li.active > a {
	background-color: #2baab1;
}

html ul.nav-pills-tertiary > li.active > a:hover,
html ul.nav-pills-tertiary > li.active > a:focus {
	background-color: #2baab1;
}

html ul.nav-pills-quaternary a {
	color: #383f48;
}

html ul.nav-pills-quaternary a:hover {
	color: #434c56;
}

html ul.nav-pills-quaternary a:focus {
	color: #434c56;
}

html ul.nav-pills-quaternary a:active {
	color: #2d323a;
}

html ul.nav-pills-quaternary > li.active > a {
	background-color: #383f48;
}

html ul.nav-pills-quaternary > li.active > a:hover,
html ul.nav-pills-quaternary > li.active > a:focus {
	background-color: #383f48;
}

html ul.nav-pills-dark a {
	color: #2e353e;
}

html ul.nav-pills-dark a:hover {
	color: #39424d;
}

html ul.nav-pills-dark a:focus {
	color: #39424d;
}

html ul.nav-pills-dark a:active {
	color: #23282f;
}

html ul.nav-pills-dark > li.active > a {
	background-color: #2e353e;
}

html ul.nav-pills-dark > li.active > a:hover,
html ul.nav-pills-dark > li.active > a:focus {
	background-color: #2e353e;
}

html ul.nav-pills-light a {
	color: #ffffff;
}

html ul.nav-pills-light a:hover {
	color: #ffffff;
}

html ul.nav-pills-light a:focus {
	color: #ffffff;
}

html ul.nav-pills-light a:active {
	color: #f2f2f2;
}

html ul.nav-pills-light > li.active > a {
	background-color: #ffffff;
}

html ul.nav-pills-light > li.active > a:hover,
html ul.nav-pills-light > li.active > a:focus {
	background-color: #ffffff;
}

.sort-source-wrapper .nav > li.active > a {
	color: #0088cc;
}

.sort-source-wrapper .nav > li.active > a:hover,
.sort-source-wrapper .nav > li.active > a:focus {
	color: #0088cc;
}

.sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #0088cc;
}

html .label-primary {
	background-color: #0088cc;
}

html .label-secondary {
	background-color: #e36159;
}

html .label-tertiary {
	background-color: #2baab1;
}

html .label-quaternary {
	background-color: #383f48;
}

html .label-dark {
	background-color: #2e353e;
}

html .label-light {
	background-color: #ffffff;
}

.btn-link {
	color: #0088cc;
}

.btn-link:hover {
	color: #0099e6;
}

.btn-link:active {
	color: #0077b3;
}

html .btn-primary {
	color: #ffffff;
	background-color: #0088cc;
	border-color: #0088cc #0088cc #006699;
}

html .btn-primary:hover {
	border-color: #0099e6 #0099e6 #0077b3;
	background-color: #0099e6;
}

html .btn-primary:active,
html .btn-primary:focus,
html .btn-primary:active:hover,
html .btn-primary:active:focus {
	border-color: #0077b3 #0077b3 #0077b3;
	background-color: #0077b3;
}

html .btn-primary.dropdown-toggle {
	border-left-color: #0077b3;
}

html .btn-primary[disabled],
html .btn-primary[disabled]:hover,
html .btn-primary[disabled]:active,
html .btn-primary[disabled]:focus {
	border-color: #33bbff;
	background-color: #33bbff;
}

html .btn-primary:hover,
html .btn-primary:focus,
html .btn-primary:active:hover,
html .btn-primary:active:focus {
	color: #ffffff;
}

html .btn-primary-scale-2 {
	color: #ffffff;
	background-color: #006699;
	border-color: #006699 #006699 #004466;
}

html .btn-primary-scale-2:hover {
	border-color: #0077b3 #0077b3 #005580;
	background-color: #0077b3;
}

html .btn-primary-scale-2:active,
html .btn-primary-scale-2:focus,
html .btn-primary-scale-2:active:hover,
html .btn-primary-scale-2:active:focus {
	border-color: #005580 #005580 #005580;
	background-color: #005580;
}

html .btn-primary-scale-2.dropdown-toggle {
	border-left-color: #005580;
}

html .btn-primary-scale-2[disabled],
html .btn-primary-scale-2[disabled]:hover,
html .btn-primary-scale-2[disabled]:active,
html .btn-primary-scale-2[disabled]:focus {
	border-color: #00aaff;
	background-color: #00aaff;
}

html .btn-secondary {
	color: #ffffff;
	background-color: #e36159;
	border-color: #e36159 #e36159 #dc372d;
}

html .btn-secondary:hover {
	border-color: #e7766f #e7766f #df4c43;
	background-color: #e7766f;
}

html .btn-secondary:active,
html .btn-secondary:focus,
html .btn-secondary:active:hover,
html .btn-secondary:active:focus {
	border-color: #df4c43 #df4c43 #df4c43;
	background-color: #df4c43;
}

html .btn-secondary.dropdown-toggle {
	border-left-color: #df4c43;
}

html .btn-secondary[disabled],
html .btn-secondary[disabled]:hover,
html .btn-secondary[disabled]:active,
html .btn-secondary[disabled]:focus {
	border-color: #f2b4b0;
	background-color: #f2b4b0;
}

html .btn-secondary:hover,
html .btn-secondary:focus,
html .btn-secondary:active:hover,
html .btn-secondary:active:focus {
	color: #ffffff;
}

html .btn-secondary-scale-2 {
	color: #ffffff;
	background-color: #dc372d;
	border-color: #dc372d #dc372d #b7281f;
}

html .btn-secondary-scale-2:hover {
	border-color: #df4c43 #df4c43 #cd2c23;
	background-color: #df4c43;
}

html .btn-secondary-scale-2:active,
html .btn-secondary-scale-2:focus,
html .btn-secondary-scale-2:active:hover,
html .btn-secondary-scale-2:active:focus {
	border-color: #cd2c23 #cd2c23 #cd2c23;
	background-color: #cd2c23;
}

html .btn-secondary-scale-2.dropdown-toggle {
	border-left-color: #cd2c23;
}

html .btn-secondary-scale-2[disabled],
html .btn-secondary-scale-2[disabled]:hover,
html .btn-secondary-scale-2[disabled]:active,
html .btn-secondary-scale-2[disabled]:focus {
	border-color: #ea8b85;
	background-color: #ea8b85;
}

html .btn-tertiary {
	color: #ffffff;
	background-color: #2baab1;
	border-color: #2baab1 #2baab1 #218388;
}

html .btn-tertiary:hover {
	border-color: #30bec6 #30bec6 #26969c;
	background-color: #30bec6;
}

html .btn-tertiary:active,
html .btn-tertiary:focus,
html .btn-tertiary:active:hover,
html .btn-tertiary:active:focus {
	border-color: #26969c #26969c #26969c;
	background-color: #26969c;
}

html .btn-tertiary.dropdown-toggle {
	border-left-color: #26969c;
}

html .btn-tertiary[disabled],
html .btn-tertiary[disabled]:hover,
html .btn-tertiary[disabled]:active,
html .btn-tertiary[disabled]:focus {
	border-color: #68d4da;
	background-color: #68d4da;
}

html .btn-tertiary:hover,
html .btn-tertiary:focus,
html .btn-tertiary:active:hover,
html .btn-tertiary:active:focus {
	color: #ffffff;
}

html .btn-tertiary-scale-2 {
	color: #ffffff;
	background-color: #218388;
	border-color: #218388 #218388 #175b5f;
}

html .btn-tertiary-scale-2:hover {
	border-color: #26969c #26969c #1c6f73;
	background-color: #26969c;
}

html .btn-tertiary-scale-2:active,
html .btn-tertiary-scale-2:focus,
html .btn-tertiary-scale-2:active:hover,
html .btn-tertiary-scale-2:active:focus {
	border-color: #1c6f73 #1c6f73 #1c6f73;
	background-color: #1c6f73;
}

html .btn-tertiary-scale-2.dropdown-toggle {
	border-left-color: #1c6f73;
}

html .btn-tertiary-scale-2[disabled],
html .btn-tertiary-scale-2[disabled]:hover,
html .btn-tertiary-scale-2[disabled]:active,
html .btn-tertiary-scale-2[disabled]:focus {
	border-color: #3fc9d0;
	background-color: #3fc9d0;
}

html .btn-quaternary {
	color: #ffffff;
	background-color: #383f48;
	border-color: #383f48 #383f48 #22262b;
}

html .btn-quaternary:hover {
	border-color: #434c56 #434c56 #2d323a;
	background-color: #434c56;
}

html .btn-quaternary:active,
html .btn-quaternary:focus,
html .btn-quaternary:active:hover,
html .btn-quaternary:active:focus {
	border-color: #2d323a #2d323a #2d323a;
	background-color: #2d323a;
}

html .btn-quaternary.dropdown-toggle {
	border-left-color: #2d323a;
}

html .btn-quaternary[disabled],
html .btn-quaternary[disabled]:hover,
html .btn-quaternary[disabled]:active,
html .btn-quaternary[disabled]:focus {
	border-color: #657181;
	background-color: #657181;
}

html .btn-quaternary:hover,
html .btn-quaternary:focus,
html .btn-quaternary:active:hover,
html .btn-quaternary:active:focus {
	color: #ffffff;
}

html .btn-quaternary-scale-2 {
	color: #ffffff;
	background-color: #22262b;
	border-color: #22262b #22262b #0b0d0f;
}

html .btn-quaternary-scale-2:hover {
	border-color: #2d323a #2d323a #17191d;
	background-color: #2d323a;
}

html .btn-quaternary-scale-2:active,
html .btn-quaternary-scale-2:focus,
html .btn-quaternary-scale-2:active:hover,
html .btn-quaternary-scale-2:active:focus {
	border-color: #17191d #17191d #17191d;
	background-color: #17191d;
}

html .btn-quaternary-scale-2.dropdown-toggle {
	border-left-color: #17191d;
}

html .btn-quaternary-scale-2[disabled],
html .btn-quaternary-scale-2[disabled]:hover,
html .btn-quaternary-scale-2[disabled]:active,
html .btn-quaternary-scale-2[disabled]:focus {
	border-color: #4e5865;
	background-color: #4e5865;
}

html .btn-dark {
	color: #ffffff;
	background-color: #2e353e;
	border-color: #2e353e #2e353e #181c21;
}

html .btn-dark:hover {
	border-color: #39424d #39424d #23282f;
	background-color: #39424d;
}

html .btn-dark:active,
html .btn-dark:focus,
html .btn-dark:active:hover,
html .btn-dark:active:focus {
	border-color: #23282f #23282f #23282f;
	background-color: #23282f;
}

html .btn-dark.dropdown-toggle {
	border-left-color: #23282f;
}

html .btn-dark[disabled],
html .btn-dark[disabled]:hover,
html .btn-dark[disabled]:active,
html .btn-dark[disabled]:focus {
	border-color: #596779;
	background-color: #596779;
}

html .btn-dark:hover,
html .btn-dark:focus,
html .btn-dark:active:hover,
html .btn-dark:active:focus {
	color: #ffffff;
}

html .btn-dark-scale-2 {
	color: #ffffff;
	background-color: #181c21;
	border-color: #181c21 #181c21 #030303;
}

html .btn-dark-scale-2:hover {
	border-color: #23282f #23282f #0d0f12;
	background-color: #23282f;
}

html .btn-dark-scale-2:active,
html .btn-dark-scale-2:focus,
html .btn-dark-scale-2:active:hover,
html .btn-dark-scale-2:active:focus {
	border-color: #0d0f12 #0d0f12 #0d0f12;
	background-color: #0d0f12;
}

html .btn-dark-scale-2.dropdown-toggle {
	border-left-color: #0d0f12;
}

html .btn-dark-scale-2[disabled],
html .btn-dark-scale-2[disabled]:hover,
html .btn-dark-scale-2[disabled]:active,
html .btn-dark-scale-2[disabled]:focus {
	border-color: #444e5b;
	background-color: #444e5b;
}

html .btn-light {
	color: #ffffff;
	background-color: #ffffff;
	border-color: #ffffff #ffffff #e6e6e6;
}

html .btn-light:hover {
	border-color: #ffffff #ffffff #f2f2f2;
	background-color: #ffffff;
}

html .btn-light:active,
html .btn-light:focus,
html .btn-light:active:hover,
html .btn-light:active:focus {
	border-color: #f2f2f2 #f2f2f2 #f2f2f2;
	background-color: #f2f2f2;
}

html .btn-light.dropdown-toggle {
	border-left-color: #f2f2f2;
}

html .btn-light[disabled],
html .btn-light[disabled]:hover,
html .btn-light[disabled]:active,
html .btn-light[disabled]:focus {
	border-color: #ffffff;
	background-color: #ffffff;
}

html .btn-light:hover,
html .btn-light:focus,
html .btn-light:active:hover,
html .btn-light:active:focus {
	color: #777777;
}

html .btn-light-scale-2 {
	color: #ffffff;
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #cccccc;
}

html .btn-light-scale-2:hover {
	border-color: #f2f2f2 #f2f2f2 #d9d9d9;
	background-color: #f2f2f2;
}

html .btn-light-scale-2:active,
html .btn-light-scale-2:focus,
html .btn-light-scale-2:active:hover,
html .btn-light-scale-2:active:focus {
	border-color: #d9d9d9 #d9d9d9 #d9d9d9;
	background-color: #d9d9d9;
}

html .btn-light-scale-2.dropdown-toggle {
	border-left-color: #d9d9d9;
}

html .btn-light-scale-2[disabled],
html .btn-light-scale-2[disabled]:hover,
html .btn-light-scale-2[disabled]:active,
html .btn-light-scale-2[disabled]:focus {
	border-color: #ffffff;
	background-color: #ffffff;
}

html .btn-borders.btn-primary {
	background: transparent;
	border-color: #0088cc;
	color: #0088cc;
	text-shadow: none;
}

html .btn-borders.btn-primary:hover,
html .btn-borders.btn-primary:focus {
	background-color: #0088cc;
	border-color: #0088cc #0088cc #006699;
	border-color: #0088cc !important;
	color: #ffffff;
}

html .btn-borders.btn-primary:hover:hover,
html .btn-borders.btn-primary:focus:hover {
	border-color: #0099e6 #0099e6 #0077b3;
	background-color: #0099e6;
}

html .btn-borders.btn-primary:hover:active,
html .btn-borders.btn-primary:focus:active,
html .btn-borders.btn-primary:hover:focus,
html .btn-borders.btn-primary:focus:focus,
html .btn-borders.btn-primary:hover:active:hover,
html .btn-borders.btn-primary:focus:active:hover,
html .btn-borders.btn-primary:hover:active:focus,
html .btn-borders.btn-primary:focus:active:focus {
	border-color: #0077b3 #0077b3 #0077b3;
	background-color: #0077b3;
}

html .btn-borders.btn-primary:hover.dropdown-toggle,
html .btn-borders.btn-primary:focus.dropdown-toggle {
	border-left-color: #0077b3;
}

html .btn-borders.btn-primary:hover[disabled],
html .btn-borders.btn-primary:focus[disabled],
html .btn-borders.btn-primary:hover[disabled]:hover,
html .btn-borders.btn-primary:focus[disabled]:hover,
html .btn-borders.btn-primary:hover[disabled]:active,
html .btn-borders.btn-primary:focus[disabled]:active,
html .btn-borders.btn-primary:hover[disabled]:focus,
html .btn-borders.btn-primary:focus[disabled]:focus {
	border-color: #33bbff;
	background-color: #33bbff;
}

html .btn-borders.btn-secondary {
	background: transparent;
	border-color: #e36159;
	color: #e36159;
	text-shadow: none;
}

html .btn-borders.btn-secondary:hover,
html .btn-borders.btn-secondary:focus {
	background-color: #e36159;
	border-color: #e36159 #e36159 #dc372d;
	border-color: #e36159 !important;
	color: #ffffff;
}

html .btn-borders.btn-secondary:hover:hover,
html .btn-borders.btn-secondary:focus:hover {
	border-color: #e7766f #e7766f #df4c43;
	background-color: #e7766f;
}

html .btn-borders.btn-secondary:hover:active,
html .btn-borders.btn-secondary:focus:active,
html .btn-borders.btn-secondary:hover:focus,
html .btn-borders.btn-secondary:focus:focus,
html .btn-borders.btn-secondary:hover:active:hover,
html .btn-borders.btn-secondary:focus:active:hover,
html .btn-borders.btn-secondary:hover:active:focus,
html .btn-borders.btn-secondary:focus:active:focus {
	border-color: #df4c43 #df4c43 #df4c43;
	background-color: #df4c43;
}

html .btn-borders.btn-secondary:hover.dropdown-toggle,
html .btn-borders.btn-secondary:focus.dropdown-toggle {
	border-left-color: #df4c43;
}

html .btn-borders.btn-secondary:hover[disabled],
html .btn-borders.btn-secondary:focus[disabled],
html .btn-borders.btn-secondary:hover[disabled]:hover,
html .btn-borders.btn-secondary:focus[disabled]:hover,
html .btn-borders.btn-secondary:hover[disabled]:active,
html .btn-borders.btn-secondary:focus[disabled]:active,
html .btn-borders.btn-secondary:hover[disabled]:focus,
html .btn-borders.btn-secondary:focus[disabled]:focus {
	border-color: #f2b4b0;
	background-color: #f2b4b0;
}

html .btn-borders.btn-tertiary {
	background: transparent;
	border-color: #2baab1;
	color: #2baab1;
	text-shadow: none;
}

html .btn-borders.btn-tertiary:hover,
html .btn-borders.btn-tertiary:focus {
	background-color: #2baab1;
	border-color: #2baab1 #2baab1 #218388;
	border-color: #2baab1 !important;
	color: #ffffff;
}

html .btn-borders.btn-tertiary:hover:hover,
html .btn-borders.btn-tertiary:focus:hover {
	border-color: #30bec6 #30bec6 #26969c;
	background-color: #30bec6;
}

html .btn-borders.btn-tertiary:hover:active,
html .btn-borders.btn-tertiary:focus:active,
html .btn-borders.btn-tertiary:hover:focus,
html .btn-borders.btn-tertiary:focus:focus,
html .btn-borders.btn-tertiary:hover:active:hover,
html .btn-borders.btn-tertiary:focus:active:hover,
html .btn-borders.btn-tertiary:hover:active:focus,
html .btn-borders.btn-tertiary:focus:active:focus {
	border-color: #26969c #26969c #26969c;
	background-color: #26969c;
}

html .btn-borders.btn-tertiary:hover.dropdown-toggle,
html .btn-borders.btn-tertiary:focus.dropdown-toggle {
	border-left-color: #26969c;
}

html .btn-borders.btn-tertiary:hover[disabled],
html .btn-borders.btn-tertiary:focus[disabled],
html .btn-borders.btn-tertiary:hover[disabled]:hover,
html .btn-borders.btn-tertiary:focus[disabled]:hover,
html .btn-borders.btn-tertiary:hover[disabled]:active,
html .btn-borders.btn-tertiary:focus[disabled]:active,
html .btn-borders.btn-tertiary:hover[disabled]:focus,
html .btn-borders.btn-tertiary:focus[disabled]:focus {
	border-color: #68d4da;
	background-color: #68d4da;
}

html .btn-borders.btn-quaternary {
	background: transparent;
	border-color: #383f48;
	color: #383f48;
	text-shadow: none;
}

html .btn-borders.btn-quaternary:hover,
html .btn-borders.btn-quaternary:focus {
	background-color: #383f48;
	border-color: #383f48 #383f48 #22262b;
	border-color: #383f48 !important;
	color: #ffffff;
}

html .btn-borders.btn-quaternary:hover:hover,
html .btn-borders.btn-quaternary:focus:hover {
	border-color: #434c56 #434c56 #2d323a;
	background-color: #434c56;
}

html .btn-borders.btn-quaternary:hover:active,
html .btn-borders.btn-quaternary:focus:active,
html .btn-borders.btn-quaternary:hover:focus,
html .btn-borders.btn-quaternary:focus:focus,
html .btn-borders.btn-quaternary:hover:active:hover,
html .btn-borders.btn-quaternary:focus:active:hover,
html .btn-borders.btn-quaternary:hover:active:focus,
html .btn-borders.btn-quaternary:focus:active:focus {
	border-color: #2d323a #2d323a #2d323a;
	background-color: #2d323a;
}

html .btn-borders.btn-quaternary:hover.dropdown-toggle,
html .btn-borders.btn-quaternary:focus.dropdown-toggle {
	border-left-color: #2d323a;
}

html .btn-borders.btn-quaternary:hover[disabled],
html .btn-borders.btn-quaternary:focus[disabled],
html .btn-borders.btn-quaternary:hover[disabled]:hover,
html .btn-borders.btn-quaternary:focus[disabled]:hover,
html .btn-borders.btn-quaternary:hover[disabled]:active,
html .btn-borders.btn-quaternary:focus[disabled]:active,
html .btn-borders.btn-quaternary:hover[disabled]:focus,
html .btn-borders.btn-quaternary:focus[disabled]:focus {
	border-color: #657181;
	background-color: #657181;
}

html .btn-borders.btn-dark {
	background: transparent;
	border-color: #2e353e;
	color: #2e353e;
	text-shadow: none;
}

html .btn-borders.btn-dark:hover,
html .btn-borders.btn-dark:focus {
	background-color: #2e353e;
	border-color: #2e353e #2e353e #181c21;
	border-color: #2e353e !important;
	color: #ffffff;
}

html .btn-borders.btn-dark:hover:hover,
html .btn-borders.btn-dark:focus:hover {
	border-color: #39424d #39424d #23282f;
	background-color: #39424d;
}

html .btn-borders.btn-dark:hover:active,
html .btn-borders.btn-dark:focus:active,
html .btn-borders.btn-dark:hover:focus,
html .btn-borders.btn-dark:focus:focus,
html .btn-borders.btn-dark:hover:active:hover,
html .btn-borders.btn-dark:focus:active:hover,
html .btn-borders.btn-dark:hover:active:focus,
html .btn-borders.btn-dark:focus:active:focus {
	border-color: #23282f #23282f #23282f;
	background-color: #23282f;
}

html .btn-borders.btn-dark:hover.dropdown-toggle,
html .btn-borders.btn-dark:focus.dropdown-toggle {
	border-left-color: #23282f;
}

html .btn-borders.btn-dark:hover[disabled],
html .btn-borders.btn-dark:focus[disabled],
html .btn-borders.btn-dark:hover[disabled]:hover,
html .btn-borders.btn-dark:focus[disabled]:hover,
html .btn-borders.btn-dark:hover[disabled]:active,
html .btn-borders.btn-dark:focus[disabled]:active,
html .btn-borders.btn-dark:hover[disabled]:focus,
html .btn-borders.btn-dark:focus[disabled]:focus {
	border-color: #596779;
	background-color: #596779;
}

html .btn-borders.btn-light {
	background: transparent;
	border-color: #ffffff;
	color: #ffffff;
	text-shadow: none;
}

html .btn-borders.btn-light:hover,
html .btn-borders.btn-light:focus {
	color: #ffffff;
	background-color: #ffffff;
	border-color: #ffffff #ffffff #e6e6e6;
	border-color: #ffffff !important;
	color: #777777;
}

html .btn-borders.btn-light:hover:hover,
html .btn-borders.btn-light:focus:hover {
	border-color: #ffffff #ffffff #f2f2f2;
	background-color: #ffffff;
}

html .btn-borders.btn-light:hover:active,
html .btn-borders.btn-light:focus:active,
html .btn-borders.btn-light:hover:focus,
html .btn-borders.btn-light:focus:focus,
html .btn-borders.btn-light:hover:active:hover,
html .btn-borders.btn-light:focus:active:hover,
html .btn-borders.btn-light:hover:active:focus,
html .btn-borders.btn-light:focus:active:focus {
	border-color: #f2f2f2 #f2f2f2 #f2f2f2;
	background-color: #f2f2f2;
}

html .btn-borders.btn-light:hover.dropdown-toggle,
html .btn-borders.btn-light:focus.dropdown-toggle {
	border-left-color: #f2f2f2;
}

html .btn-borders.btn-light:hover[disabled],
html .btn-borders.btn-light:focus[disabled],
html .btn-borders.btn-light:hover[disabled]:hover,
html .btn-borders.btn-light:focus[disabled]:hover,
html .btn-borders.btn-light:hover[disabled]:active,
html .btn-borders.btn-light:focus[disabled]:active,
html .btn-borders.btn-light:hover[disabled]:focus,
html .btn-borders.btn-light:focus[disabled]:focus {
	border-color: #ffffff;
	background-color: #ffffff;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #0088cc;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #0088cc !important;
	border-color: #0088cc;
}

body.dark .pagination > li > a,
body.dark .pagination > li > span,
body.dark .pagination > li > a:hover,
body.dark .pagination > li > span:hover,
body.dark .pagination > li > a:focus,
body.dark .pagination > li > span:focus {
	color: #0088cc;
}

body.dark .pagination > .active > a,
body.dark .pagination > .active > span,
body.dark .pagination > .active > a:hover,
body.dark .pagination > .active > span:hover,
body.dark .pagination > .active > a:focus,
body.dark .pagination > .active > span:focus {
	background-color: #0088cc;
	border-color: #0088cc;
}

.pagination > .active > a,
body.dark .pagination > .active > a {
	color: #ffffff;
	background-color: #0088cc;
	border-color: #0088cc #0088cc #006699;
}

.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover {
	border-color: #0099e6 #0099e6 #0077b3;
	background-color: #0099e6;
}

.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.pagination > .active > a:active:hover,
body.dark .pagination > .active > a:active:hover,
.pagination > .active > a:active:focus,
body.dark .pagination > .active > a:active:focus {
	border-color: #0077b3 #0077b3 #0077b3;
	background-color: #0077b3;
}

.pagination > .active > a.dropdown-toggle,
body.dark .pagination > .active > a.dropdown-toggle {
	border-left-color: #0077b3;
}

.pagination > .active > a[disabled],
body.dark .pagination > .active > a[disabled],
.pagination > .active > a[disabled]:hover,
body.dark .pagination > .active > a[disabled]:hover,
.pagination > .active > a[disabled]:active,
body.dark .pagination > .active > a[disabled]:active,
.pagination > .active > a[disabled]:focus,
body.dark .pagination > .active > a[disabled]:focus {
	border-color: #33bbff;
	background-color: #33bbff;
}

html .alert-primary {
	background-color: #0088cc;
	border-color: #007ebd;
	color: #ffffff;
}

html .alert-primary .alert-link {
	color: #ffffff;
}

html .alert-secondary {
	background-color: #e36159;
	border-color: #e1554c;
	color: #ffffff;
}

html .alert-secondary .alert-link {
	color: #ffffff;
}

html .alert-tertiary {
	background-color: #2baab1;
	border-color: #289ea5;
	color: #ffffff;
}

html .alert-tertiary .alert-link {
	color: #ffffff;
}

html .alert-quaternary {
	background-color: #383f48;
	border-color: #31373f;
	color: #ffffff;
}

html .alert-quaternary .alert-link {
	color: #ffffff;
}

html .alert-dark {
	background-color: #2e353e;
	border-color: #272d35;
	color: #ffffff;
}

html .alert-dark .alert-link {
	color: #ffffff;
}

html .alert-light {
	background-color: #ffffff;
	border-color: #f7f7f7;
	color: #777777;
}

html .alert-light .alert-link {
	color: #777777;
}

html .progress-bar-primary {
	background-color: #0088cc;
}

html .progress-bar-secondary {
	background-color: #e36159;
}

html .progress-bar-tertiary {
	background-color: #2baab1;
}

html .progress-bar-quaternary {
	background-color: #383f48;
}

html .progress-bar-dark {
	background-color: #2e353e;
}

html .progress-bar-light {
	background-color: #ffffff;
}

html section.section-primary {
	background-color: #0088cc !important;
	border-color: #0077b3 !important;
}

html section.section-primary h1,
html section.section-primary h2,
html section.section-primary h3,
html section.section-primary h4,
html section.section-primary h5,
html section.section-primary h6 {
	color: #ffffff;
}

html section.section-primary p {
	color: #e6e6e6;
}

html section.section-primary-scale-2 {
	background-color: #006699 !important;
	border-color: #005580 !important;
}

html section.section-primary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #006699;
}

html section.section-secondary {
	background-color: #e36159 !important;
	border-color: #df4c43 !important;
}

html section.section-secondary h1,
html section.section-secondary h2,
html section.section-secondary h3,
html section.section-secondary h4,
html section.section-secondary h5,
html section.section-secondary h6 {
	color: #ffffff;
}

html section.section-secondary p {
	color: #e6e6e6;
}

html section.section-secondary-scale-2 {
	background-color: #dc372d !important;
	border-color: #cd2c23 !important;
}

html section.section-secondary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #dc372d;
}

html section.section-tertiary {
	background-color: #2baab1 !important;
	border-color: #26969c !important;
}

html section.section-tertiary h1,
html section.section-tertiary h2,
html section.section-tertiary h3,
html section.section-tertiary h4,
html section.section-tertiary h5,
html section.section-tertiary h6 {
	color: #ffffff;
}

html section.section-tertiary p {
	color: #e6e6e6;
}

html section.section-tertiary-scale-2 {
	background-color: #218388 !important;
	border-color: #1c6f73 !important;
}

html section.section-tertiary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #218388;
}

html section.section-quaternary {
	background-color: #383f48 !important;
	border-color: #2d323a !important;
}

html section.section-quaternary h1,
html section.section-quaternary h2,
html section.section-quaternary h3,
html section.section-quaternary h4,
html section.section-quaternary h5,
html section.section-quaternary h6 {
	color: #ffffff;
}

html section.section-quaternary p {
	color: #e6e6e6;
}

html section.section-quaternary-scale-2 {
	background-color: #22262b !important;
	border-color: #17191d !important;
}

html section.section-quaternary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #22262b;
}

html section.section-dark {
	background-color: #2e353e !important;
	border-color: #23282f !important;
}

html section.section-dark h1,
html section.section-dark h2,
html section.section-dark h3,
html section.section-dark h4,
html section.section-dark h5,
html section.section-dark h6 {
	color: #ffffff;
}

html section.section-dark p {
	color: #e6e6e6;
}

html section.section-dark-scale-2 {
	background-color: #181c21 !important;
	border-color: #0d0f12 !important;
}

html section.section-dark-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #181c21;
}

html section.section-light {
	background-color: #ffffff !important;
	border-color: #f2f2f2 !important;
}

html section.section-light h1,
html section.section-light h2,
html section.section-light h3,
html section.section-light h4,
html section.section-light h5,
html section.section-light h6 {
	color: #777777;
}

html section.section-light p {
	color: #5e5e5e;
}

html section.section-light-scale-2 {
	background-color: #e6e6e6 !important;
	border-color: #d9d9d9 !important;
}

html section.section-light-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #e6e6e6;
}

html section.section-default-scale-1 {
	background-color: #dbdbdb !important;
	border-top-color: #d3d3d3 !important;
}

html section.section-default-scale-2 {
	background-color: #c1c1c1 !important;
	border-top-color: #b9b9b9 !important;
}

html section.section-default-scale-3 {
	background-color: #a8a8a8 !important;
	border-top-color: #a0a0a0 !important;
}

html section.section-default-scale-4 {
	background-color: #8e8e8e !important;
	border-top-color: #868686 !important;
}

html section.section-default-scale-5 {
	background-color: #757575 !important;
	border-top-color: #6d6d6d !important;
}

html section.section-default-scale-6 {
	background-color: #5b5b5b !important;
	border-top-color: #535353 !important;
}

html section.section-default-scale-7 {
	background-color: #424242 !important;
	border-top-color: #3a3a3a !important;
}

html section.section-default-scale-8 {
	background-color: #282828 !important;
	border-top-color: #202020 !important;
}

html section.section-default-scale-9 {
	background-color: #0f0f0f !important;
	border-top-color: #070707 !important;
}

html.dark section.section-default-scale-1 {
	background-color: #333a44 !important;
	border-top-color: #2c323b !important;
}

html.dark section.section-default-scale-2 {
	background-color: #495362 !important;
	border-top-color: #424b59 !important;
}

html.dark section.section-default-scale-3 {
	background-color: #5e6b7f !important;
	border-top-color: #586476 !important;
}

html.dark section.section-default-scale-4 {
	background-color: #77849a !important;
	border-top-color: #6e7d93 !important;
}

html.dark section.section-default-scale-5 {
	background-color: #949faf !important;
	border-top-color: #8b97a9 !important;
}

html.dark section.section-default-scale-6 {
	background-color: #b1b9c5 !important;
	border-top-color: #a8b1be !important;
}

html.dark section.section-default-scale-7 {
	background-color: #ced3db !important;
	border-top-color: #c5cbd4 !important;
}

html.dark section.section-default-scale-8 {
	background-color: #ebeef1 !important;
	border-top-color: #e3e6ea !important;
}

html.dark section.section-default-scale-9 {
	background-color: #ffffff !important;
	border-top-color: #f7f7f7 !important;
}

section.page-header h1 {
	border-bottom-color: #0088cc;
}

section.page-header.custom-product {
	background-color: #0077b3;
	border-top-color: #0081c2;
}

html .page-header-color.page-header-primary {
	background-color: #0088cc;
	border-bottom-color: #0088cc;
	color: #ffffff;
}

html .page-header-color.page-header-primary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-primary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-primary a {
	color: #ffffff;
}

html .page-header-color.page-header-primary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-secondary {
	background-color: #e36159;
	border-bottom-color: #e36159;
	color: #ffffff;
}

html .page-header-color.page-header-secondary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-secondary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-secondary a {
	color: #ffffff;
}

html .page-header-color.page-header-secondary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary {
	background-color: #2baab1;
	border-bottom-color: #2baab1;
	color: #ffffff;
}

html .page-header-color.page-header-tertiary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary a {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary {
	background-color: #383f48;
	border-bottom-color: #383f48;
	color: #ffffff;
}

html .page-header-color.page-header-quaternary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary a {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-dark {
	background-color: #2e353e;
	border-bottom-color: #2e353e;
	color: #ffffff;
}

html .page-header-color.page-header-dark h1 {
	color: #ffffff;
}

html .page-header-color.page-header-dark h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-dark a {
	color: #ffffff;
}

html .page-header-color.page-header-dark .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-light {
	background-color: #ffffff;
	border-bottom-color: #ffffff;
	color: #777777;
}

html .page-header-color.page-header-light h1 {
	color: #777777;
}

html .page-header-color.page-header-light h1 span {
	color: #777777;
}

html .page-header-color.page-header-light a {
	color: #777777;
}

html .page-header-color.page-header-light .breadcrumb > .active {
	color: #777777;
}

html .toggle-primary .toggle label {
	color: #0088cc;
	border-left-color: #0088cc;
	border-right-color: #0088cc;
}

html .toggle-primary .toggle.active > label {
	background-color: #0088cc;
	border-color: #0088cc;
	color: #ffffff;
}

html .toggle-primary.toggle-simple .toggle > label:after {
	background-color: #0088cc;
}

html .toggle-secondary .toggle label {
	color: #e36159;
	border-left-color: #e36159;
	border-right-color: #e36159;
}

html .toggle-secondary .toggle.active > label {
	background-color: #e36159;
	border-color: #e36159;
	color: #ffffff;
}

html .toggle-secondary.toggle-simple .toggle > label:after {
	background-color: #e36159;
}

html .toggle-tertiary .toggle label {
	color: #2baab1;
	border-left-color: #2baab1;
	border-right-color: #2baab1;
}

html .toggle-tertiary .toggle.active > label {
	background-color: #2baab1;
	border-color: #2baab1;
	color: #ffffff;
}

html .toggle-tertiary.toggle-simple .toggle > label:after {
	background-color: #2baab1;
}

html .toggle-quaternary .toggle label {
	color: #383f48;
	border-left-color: #383f48;
	border-right-color: #383f48;
}

html .toggle-quaternary .toggle.active > label {
	background-color: #383f48;
	border-color: #383f48;
	color: #ffffff;
}

html .toggle-quaternary.toggle-simple .toggle > label:after {
	background-color: #383f48;
}

html .toggle-dark .toggle label {
	color: #2e353e;
	border-left-color: #2e353e;
	border-right-color: #2e353e;
}

html .toggle-dark .toggle.active > label {
	background-color: #2e353e;
	border-color: #2e353e;
	color: #ffffff;
}

html .toggle-dark.toggle-simple .toggle > label:after {
	background-color: #2e353e;
}

html .toggle-light .toggle label {
	color: #ffffff;
	border-left-color: #ffffff;
	border-right-color: #ffffff;
}

html .toggle-light .toggle.active > label {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #777777;
}

html .toggle-light.toggle-simple .toggle > label:after {
	background-color: #ffffff;
}

.thumb-info .thumb-info-type {
	background-color: #0088cc;
}

.thumb-info .thumb-info-action-icon {
	background-color: #0088cc;
}

.thumb-info-social-icons a {
	background-color: #0088cc;
}

.thumbnail .zoom {
	background-color: #0088cc;
}

.img-thumbnail .zoom {
	background-color: #0088cc;
}

html .thumb-info .thumb-info-action-icon-primary {
	background-color: #0088cc;
}

html .thumb-info .thumb-info-action-icon-primary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-secondary {
	background-color: #e36159;
}

html .thumb-info .thumb-info-action-icon-secondary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-tertiary {
	background-color: #2baab1;
}

html .thumb-info .thumb-info-action-icon-tertiary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-quaternary {
	background-color: #383f48;
}

html .thumb-info .thumb-info-action-icon-quaternary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-dark {
	background-color: #2e353e;
}

html .thumb-info .thumb-info-action-icon-dark i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-light {
	background-color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-light i {
	color: #777777;
}

.thumb-info-ribbon {
	background: #0088cc;
}

.thumb-info-ribbon:before {
	border-right-color: #005580;
	border-left-color: #005580;
}

.inverted {
	background-color: #0088cc;
}

html .inverted-primary {
	background-color: #0088cc;
}

html .inverted-secondary {
	background-color: #e36159;
}

html .inverted-tertiary {
	background-color: #2baab1;
}

html .inverted-quaternary {
	background-color: #383f48;
}

html .inverted-dark {
	background-color: #2e353e;
}

html .inverted-light {
	background-color: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #0074ad;
}

.owl-carousel.show-nav-title .owl-nav [class*="owl-"] {
	color: #0088cc;
}

.owl-carousel .owl-nav [class*="owl-"] {
	color: #ffffff;
	background-color: #0088cc;
	border-color: #0088cc #0088cc #006699;
}

.owl-carousel .owl-nav [class*="owl-"]:hover {
	border-color: #0099e6 #0099e6 #0077b3;
	background-color: #0099e6;
}

.owl-carousel .owl-nav [class*="owl-"]:active,
.owl-carousel .owl-nav [class*="owl-"]:focus,
.owl-carousel .owl-nav [class*="owl-"]:active:hover,
.owl-carousel .owl-nav [class*="owl-"]:active:focus {
	border-color: #0077b3 #0077b3 #0077b3;
	background-color: #0077b3;
}

.owl-carousel .owl-nav [class*="owl-"].dropdown-toggle {
	border-left-color: #0077b3;
}

.owl-carousel .owl-nav [class*="owl-"][disabled],
.owl-carousel .owl-nav [class*="owl-"][disabled]:hover,
.owl-carousel .owl-nav [class*="owl-"][disabled]:active,
.owl-carousel .owl-nav [class*="owl-"][disabled]:focus {
	border-color: #33bbff;
	background-color: #33bbff;
}

html body .tabs .nav-tabs a,
html.dark body .tabs .nav-tabs a,
html body .tabs .nav-tabs a:hover,
html.dark body .tabs .nav-tabs a:hover {
	color: #0088cc;
}

html body .tabs .nav-tabs a:hover,
html.dark body .tabs .nav-tabs a:hover,
html body .tabs .nav-tabs a:focus,
html.dark body .tabs .nav-tabs a:focus {
	border-top-color: #0088cc;
}

html body .tabs .nav-tabs li.active a,
html.dark body .tabs .nav-tabs li.active a {
	border-top-color: #0088cc;
	color: #0088cc;
}

html body .tabs .nav-tabs.nav-justified a:hover,
html.dark body .tabs .nav-tabs.nav-justified a:hover,
html body .tabs .nav-tabs.nav-justified a:focus,
html.dark body .tabs .nav-tabs.nav-justified a:focus {
	border-top-color: #0088cc;
}

html body .tabs.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li a:hover,
html body .tabs.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs.tabs-bottom .nav-tabs li.active a,
html body .tabs.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom-color: #0088cc;
}

html body .tabs.tabs-vertical.tabs-left .nav-tabs li a:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li a:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:focus,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:focus {
	border-left-color: #0088cc;
}

html body .tabs.tabs-vertical.tabs-right .nav-tabs li a:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li a:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:focus,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:focus {
	border-right-color: #0088cc;
}

html body .tabs.tabs-simple .nav-tabs > li.active a,
html.dark body .tabs.tabs-simple .nav-tabs > li.active a,
html body .tabs.tabs-simple .nav-tabs > li.active a:focus,
html.dark body .tabs.tabs-simple .nav-tabs > li.active a:focus,
html body .tabs.tabs-simple .nav-tabs > li a:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li a:hover,
html body .tabs.tabs-simple .nav-tabs > li.active a:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li.active a:hover {
	border-top-color: #0088cc;
	color: #0088cc;
}

html body .tabs-primary .nav-tabs li a,
html.dark body .tabs-primary .nav-tabs li a,
html body .tabs-primary .nav-tabs.nav-justified li a,
html.dark body .tabs-primary .nav-tabs.nav-justified li a,
html body .tabs-primary .nav-tabs li a:hover,
html.dark body .tabs-primary .nav-tabs li a:hover,
html body .tabs-primary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li a:hover {
	color: #0088cc;
}

html body .tabs-primary .nav-tabs li a:hover,
html.dark body .tabs-primary .nav-tabs li a:hover,
html body .tabs-primary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li a:hover {
	border-top-color: #0088cc;
}

html body .tabs-primary .nav-tabs li.active a,
html.dark body .tabs-primary .nav-tabs li.active a,
html body .tabs-primary .nav-tabs.nav-justified li.active a,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active a,
html body .tabs-primary .nav-tabs li.active a:hover,
html.dark body .tabs-primary .nav-tabs li.active a:hover,
html body .tabs-primary .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active a:hover,
html body .tabs-primary .nav-tabs li.active a:focus,
html.dark body .tabs-primary .nav-tabs li.active a:focus,
html body .tabs-primary .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #0088cc;
	color: #0088cc;
}

html body .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #0088cc;
}

html body .tabs-primary.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active a,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-primary.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #0088cc;
}

html body .tabs-primary.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li a:hover {
	border-left-color: #0088cc;
}

html body .tabs-primary.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active a,
html body .tabs-primary.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-primary.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #0088cc;
}

html body .tabs-primary.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li a:hover {
	border-right-color: #0088cc;
}

html body .tabs-primary.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active a,
html body .tabs-primary.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-primary.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #0088cc;
}

html body .tabs-secondary .nav-tabs li a,
html.dark body .tabs-secondary .nav-tabs li a,
html body .tabs-secondary .nav-tabs.nav-justified li a,
html.dark body .tabs-secondary .nav-tabs.nav-justified li a,
html body .tabs-secondary .nav-tabs li a:hover,
html.dark body .tabs-secondary .nav-tabs li a:hover,
html body .tabs-secondary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li a:hover {
	color: #e36159;
}

html body .tabs-secondary .nav-tabs li a:hover,
html.dark body .tabs-secondary .nav-tabs li a:hover,
html body .tabs-secondary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li a:hover {
	border-top-color: #e36159;
}

html body .tabs-secondary .nav-tabs li.active a,
html.dark body .tabs-secondary .nav-tabs li.active a,
html body .tabs-secondary .nav-tabs.nav-justified li.active a,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active a,
html body .tabs-secondary .nav-tabs li.active a:hover,
html.dark body .tabs-secondary .nav-tabs li.active a:hover,
html body .tabs-secondary .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active a:hover,
html body .tabs-secondary .nav-tabs li.active a:focus,
html.dark body .tabs-secondary .nav-tabs li.active a:focus,
html body .tabs-secondary .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #e36159;
	color: #e36159;
}

html body .tabs-secondary.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li a:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #e36159;
}

html body .tabs-secondary.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active a,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-secondary.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #e36159;
}

html body .tabs-secondary.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li a:hover {
	border-left-color: #e36159;
}

html body .tabs-secondary.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active a,
html body .tabs-secondary.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #e36159;
}

html body .tabs-secondary.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li a:hover {
	border-right-color: #e36159;
}

html body .tabs-secondary.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active a,
html body .tabs-secondary.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #e36159;
}

html body .tabs-tertiary .nav-tabs li a,
html.dark body .tabs-tertiary .nav-tabs li a,
html body .tabs-tertiary .nav-tabs.nav-justified li a,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li a,
html body .tabs-tertiary .nav-tabs li a:hover,
html.dark body .tabs-tertiary .nav-tabs li a:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li a:hover {
	color: #2baab1;
}

html body .tabs-tertiary .nav-tabs li a:hover,
html.dark body .tabs-tertiary .nav-tabs li a:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li a:hover {
	border-top-color: #2baab1;
}

html body .tabs-tertiary .nav-tabs li.active a,
html.dark body .tabs-tertiary .nav-tabs li.active a,
html body .tabs-tertiary .nav-tabs.nav-justified li.active a,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active a,
html body .tabs-tertiary .nav-tabs li.active a:hover,
html.dark body .tabs-tertiary .nav-tabs li.active a:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active a:hover,
html body .tabs-tertiary .nav-tabs li.active a:focus,
html.dark body .tabs-tertiary .nav-tabs li.active a:focus,
html body .tabs-tertiary .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #2baab1;
	color: #2baab1;
}

html body .tabs-tertiary.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li a:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #2baab1;
}

html body .tabs-tertiary.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active a,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-tertiary.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li a:hover {
	border-left-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active a,
html body .tabs-tertiary.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li a:hover {
	border-right-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active a,
html body .tabs-tertiary.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #2baab1;
}

html body .tabs-quaternary .nav-tabs li a,
html.dark body .tabs-quaternary .nav-tabs li a,
html body .tabs-quaternary .nav-tabs.nav-justified li a,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li a,
html body .tabs-quaternary .nav-tabs li a:hover,
html.dark body .tabs-quaternary .nav-tabs li a:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li a:hover {
	color: #383f48;
}

html body .tabs-quaternary .nav-tabs li a:hover,
html.dark body .tabs-quaternary .nav-tabs li a:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li a:hover {
	border-top-color: #383f48;
}

html body .tabs-quaternary .nav-tabs li.active a,
html.dark body .tabs-quaternary .nav-tabs li.active a,
html body .tabs-quaternary .nav-tabs.nav-justified li.active a,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active a,
html body .tabs-quaternary .nav-tabs li.active a:hover,
html.dark body .tabs-quaternary .nav-tabs li.active a:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active a:hover,
html body .tabs-quaternary .nav-tabs li.active a:focus,
html.dark body .tabs-quaternary .nav-tabs li.active a:focus,
html body .tabs-quaternary .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #383f48;
	color: #383f48;
}

html body .tabs-quaternary.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li a:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #383f48;
}

html body .tabs-quaternary.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active a,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-quaternary.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li a:hover {
	border-left-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active a,
html body .tabs-quaternary.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-quaternary.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li a:hover {
	border-right-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active a,
html body .tabs-quaternary.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-quaternary.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #383f48;
}

html body .tabs-dark .nav-tabs li a,
html.dark body .tabs-dark .nav-tabs li a,
html body .tabs-dark .nav-tabs.nav-justified li a,
html.dark body .tabs-dark .nav-tabs.nav-justified li a,
html body .tabs-dark .nav-tabs li a:hover,
html.dark body .tabs-dark .nav-tabs li a:hover,
html body .tabs-dark .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li a:hover {
	color: #2e353e;
}

html body .tabs-dark .nav-tabs li a:hover,
html.dark body .tabs-dark .nav-tabs li a:hover,
html body .tabs-dark .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li a:hover {
	border-top-color: #2e353e;
}

html body .tabs-dark .nav-tabs li.active a,
html.dark body .tabs-dark .nav-tabs li.active a,
html body .tabs-dark .nav-tabs.nav-justified li.active a,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active a,
html body .tabs-dark .nav-tabs li.active a:hover,
html.dark body .tabs-dark .nav-tabs li.active a:hover,
html body .tabs-dark .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active a:hover,
html body .tabs-dark .nav-tabs li.active a:focus,
html.dark body .tabs-dark .nav-tabs li.active a:focus,
html body .tabs-dark .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #2e353e;
	color: #2e353e;
}

html body .tabs-dark.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li a:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #2e353e;
}

html body .tabs-dark.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active a,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-dark.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #2e353e;
}

html body .tabs-dark.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li a:hover {
	border-left-color: #2e353e;
}

html body .tabs-dark.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active a,
html body .tabs-dark.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-dark.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #2e353e;
}

html body .tabs-dark.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li a:hover {
	border-right-color: #2e353e;
}

html body .tabs-dark.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active a,
html body .tabs-dark.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-dark.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #2e353e;
}

html body .tabs-light .nav-tabs li a,
html.dark body .tabs-light .nav-tabs li a,
html body .tabs-light .nav-tabs.nav-justified li a,
html.dark body .tabs-light .nav-tabs.nav-justified li a,
html body .tabs-light .nav-tabs li a:hover,
html.dark body .tabs-light .nav-tabs li a:hover,
html body .tabs-light .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li a:hover {
	color: #ffffff;
}

html body .tabs-light .nav-tabs li a:hover,
html.dark body .tabs-light .nav-tabs li a:hover,
html body .tabs-light .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li a:hover {
	border-top-color: #ffffff;
}

html body .tabs-light .nav-tabs li.active a,
html.dark body .tabs-light .nav-tabs li.active a,
html body .tabs-light .nav-tabs.nav-justified li.active a,
html.dark body .tabs-light .nav-tabs.nav-justified li.active a,
html body .tabs-light .nav-tabs li.active a:hover,
html.dark body .tabs-light .nav-tabs li.active a:hover,
html body .tabs-light .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li.active a:hover,
html body .tabs-light .nav-tabs li.active a:focus,
html.dark body .tabs-light .nav-tabs li.active a:focus,
html body .tabs-light .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-light .nav-tabs.nav-justified li.active a:focus {
	border-top-color: #ffffff;
	color: #ffffff;
}

html body .tabs-light.tabs-bottom .nav-tabs li a:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li a:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li a:hover {
	border-bottom-color: #ffffff;
}

html body .tabs-light.tabs-bottom .nav-tabs li.active a,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active a,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-light.tabs-bottom .nav-tabs li.active a:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-light.tabs-bottom .nav-tabs li.active a:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
	border-bottom-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-left li a:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li a:hover {
	border-left-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-left li.active a,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active a,
html body .tabs-light.tabs-vertical.tabs-left li.active a:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-light.tabs-vertical.tabs-left li.active a:focus,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active a:focus {
	border-left-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-right li a:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li a:hover {
	border-right-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-right li.active a,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active a,
html body .tabs-light.tabs-vertical.tabs-right li.active a:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-light.tabs-vertical.tabs-right li.active a:focus,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active a:focus {
	border-right-color: #ffffff;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active a:focus {
	border-right-color: #0088cc;
	border-left-color: transparent;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active a:focus {
	border-right-color: transparent;
	border-left-color: #0088cc;
}

.list.list-icons li > .fa:first-child,
.list.list-icons li a:first-child > .fa:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .icons:first-child {
	color: #0088cc;
	border-color: #0088cc;
}

.list.list-icons.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #0088cc;
}

.list.list-ordened li:before {
	color: #0088cc;
	border-color: #0088cc;
}

.list.list-ordened.list-ordened-style-3 li:before {
	background-color: #0088cc;
}

html .list-primary.list-icons li > .fa:first-child,
html .list-primary.list-icons li a:first-child > .fa:first-child,
html .list-primary.list-icons li > .icons:first-child,
html .list-primary.list-icons li a:first-child > .icons:first-child {
	color: #0088cc;
	border-color: #0088cc;
}

html .list-primary.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-primary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #0088cc;
	color: #ffffff;
}

html .list-primary.list-ordened li:before {
	color: #0088cc;
}

html .list-primary.list-ordened.list-ordened-style-3 li:before {
	background-color: #0088cc;
	color: #ffffff;
}

html .list-secondary.list-icons li > .fa:first-child,
html .list-secondary.list-icons li a:first-child > .fa:first-child,
html .list-secondary.list-icons li > .icons:first-child,
html .list-secondary.list-icons li a:first-child > .icons:first-child {
	color: #e36159;
	border-color: #e36159;
}

html .list-secondary.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-secondary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #e36159;
	color: #ffffff;
}

html .list-secondary.list-ordened li:before {
	color: #e36159;
}

html .list-secondary.list-ordened.list-ordened-style-3 li:before {
	background-color: #e36159;
	color: #ffffff;
}

html .list-tertiary.list-icons li > .fa:first-child,
html .list-tertiary.list-icons li a:first-child > .fa:first-child,
html .list-tertiary.list-icons li > .icons:first-child,
html .list-tertiary.list-icons li a:first-child > .icons:first-child {
	color: #2baab1;
	border-color: #2baab1;
}

html .list-tertiary.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #2baab1;
	color: #ffffff;
}

html .list-tertiary.list-ordened li:before {
	color: #2baab1;
}

html .list-tertiary.list-ordened.list-ordened-style-3 li:before {
	background-color: #2baab1;
	color: #ffffff;
}

html .list-quaternary.list-icons li > .fa:first-child,
html .list-quaternary.list-icons li a:first-child > .fa:first-child,
html .list-quaternary.list-icons li > .icons:first-child,
html .list-quaternary.list-icons li a:first-child > .icons:first-child {
	color: #383f48;
	border-color: #383f48;
}

html .list-quaternary.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #383f48;
	color: #ffffff;
}

html .list-quaternary.list-ordened li:before {
	color: #383f48;
}

html .list-quaternary.list-ordened.list-ordened-style-3 li:before {
	background-color: #383f48;
	color: #ffffff;
}

html .list-dark.list-icons li > .fa:first-child,
html .list-dark.list-icons li a:first-child > .fa:first-child,
html .list-dark.list-icons li > .icons:first-child,
html .list-dark.list-icons li a:first-child > .icons:first-child {
	color: #2e353e;
	border-color: #2e353e;
}

html .list-dark.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-dark.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #2e353e;
	color: #ffffff;
}

html .list-dark.list-ordened li:before {
	color: #2e353e;
}

html .list-dark.list-ordened.list-ordened-style-3 li:before {
	background-color: #2e353e;
	color: #ffffff;
}

html .list-light.list-icons li > .fa:first-child,
html .list-light.list-icons li a:first-child > .fa:first-child,
html .list-light.list-icons li > .icons:first-child,
html .list-light.list-icons li a:first-child > .icons:first-child {
	color: #ffffff;
	border-color: #ffffff;
}

html .list-light.list-icons.list-icons-style-3 li > .fa:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
html .list-light.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #ffffff;
	color: #777777;
}

html .list-light.list-ordened li:before {
	color: #ffffff;
}

html .list-light.list-ordened.list-ordened-style-3 li:before {
	background-color: #ffffff;
	color: #777777;
}

.parallax blockquote i.fa-quote-left {
	color: #0088cc;
}

section.video blockquote i.fa-quote-left {
	color: #0088cc;
}

.panel-group .panel-heading a {
	color: #0088cc;
}

html .panel-group.panel-group-primary .panel-heading {
	background-color: #0088cc !important;
}

html .panel-group.panel-group-primary .panel-heading a {
	color: #ffffff;
}

html .panel-group.panel-group-secondary .panel-heading {
	background-color: #e36159 !important;
}

html .panel-group.panel-group-secondary .panel-heading a {
	color: #ffffff;
}

html .panel-group.panel-group-tertiary .panel-heading {
	background-color: #2baab1 !important;
}

html .panel-group.panel-group-tertiary .panel-heading a {
	color: #ffffff;
}

html .panel-group.panel-group-quaternary .panel-heading {
	background-color: #383f48 !important;
}

html .panel-group.panel-group-quaternary .panel-heading a {
	color: #ffffff;
}

html .panel-group.panel-group-dark .panel-heading {
	background-color: #2e353e !important;
}

html .panel-group.panel-group-dark .panel-heading a {
	color: #ffffff;
}

html .panel-group.panel-group-light .panel-heading {
	background-color: #ffffff !important;
}

html .panel-group.panel-group-light .panel-heading a {
	color: #777777;
}

html .divider.divider-primary .fa,
html .divider.divider-primary .icons {
	color: #0088cc;
}

html .divider.divider-primary.divider-small hr {
	background: #0088cc;
}

html .divider.divider-secondary .fa,
html .divider.divider-secondary .icons {
	color: #e36159;
}

html .divider.divider-secondary.divider-small hr {
	background: #e36159;
}

html .divider.divider-tertiary .fa,
html .divider.divider-tertiary .icons {
	color: #2baab1;
}

html .divider.divider-tertiary.divider-small hr {
	background: #2baab1;
}

html .divider.divider-quaternary .fa,
html .divider.divider-quaternary .icons {
	color: #383f48;
}

html .divider.divider-quaternary.divider-small hr {
	background: #383f48;
}

html .divider.divider-dark .fa,
html .divider.divider-dark .icons {
	color: #2e353e;
}

html .divider.divider-dark.divider-small hr {
	background: #2e353e;
}

html .divider.divider-light .fa,
html .divider.divider-light .icons {
	color: #ffffff;
}

html .divider.divider-light.divider-small hr {
	background: #ffffff;
}

html .divider.divider-style-2.divider-primary .fa,
html .divider.divider-style-2.divider-primary .icons {
	background: #0088cc;
	color: #ffffff;
}

html .divider.divider-style-2.divider-secondary .fa,
html .divider.divider-style-2.divider-secondary .icons {
	background: #e36159;
	color: #ffffff;
}

html .divider.divider-style-2.divider-tertiary .fa,
html .divider.divider-style-2.divider-tertiary .icons {
	background: #2baab1;
	color: #ffffff;
}

html .divider.divider-style-2.divider-quaternary .fa,
html .divider.divider-style-2.divider-quaternary .icons {
	background: #383f48;
	color: #ffffff;
}

html .divider.divider-style-2.divider-dark .fa,
html .divider.divider-style-2.divider-dark .icons {
	background: #2e353e;
	color: #ffffff;
}

html .divider.divider-style-2.divider-light .fa,
html .divider.divider-style-2.divider-light .icons {
	background: #ffffff;
	color: #777777;
}

html .divider.divider-style-3.divider-primary .fa,
html .divider.divider-style-3.divider-primary .icons {
	border-color: #0088cc;
}

html .divider.divider-style-3.divider-secondary .fa,
html .divider.divider-style-3.divider-secondary .icons {
	border-color: #e36159;
}

html .divider.divider-style-3.divider-tertiary .fa,
html .divider.divider-style-3.divider-tertiary .icons {
	border-color: #2baab1;
}

html .divider.divider-style-3.divider-quaternary .fa,
html .divider.divider-style-3.divider-quaternary .icons {
	border-color: #383f48;
}

html .divider.divider-style-3.divider-dark .fa,
html .divider.divider-style-3.divider-dark .icons {
	border-color: #2e353e;
}

html .divider.divider-style-3.divider-light .fa,
html .divider.divider-style-3.divider-light .icons {
	border-color: #ffffff;
}

#header .tip {
	background: #0088cc;
	color: #ffffff;
}

#header .tip:before {
	border-right-color: #0088cc;
}

html #header .tip-primary {
	background: #0088cc !important;
	color: #ffffff !important;
}

html #header .tip-primary:before {
	border-right-color: #0088cc !important;
}

html #header .tip-secondary {
	background: #e36159 !important;
	color: #ffffff !important;
}

html #header .tip-secondary:before {
	border-right-color: #e36159 !important;
}

html #header .tip-tertiary {
	background: #2baab1 !important;
	color: #ffffff !important;
}

html #header .tip-tertiary:before {
	border-right-color: #2baab1 !important;
}

html #header .tip-quaternary {
	background: #383f48 !important;
	color: #ffffff !important;
}

html #header .tip-quaternary:before {
	border-right-color: #383f48 !important;
}

html #header .tip-dark {
	background: #2e353e !important;
	color: #ffffff !important;
}

html #header .tip-dark:before {
	border-right-color: #2e353e !important;
}

html #header .tip-light {
	background: #ffffff !important;
	color: #777777 !important;
}

html #header .tip-light:before {
	border-right-color: #ffffff !important;
}

html[dir="rtl"] #header .tip.skin:before {
	border-left-color: #0088cc;
}

#header .header-btn-collapse-nav {
	background: #0088cc;
}

@media (min-width: 992px) {
	#header .header-nav-main nav > ul > li.open > a,
	#header .header-nav-main nav > ul > li:hover > a {
		background: #0088cc;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
		border-top-color: #0088cc;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #0088cc;
	}

	#header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu {
		border-top-color: #0088cc;
	}

	#header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent #0088cc transparent;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
		border-top-color: #0088cc;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu li a {
		color: #0088cc;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:not(:hover).active > a {
		color: #0088cc;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:not(:hover).active > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:hover > a {
		background-color: #0088cc;
	}

	#header .header-nav.header-nav-top-line nav > ul li.active > a,
	#header .header-nav.header-nav-top-line nav > ul li:hover > a {
		color: #0088cc;
	}

	#header .header-nav.header-nav-top-line nav > ul li.active > a:before,
	#header .header-nav.header-nav-top-line nav > ul li:hover > a:before {
		background: #0088cc;
	}

	#header .header-nav.header-nav-top-line nav > ul li.active > a.dropdown-toggle:after,
	#header .header-nav.header-nav-top-line nav > ul li:hover > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul li.active > a,
	#header .header-nav.header-nav-dark-dropdown nav > ul li:hover > a {
		color: #0088cc;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul li.active > a.dropdown-toggle:after,
	#header .header-nav.header-nav-dark-dropdown nav > ul li:hover > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	html.side-header #header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
	html.side-header #header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
		border-left-color: #0088cc;
	}

	html.side-header #header .header-nav-main nav > ul li.dropdown > a.dropdown-toggle:after {
		border-color: transparent transparent transparent #0088cc;
	}

	html.side-header #header .header-nav-main nav > ul li.dropdown.active > a.dropdown-toggle:after,
	html.side-header #header .header-nav-main nav > ul li.dropdown:hover > a.dropdown-toggle:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html.side-header-right #header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
	html.side-header-right #header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
		border-right-color: #0088cc;
	}

	html.side-header-right #header .header-nav-main nav > ul li.dropdown > a.dropdown-toggle:after {
		border-color: transparent #0088cc transparent transparent;
	}

	html.side-header-right #header .header-nav-main nav > ul li.dropdown.active > a.dropdown-toggle:after,
	html.side-header-right #header .header-nav-main nav > ul li.dropdown:hover > a.dropdown-toggle:after {
		border-color: transparent #ffffff transparent transparent;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a {
		color: #0088cc;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover {
		background: #0088cc;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:after {
		border-color: #0088cc transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
		background: #0088cc;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu {
		border-top-color: #0088cc;
		background-color: #0088cc;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #0099e6;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li:hover > a {
		background: #0099e6;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-primary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #e36159 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a {
		color: #e36159;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover {
		background: #e36159;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:after {
		border-color: #e36159 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
		background: #e36159;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu {
		border-top-color: #e36159;
		background-color: #e36159;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #e7766f;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li:hover > a {
		background: #e7766f;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-secondary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #2baab1 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a {
		color: #2baab1;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover {
		background: #2baab1;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:after {
		border-color: #2baab1 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
		background: #2baab1;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu {
		border-top-color: #2baab1;
		background-color: #2baab1;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #30bec6;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li:hover > a {
		background: #30bec6;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-tertiary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #383f48 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a {
		color: #383f48;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:hover {
		background: #383f48;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:after {
		border-color: #383f48 transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a {
		background: #383f48;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu {
		border-top-color: #383f48;
		background-color: #383f48;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #434c56;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li:hover > a {
		background: #434c56;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-quaternary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #2e353e transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a {
		color: #2e353e;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover {
		background: #2e353e;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:after {
		border-color: #2e353e transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
		background: #2e353e;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu {
		border-top-color: #2e353e;
		background-color: #2e353e;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #39424d;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li:hover > a {
		background: #39424d;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-dark .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav-main nav > ul > li.active > a.dropdown-toggle:after,
	html #header .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #ffffff transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a {
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover {
		background: #ffffff;
		color: #777777;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:after {
		border-color: #ffffff transparent transparent transparent;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.active > a,
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
		background: #ffffff;
		color: #777777;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu {
		border-top-color: #ffffff;
		background-color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li a {
		color: #777777;
		border-bottom-color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li:hover > a {
		background: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #777777;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-light .dropdown-mega-sub-title {
		color: #777777;
		opacity: 0.70;
	}

	html[dir="rtl"] #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent #0088cc transparent transparent;
	}
}

@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li > a,
	#header .header-nav-main nav > ul > li > a:focus,
	#header .header-nav-main nav > ul > li > a:hover {
		color: #0088cc;
	}

	#header .header-nav-main nav > ul > li.active > a,
	#header .header-nav-main nav > ul > li.active > a:focus,
	#header .header-nav-main nav > ul > li.active > a:hover {
		background-color: #0088cc;
	}
}

@media (max-width: 991px) {
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a {
		color: #0088cc;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a {
		background: #0088cc;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary > a {
		color: #e36159;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.active > a {
		background: #e36159;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary > a {
		color: #2baab1;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.active > a {
		background: #2baab1;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary > a {
		color: #383f48;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary.active > a {
		background: #383f48;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark > a {
		color: #2e353e;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.active > a {
		background: #2e353e;
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light > a {
		color: #ffffff;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.active > a {
		background: #ffffff;
		color: #777777;
	}
}

html #header .header-top.header-top-primary {
	background: #0088cc;
	border-top-color: #0077b3;
}

html #header .header-top.header-top-primary .header-nav-top ul > li.open > a {
	background: #0099e6;
}

html #header .header-top.header-top-primary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:focus {
	background: #0099e6;
}

html #header .header-top.header-top-primary .dropdown-menu {
	background: #0099e6;
	border-color: #0099e6;
}

html #header .header-top.header-top-primary .dropdown-menu a:hover,
html #header .header-top.header-top-primary .dropdown-menu a:focus {
	background: #00aaff !important;
}

html #header .header-top.header-top-secondary {
	background: #e36159;
	border-top-color: #df4c43;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a {
	background: #e7766f;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:focus {
	background: #e7766f;
}

html #header .header-top.header-top-secondary .dropdown-menu {
	background: #e7766f;
	border-color: #e7766f;
}

html #header .header-top.header-top-secondary .dropdown-menu a:hover,
html #header .header-top.header-top-secondary .dropdown-menu a:focus {
	background: #ea8b85 !important;
}

html #header .header-top.header-top-tertiary {
	background: #2baab1;
	border-top-color: #26969c;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a {
	background: #30bec6;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:focus {
	background: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu {
	background: #30bec6;
	border-color: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu a:hover,
html #header .header-top.header-top-tertiary .dropdown-menu a:focus {
	background: #3fc9d0 !important;
}

html #header .header-top.header-top-quaternary {
	background: #383f48;
	border-top-color: #2d323a;
}

html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a {
	background: #434c56;
}

html #header .header-top.header-top-quaternary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a:focus {
	background: #434c56;
}

html #header .header-top.header-top-quaternary .dropdown-menu {
	background: #434c56;
	border-color: #434c56;
}

html #header .header-top.header-top-quaternary .dropdown-menu a:hover,
html #header .header-top.header-top-quaternary .dropdown-menu a:focus {
	background: #4e5865 !important;
}

html #header .header-top.header-top-dark {
	background: #2e353e;
	border-top-color: #23282f;
}

html #header .header-top.header-top-dark .header-nav-top ul > li.open > a {
	background: #39424d;
}

html #header .header-top.header-top-dark .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:focus {
	background: #39424d;
}

html #header .header-top.header-top-dark .dropdown-menu {
	background: #39424d;
	border-color: #39424d;
}

html #header .header-top.header-top-dark .dropdown-menu a:hover,
html #header .header-top.header-top-dark .dropdown-menu a:focus {
	background: #444e5b !important;
}

html #header .header-top.header-top-light {
	background: #ffffff;
	border-top-color: #f2f2f2;
}

html #header .header-top.header-top-light .header-nav-top ul > li.open > a {
	background: #ffffff;
}

html #header .header-top.header-top-light .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:focus {
	background: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu {
	background: #ffffff;
	border-color: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu a:hover,
html #header .header-top.header-top-light .dropdown-menu a:focus {
	background: #ffffff !important;
}

@media (min-width: 992px) {
	html #header .header-nav-bar-primary {
		background: #0088cc !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-secondary {
		background: #e36159 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-tertiary {
		background: #2baab1 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-quaternary {
		background: #383f48 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-dark {
		background: #2e353e !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-light {
		background: #ffffff !important;
		color: #777777 !important;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a,
	#header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
		color: #0088cc;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a.dropdown-toggle:after,
	#header .header-nav-main.header-nav-main-light nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a {
		color: #0088cc;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a.dropdown-toggle:after {
		border-color: #0088cc transparent transparent transparent;
	}
}

.testimonial blockquote {
	background: #0099e6;
}

.testimonial .testimonial-arrow-down {
	border-top-color: #0099e6;
}

html .testimonial-primary blockquote {
	background: #0099e6;
}

html .testimonial-primary .testimonial-arrow-down {
	border-top-color: #0099e6;
}

html .testimonial-secondary blockquote {
	background: #e7766f;
}

html .testimonial-secondary .testimonial-arrow-down {
	border-top-color: #e7766f;
}

html .testimonial-tertiary blockquote {
	background: #30bec6;
}

html .testimonial-tertiary .testimonial-arrow-down {
	border-top-color: #30bec6;
}

html .testimonial-quaternary blockquote {
	background: #434c56;
}

html .testimonial-quaternary .testimonial-arrow-down {
	border-top-color: #434c56;
}

html .testimonial-dark blockquote {
	background: #39424d;
}

html .testimonial-dark .testimonial-arrow-down {
	border-top-color: #39424d;
}

html .testimonial-light blockquote {
	background: #ffffff;
}

html .testimonial-light .testimonial-arrow-down {
	border-top-color: #ffffff;
}

.circular-bar.only-icon .fa,
.circular-bar.only-icon .icons {
	color: #0088cc;
}

html .counters .counter-primary .fa,
html .counters .counter-primary .icons,
html .counters .counter-primary strong {
	color: #0088cc;
}

html .counters .counter-secondary .fa,
html .counters .counter-secondary .icons,
html .counters .counter-secondary strong {
	color: #e36159;
}

html .counters .counter-tertiary .fa,
html .counters .counter-tertiary .icons,
html .counters .counter-tertiary strong {
	color: #2baab1;
}

html .counters .counter-quaternary .fa,
html .counters .counter-quaternary .icons,
html .counters .counter-quaternary strong {
	color: #383f48;
}

html .counters .counter-dark .fa,
html .counters .counter-dark .icons,
html .counters .counter-dark strong {
	color: #2e353e;
}

html .counters .counter-light .fa,
html .counters .counter-light .icons,
html .counters .counter-light strong {
	color: #ffffff;
}

.icon-featured {
	background-color: #0088cc;
}

.featured-box-primary .icon-featured {
	background-color: #0088cc;
}

.featured-box-primary .icon-featured:after {
	border-color: #0088cc;
}

.featured-box-primary h4 {
	color: #0088cc;
}

.featured-box-primary .box-content {
	border-top-color: #0088cc;
}

html .featured-box-primary .icon-featured {
	background-color: #0088cc;
}

html .featured-box-primary h4 {
	color: #0088cc;
}

html .featured-box-primary .box-content {
	border-top-color: #0088cc;
}

html .featured-box-secondary .icon-featured {
	background-color: #e36159;
}

html .featured-box-secondary h4 {
	color: #e36159;
}

html .featured-box-secondary .box-content {
	border-top-color: #e36159;
}

html .featured-box-tertiary .icon-featured {
	background-color: #2baab1;
}

html .featured-box-tertiary h4 {
	color: #2baab1;
}

html .featured-box-tertiary .box-content {
	border-top-color: #2baab1;
}

html .featured-box-quaternary .icon-featured {
	background-color: #383f48;
}

html .featured-box-quaternary h4 {
	color: #383f48;
}

html .featured-box-quaternary .box-content {
	border-top-color: #383f48;
}

html .featured-box-dark .icon-featured {
	background-color: #2e353e;
}

html .featured-box-dark h4 {
	color: #2e353e;
}

html .featured-box-dark .box-content {
	border-top-color: #2e353e;
}

html .featured-box-light .icon-featured {
	background-color: #ffffff;
}

html .featured-box-light h4 {
	color: #ffffff;
}

html .featured-box-light .box-content {
	border-top-color: #ffffff;
}

html .featured-boxes-style-3 .featured-box.featured-box-primary .icon-featured {
	border-color: #0088cc;
	color: #0088cc;
}

html .featured-boxes-style-3 .featured-box.featured-box-secondary .icon-featured {
	border-color: #e36159;
	color: #e36159;
}

html .featured-boxes-style-3 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1;
}

html .featured-boxes-style-3 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48;
}

html .featured-boxes-style-3 .featured-box.featured-box-dark .icon-featured {
	border-color: #2e353e;
	color: #2e353e;
}

html .featured-boxes-style-3 .featured-box.featured-box-light .icon-featured {
	border-color: #ffffff;
	color: #ffffff;
}

html .featured-boxes-style-4 .featured-box.featured-box-primary .icon-featured {
	border-color: #0088cc;
	color: #0088cc;
}

html .featured-boxes-style-4 .featured-box.featured-box-secondary .icon-featured {
	border-color: #e36159;
	color: #e36159;
}

html .featured-boxes-style-4 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1;
}

html .featured-boxes-style-4 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48;
}

html .featured-boxes-style-4 .featured-box.featured-box-dark .icon-featured {
	border-color: #2e353e;
	color: #2e353e;
}

html .featured-boxes-style-4 .featured-box.featured-box-light .icon-featured {
	border-color: #ffffff;
	color: #ffffff;
}

html .featured-boxes-style-5 .featured-box.featured-box-primary .icon-featured {
	color: #0088cc;
}

html .featured-boxes-style-5 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159;
}

html .featured-boxes-style-5 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-5 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-5 .featured-box.featured-box-dark .icon-featured {
	color: #2e353e;
}

html .featured-boxes-style-5 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

html .featured-boxes-style-6 .featured-box.featured-box-primary .icon-featured {
	color: #0088cc;
}

html .featured-boxes-style-6 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159;
}

html .featured-boxes-style-6 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-6 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-6 .featured-box.featured-box-dark .icon-featured {
	color: #2e353e;
}

html .featured-boxes-style-6 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

html .featured-boxes-style-8 .featured-box.featured-box-primary .icon-featured {
	color: #0088cc;
}

html .featured-boxes-style-8 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159;
}

html .featured-boxes-style-8 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-8 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-8 .featured-box.featured-box-dark .icon-featured {
	color: #2e353e;
}

html .featured-boxes-style-8 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

html .featured-box-effect-2.featured-box-primary .icon-featured:after {
	box-shadow: 0 0 0 3px #0088cc;
}

html .featured-box-effect-2.featured-box-secondary .icon-featured:after {
	box-shadow: 0 0 0 3px #e36159;
}

html .featured-box-effect-2.featured-box-tertiary .icon-featured:after {
	box-shadow: 0 0 0 3px #2baab1;
}

html .featured-box-effect-2.featured-box-quaternary .icon-featured:after {
	box-shadow: 0 0 0 3px #383f48;
}

html .featured-box-effect-2.featured-box-dark .icon-featured:after {
	box-shadow: 0 0 0 3px #2e353e;
}

html .featured-box-effect-2.featured-box-light .icon-featured:after {
	box-shadow: 0 0 0 3px #ffffff;
}

html .featured-box-effect-3.featured-box-primary .icon-featured:after {
	box-shadow: 0 0 0 10px #0088cc;
}

html .featured-box-effect-3.featured-box-primary:hover .icon-featured {
	background: #0088cc !important;
}

html .featured-box-effect-3.featured-box-secondary .icon-featured:after {
	box-shadow: 0 0 0 10px #e36159;
}

html .featured-box-effect-3.featured-box-secondary:hover .icon-featured {
	background: #e36159 !important;
}

html .featured-box-effect-3.featured-box-tertiary .icon-featured:after {
	box-shadow: 0 0 0 10px #2baab1;
}

html .featured-box-effect-3.featured-box-tertiary:hover .icon-featured {
	background: #2baab1 !important;
}

html .featured-box-effect-3.featured-box-quaternary .icon-featured:after {
	box-shadow: 0 0 0 10px #383f48;
}

html .featured-box-effect-3.featured-box-quaternary:hover .icon-featured {
	background: #383f48 !important;
}

html .featured-box-effect-3.featured-box-dark .icon-featured:after {
	box-shadow: 0 0 0 10px #2e353e;
}

html .featured-box-effect-3.featured-box-dark:hover .icon-featured {
	background: #2e353e !important;
}

html .featured-box-effect-3.featured-box-light .icon-featured:after {
	box-shadow: 0 0 0 10px #ffffff;
}

html .featured-box-effect-3.featured-box-light:hover .icon-featured {
	background: #ffffff !important;
}

.feature-box .feature-box-icon {
	background-color: #0088cc;
}

html .feature-box-primary .feature-box-icon {
	background-color: #0088cc;
}

html .feature-box-secondary .feature-box-icon {
	background-color: #e36159;
}

html .feature-box-tertiary .feature-box-icon {
	background-color: #2baab1;
}

html .feature-box-quaternary .feature-box-icon {
	background-color: #383f48;
}

html .feature-box-dark .feature-box-icon {
	background-color: #2e353e;
}

html .feature-box-light .feature-box-icon {
	background-color: #ffffff;
}

.feature-box.feature-box-style-2 .feature-box-icon i.fa,
.feature-box.feature-box-style-2 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-primary.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-primary.feature-box-style-2 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-secondary.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-secondary.feature-box-style-2 .feature-box-icon .icons {
	color: #e36159;
}

html .feature-box-tertiary.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-tertiary.feature-box-style-2 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-quaternary.feature-box-style-2 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-dark.feature-box-style-2 .feature-box-icon .icons {
	color: #2e353e;
}

html .feature-box-light.feature-box-style-2 .feature-box-icon i.fa,
html .feature-box-light.feature-box-style-2 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-3 .feature-box-icon {
	border-color: #0088cc;
}

.feature-box.feature-box-style-3 .feature-box-icon i.fa,
.feature-box.feature-box-style-3 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon {
	border-color: #0088cc;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-primary.feature-box-style-3 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon {
	border-color: #e36159;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-secondary.feature-box-style-3 .feature-box-icon .icons {
	color: #e36159;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon {
	border-color: #2baab1;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-tertiary.feature-box-style-3 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon {
	border-color: #383f48;
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-quaternary.feature-box-style-3 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon {
	border-color: #2e353e;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-dark.feature-box-style-3 .feature-box-icon .icons {
	color: #2e353e;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon {
	border-color: #ffffff;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon i.fa,
html .feature-box-light.feature-box-style-3 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-4 .feature-box-icon i.fa,
.feature-box.feature-box-style-4 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-primary.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-primary.feature-box-style-4 .feature-box-icon .icons {
	color: #0088cc;
}

html .feature-box-secondary.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-secondary.feature-box-style-4 .feature-box-icon .icons {
	color: #e36159;
}

html .feature-box-tertiary.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-tertiary.feature-box-style-4 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-quaternary.feature-box-style-4 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-dark.feature-box-style-4 .feature-box-icon .icons {
	color: #2e353e;
}

html .feature-box-light.feature-box-style-4 .feature-box-icon i.fa,
html .feature-box-light.feature-box-style-4 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-5 .feature-box-icon i.fa,
.feature-box.feature-box-style-5 .feature-box-icon i.icons {
	color: #0088cc;
}

html .feature-box-primary.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-primary.feature-box-style-5 .feature-box-icon i.icons {
	color: #0088cc;
}

html .feature-box-secondary.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-secondary.feature-box-style-5 .feature-box-icon i.icons {
	color: #e36159;
}

html .feature-box-tertiary.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-tertiary.feature-box-style-5 .feature-box-icon i.icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-quaternary.feature-box-style-5 .feature-box-icon i.icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-dark.feature-box-style-5 .feature-box-icon i.icons {
	color: #2e353e;
}

html .feature-box-light.feature-box-style-5 .feature-box-icon i.fa,
html .feature-box-light.feature-box-style-5 .feature-box-icon i.icons {
	color: #ffffff;
}

html .feature-box-primary.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-primary.feature-box-style-6 .feature-box-icon i.icons {
	color: #0088cc;
}

html .feature-box-secondary.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-secondary.feature-box-style-6 .feature-box-icon i.icons {
	color: #e36159;
}

html .feature-box-tertiary.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-tertiary.feature-box-style-6 .feature-box-icon i.icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-quaternary.feature-box-style-6 .feature-box-icon i.icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-dark.feature-box-style-6 .feature-box-icon i.icons {
	color: #2e353e;
}

html .feature-box-light.feature-box-style-6 .feature-box-icon i.fa,
html .feature-box-light.feature-box-style-6 .feature-box-icon i.icons {
	color: #ffffff;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(1) .featured-box-full-primary {
	background-color: #006699;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(2) .featured-box-full-primary {
	background-color: #0077b3;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(3) .featured-box-full-primary {
	background-color: #0088cc;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(4) .featured-box-full-primary {
	background-color: #0099e6;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(5) .featured-box-full-primary {
	background-color: #00aaff;
}

html .featured-boxes-full-scale > [class*="col-"]:nth-child(6) .featured-box-full-primary {
	background-color: #1ab2ff;
}

html .featured-boxes-full .featured-box-full-primary {
	background-color: #0088cc;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-secondary {
	background-color: #e36159;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-tertiary {
	background-color: #2baab1;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-quaternary {
	background-color: #383f48;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-dark {
	background-color: #2e353e;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-light {
	background-color: #ffffff;
	color: #777777;
}

.pricing-table .most-popular {
	border-color: #0088cc;
}

.pricing-table .most-popular h3 {
	background-color: #0088cc !important;
}

.pricing-table.princig-table-flat .plan h3 {
	background-color: #0088cc;
}

.pricing-table.princig-table-flat .plan h3 span {
	background: #0088cc;
}

html .call-to-action.featured.featured-primary {
	border-top-color: #0088cc;
}

html .call-to-action.call-to-action-primary {
	background: #0088cc;
}

html .call-to-action.with-full-borders-primary {
	border-color: #0088cc;
}

html .call-to-action.call-to-action-primary {
	background: #0088cc;
}

html .call-to-action.call-to-action-primary h3,
html .call-to-action.call-to-action-primary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-secondary {
	border-top-color: #e36159;
}

html .call-to-action.call-to-action-secondary {
	background: #e36159;
}

html .call-to-action.with-full-borders-secondary {
	border-color: #e36159;
}

html .call-to-action.call-to-action-secondary {
	background: #e36159;
}

html .call-to-action.call-to-action-secondary h3,
html .call-to-action.call-to-action-secondary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-tertiary {
	border-top-color: #2baab1;
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1;
}

html .call-to-action.with-full-borders-tertiary {
	border-color: #2baab1;
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1;
}

html .call-to-action.call-to-action-tertiary h3,
html .call-to-action.call-to-action-tertiary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-quaternary {
	border-top-color: #383f48;
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48;
}

html .call-to-action.with-full-borders-quaternary {
	border-color: #383f48;
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48;
}

html .call-to-action.call-to-action-quaternary h3,
html .call-to-action.call-to-action-quaternary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-dark {
	border-top-color: #2e353e;
}

html .call-to-action.call-to-action-dark {
	background: #2e353e;
}

html .call-to-action.with-full-borders-dark {
	border-color: #2e353e;
}

html .call-to-action.call-to-action-dark {
	background: #2e353e;
}

html .call-to-action.call-to-action-dark h3,
html .call-to-action.call-to-action-dark p {
	color: #ffffff;
}

html .call-to-action.featured.featured-light {
	border-top-color: #ffffff;
}

html .call-to-action.call-to-action-light {
	background: #ffffff;
}

html .call-to-action.with-full-borders-light {
	border-color: #ffffff;
}

html .call-to-action.call-to-action-light {
	background: #ffffff;
}

html .call-to-action.call-to-action-light h3,
html .call-to-action.call-to-action-light p {
	color: #777777;
}

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
	background: #0088cc;
	box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #0088cc;
}

ul.history li .featured-box .box-content {
	border-top-color: #0088cc;
}

.recent-posts .date .month,
article.post .post-date .month {
	background-color: #0088cc;
}

.recent-posts .date .day,
article.post .post-date .day {
	color: #0088cc;
}

.slider .tp-bannertimer {
	background-color: #0088cc;
}

.home-concept strong {
	color: #0088cc;
}

html .home-intro-primary {
	background: #0088cc;
}

html .home-intro-secondary {
	background: #e36159;
}

html .home-intro-tertiary {
	background: #2baab1;
}

html .home-intro-quaternary {
	background: #383f48;
}

html .home-intro-dark {
	background: #2e353e;
}

html .home-intro-light {
	background: #ffffff;
}

.home-intro p em {
	color: #00aaff;
}

html .slider-container .tp-caption-overlay-primary {
	background: #0088cc;
}

html .slider-container .tp-caption-overlay-opacity-primary {
	background: rgba(0, 136, 204, 0.4);
}

html .slider-container .tp-caption-overlay-secondary {
	background: #e36159;
}

html .slider-container .tp-caption-overlay-opacity-secondary {
	background: rgba(227, 97, 89, 0.4);
}

html .slider-container .tp-caption-overlay-tertiary {
	background: #2baab1;
}

html .slider-container .tp-caption-overlay-opacity-tertiary {
	background: rgba(43, 170, 177, 0.4);
}

html .slider-container .tp-caption-overlay-quaternary {
	background: #383f48;
}

html .slider-container .tp-caption-overlay-opacity-quaternary {
	background: rgba(56, 63, 72, 0.4);
}

html .slider-container .tp-caption-overlay-dark {
	background: #2e353e;
}

html .slider-container .tp-caption-overlay-opacity-dark {
	background: rgba(46, 53, 62, 0.4);
}

html .slider-container .tp-caption-overlay-light {
	background: #ffffff;
}

html .slider-container .tp-caption-overlay-opacity-light {
	background: rgba(255, 255, 255, 0.4);
}
/* Arrows */
.tparrows.tparrows-carousel.tp-leftarrow,
.tparrows.tparrows-carousel.tp-rightarrow {
	color: #ffffff;
	background-color: #0088cc;
	border-color: #0088cc #0088cc #006699;
}

.tparrows.tparrows-carousel.tp-leftarrow:hover,
.tparrows.tparrows-carousel.tp-rightarrow:hover {
	border-color: #0099e6 #0099e6 #0077b3;
	background-color: #0099e6;
}

.tparrows.tparrows-carousel.tp-leftarrow:active,
.tparrows.tparrows-carousel.tp-rightarrow:active,
.tparrows.tparrows-carousel.tp-leftarrow:focus,
.tparrows.tparrows-carousel.tp-rightarrow:focus,
.tparrows.tparrows-carousel.tp-leftarrow:active:hover,
.tparrows.tparrows-carousel.tp-rightarrow:active:hover,
.tparrows.tparrows-carousel.tp-leftarrow:active:focus,
.tparrows.tparrows-carousel.tp-rightarrow:active:focus {
	border-color: #0077b3 #0077b3 #0077b3;
	background-color: #0077b3;
}

.tparrows.tparrows-carousel.tp-leftarrow.dropdown-toggle,
.tparrows.tparrows-carousel.tp-rightarrow.dropdown-toggle {
	border-left-color: #0077b3;
}

.tparrows.tparrows-carousel.tp-leftarrow[disabled],
.tparrows.tparrows-carousel.tp-rightarrow[disabled],
.tparrows.tparrows-carousel.tp-leftarrow[disabled]:hover,
.tparrows.tparrows-carousel.tp-rightarrow[disabled]:hover,
.tparrows.tparrows-carousel.tp-leftarrow[disabled]:active,
.tparrows.tparrows-carousel.tp-rightarrow[disabled]:active,
.tparrows.tparrows-carousel.tp-leftarrow[disabled]:focus,
.tparrows.tparrows-carousel.tp-rightarrow[disabled]:focus {
	border-color: #33bbff;
	background-color: #33bbff;
}

.shop ul.products li.product .onsale {
	background-color: #0088cc;
	border-bottom-color: #006da3;
}

.shop .star-rating span,
.shop .cart-totals tr.total .amount {
	color: #0088cc;
}

#footer .footer-ribbon {
	background: #0088cc;
}

#footer .footer-ribbon:before {
	border-right-color: #005580;
	border-left-color: #005580;
}

#footer.light h1,
#footer.light h2,
#footer.light h3,
#footer.light h4,
#footer.light a {
	color: #0088cc;
}

#footer.color {
	background: #0088cc;
	border-top-color: #0088cc;
}

#footer.color .footer-ribbon {
	background: #006699;
}

#footer.color .footer-ribbon:before {
	border-right-color: #00334d;
}

#footer.color .footer-copyright {
	background: #0077b3;
	border-top-color: #0077b3;
}

html #footer.color-primary {
	background: #0088cc;
	border-top-color: #0088cc;
}

html #footer.color-primary .footer-ribbon {
	background: #006699;
}

html #footer.color-primary .footer-ribbon:before {
	border-right-color: #00334d;
}

html #footer.color-primary .footer-copyright {
	background: #0077b3;
	border-top-color: #0077b3;
}

html #footer.color-secondary {
	background: #e36159;
	border-top-color: #e36159;
}

html #footer.color-secondary .footer-ribbon {
	background: #dc372d;
}

html #footer.color-secondary .footer-ribbon:before {
	border-right-color: #a1231b;
}

html #footer.color-secondary .footer-copyright {
	background: #df4c43;
	border-top-color: #df4c43;
}

html #footer.color-tertiary {
	background: #2baab1;
	border-top-color: #2baab1;
}

html #footer.color-tertiary .footer-ribbon {
	background: #218388;
}

html #footer.color-tertiary .footer-ribbon:before {
	border-right-color: #12474a;
}

html #footer.color-tertiary .footer-copyright {
	background: #26969c;
	border-top-color: #26969c;
}

html #footer.color-quaternary {
	background: #383f48;
	border-top-color: #383f48;
}

html #footer.color-quaternary .footer-ribbon {
	background: #22262b;
}

html #footer.color-quaternary .footer-ribbon:before {
	border-right-color: #000000;
}

html #footer.color-quaternary .footer-copyright {
	background: #2d323a;
	border-top-color: #2d323a;
}

html #footer.color-dark {
	background: #2e353e;
	border-top-color: #2e353e;
}

html #footer.color-dark .footer-ribbon {
	background: #181c21;
}

html #footer.color-dark .footer-ribbon:before {
	border-right-color: #000000;
}

html #footer.color-dark .footer-copyright {
	background: #23282f;
	border-top-color: #23282f;
}

html #footer.color-light {
	background: #ffffff;
	border-top-color: #ffffff;
}

html #footer.color-light .footer-ribbon {
	background: #e6e6e6;
}

html #footer.color-light .footer-ribbon:before {
	border-right-color: #bfbfbf;
}

html #footer.color-light .footer-copyright {
	background: #f2f2f2;
	border-top-color: #f2f2f2;
}

.sample-icon-list .sample-icon a:hover {
	background-color: #0088cc;
}

html.boxed .body {
	border-top-color: #0088cc;
}
