body, 
p,
a,
h1, 
h2,
h3,
h4,
h5,
h6,
strong,
span,
input,
button,
select,
textarea {
  font-family: 'Crimson Text', serif;
  font-weight: 400;
}

html {
  font-size: 16px;
}

h1{
  line-height: 1;
  font-size: 30px;
  @media all and (min-width: 1024px) {
    font-size: 60px;
  }
}

h2 {
  line-height: 1;
  font-size: 20px;
   @media all and (min-width: 1024px) {
    font-size: 40px;
  }
}

h1, h2, h3, h4 {
  font-weight: 400;
  text-transform: none;
}

h3 {
  font-size: 26px;
  line-height: 1.1;
}

h4.panel-title {
  font-size: 1.4em;
}


b, strong {
  font-weight: 600; 
}

a.nohover {
  text-decoration: none;
  h1, h1, h3, h4, p {
    text-decoration: none;
  }
}

section.page-header h1 {
  border-bottom-color: $brand-primary;
}

@media all and (max-width: 360px) {
  .fb-page.fb_iframe_widget{
    max-width: 100% !important;

    span, iframe {
      max-width: 100% !important;
    }
  }
}

.fright {
  float: right;
}

body {
  font-size: 16px;
}

.underline {
  position: relative;
  margin-bottom: 75px;

  &:after {
    content: "";
    background: $brand-primary;
    height: 2px;
    width: 200px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.line-left {
    &:after {
      left: 0;
      transform: none;  
    }
  }
}

.product-block {
  text-align: center;
  margin-bottom: 40px;

  @media all and (min-width: 1024px) {
    margin-bottom: 60px;
  }
  > div {
    background: #fff;
    padding-bottom: 60px;
  }

  .product-image {
    border-bottom: 20px solid black;

    img {
       width: 100%;
      height: auto;
    }
  }
  h3, .current-price, p {
    padding: 0 20px;
    text-align: center;
  }
  h3 {
    margin: 40px 0;
    font-size: 21px;
    height: 70px;
    overflow: hidden;
  }
  span.current-price {
    display: block;
    font-size: 21px;
    color: black;
    margin-bottom: 30px;
    height: 45px;
    overflow: hidden;
  }
}

.call-to-action.call-to-action-primary {
  h3, p {
    text-align: left;
    font-weight: 400;
    font-size: 26px;
  }
  p {
    font-weight: 600;
  }
}

//responsive

@media all and (max-width: 767px) {
  * {
    box-sizing: border-box;
  }

  .col-sm-12 {
    padding: 0 5px;
  }

  .breadcrumb {
    span, a {
      font-size: 14px;
    }
  }
}

@media all and (max-width: 1024px) {
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.clearfix {
  @include clearfix;
}

.nofloat {
  float: none !important;
}

.center-block {
  margin: 0 auto;
}