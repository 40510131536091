html .call-to-action.call-to-action-primary{
  background-color: $brand-secondary;
}

section.facebook-wall {
  background: url(../images/facebook-bg.jpg) center center no-repeat;
  background-size: cover;
  border-top: none;
}

section.section {
  background: $brand-tertiary;
  border: none;
}

//Action site //all pages
.auctions-nav {
  background: #000;
  width: 100%;

  .navigation-wrapper {
    margin-bottom: 20px;
    padding: 5px 0;

    nav {
      display: block;
      ul.nav {
        display: block;
      }
      li {
        display: inline-block;
        width: 30%;
        a {
          display: block;
          white-space: nowrap;
          color: #fff; 
          padding: 15px;
        }

        @media all and (min-width: 768px) {
          width: auto;
        }
        @media all and (min-width: 991px) {
          width: auto;
        }
      }
    }
  }
}

.container .image {
  margin-bottom: 20px;
}